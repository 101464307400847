import React, { useEffect, useState,forwardRef,useImperativeHandle,useRef } from 'react';
import moment from 'moment';
import { Table, Pagination, Button, Divider, ButtonToolbar, Badge,DateRangePicker,Modal,Form,Uploader, Checkbox, Input, InputGroup } from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import SearchIcon from '@rsuite/icons/Search';
import STATIC_JSON from '../../data/static.json';
import { adminGet, 
  getInstuctorAttendance,
  getAllInstuctor,
  getAllForm,
  getAllSchoolProgress,
  getStudentsByUdiseCode,
  instuctorPunchIn,
  instuctorPunchOut,
  removeAStudent,
  updateStudentsByUdiseCode,
  getAllUploadedContent,
  getAllActivityLogs,
  updateActivityLog,
  removeActivityLog,
  getAllHeads,
  removeHead,
  updateHead,
  getAllActivities,
  getAllHeadDetails,
  getFilesByUsername,
  updateFilesByUsername,
  getAllOldInstuctor,
  getOrAddSessionPlans,
  updateSessionPlans,
  getAllSessionsPlan,
  getAllAssets,
  getAllSchoolList,
  getAllBRCForm,
  getAllUsers,
  deleteAFile,
  getAllActivityMonthlyLogs,
  getAllActivitiesMonthly
  } from '../../services/services';
// Drop down
import { Dropdown } from 'rsuite';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import AddNewStudent from '../popup/AddNewStudent';
import TrashIcon from '@rsuite/icons/Trash';
import { arrayOfJSONWithCount, assignMarksBasedOnGrades, exportToExcel, filterSessionPlansByStatus, getCurrentLocation, removeChildrenWithEmptyLink, removeDuplicateWeek } from '../../utils/utils';
import { CharacterAuthorize, IdMapping } from '@rsuite/icons';
// Notifications
import { Loader,Message, useToaster } from 'rsuite';
import AddNewAdmin from '../popup/AddNewAdmin';
import AdminStatus from '../popup/AdminStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewImages from '../popup/ViewImages';
import AddNewActivity from '../popup/AddNewActivity';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import FileUploader from '../uploader/fileuploader';
import { Nav } from 'rsuite';
import AdminIcon from '@rsuite/icons/Admin';
import AddNewHead from '../popup/AddNewHead';
import FileUpload from '@rsuite/icons/FileUpload';
import url from '../../api/api';
import ViewAttendance from '../popup/ViewAttendance';
import ViewSessionPlans from '../popup/ViewSessionPlans';
import AddNewAsset from '../popup/AddNewAsset';
import AddNewSchoolList from '../popup/AddNewSchoolList';
import ViewSchoolList from '../popup/ViewSchoolList';
import ViewAssets from '../popup/ViewAssets';
import UserPasswordUpdate from '../popup/UserPasswordUpdate';
import AddNewUDISE from '../popup/AddNewUDISE';
import UDISEFileUploader from '../uploader/UDISEBulkUpload';
import UpdateAUDISECode from '../popup/UpdateUDISECode';
import UserStatus from '../popup/UserStatus';
import FileReplace from '../uploader/fileReplace';

const CustomInputGroup = ({ placeholder, ...props }) => (
  <InputGroup inside {...props} style={{width : '30%'}}>
    <Input placeholder={placeholder} />
    <InputGroup.Addon>
      <SearchIcon />
    </InputGroup.Addon>
  </InputGroup>
);

const handleDownload = (fileURL) => {
  // Replace 'YOUR_FILE_URL' with the actual URL of the file you want to download
  const fileUrl = fileURL;
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', 'filename'); // You can set the filename here
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const { Column, ColumnGroup, HeaderCell, Cell } = Table;
const EditableCell = ({ rowData, dataKey, onChange,type,step, ...props }) => {
  const editing = rowData?.status === 'EDIT';
  return (
    <Cell {...props} className={editing ? 'table-content-editing' : 'fs_14'}>
      {editing ? (
        <input
          className={rowData[dataKey].length === 0 ? "rs-input rs-input-error rs-input-edit" : "rs-input rs-input-edit"}
          defaultValue={rowData[dataKey]}
          onChange={event => {
            onChange && onChange(rowData?.id, dataKey, event.target.value);
          }}
          type={type}
          step={step}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};

const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
  return (
    <Cell {...props} style={{ padding: '6px' }}>
      <Button
        appearance="link"
        onClick={() => {
          onClick(rowData?.id);
        }}
      >
        {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
      </Button>
    </Cell>
  );
};

const handleMap = (lat,long) => {
  window.open(`https://maps.google.com?q=${lat},${long}`,'_blank')
}

export const TableData = (props) => {
  const data = removeChildrenWithEmptyLink(props.data);
  const grades = ['Grade 6','Grade 7','Grade 8','Grade 9','Grade 10','Grade 11','Grade 12']
  return (
    <Table
      isTree
      bordered
      cellBordered
      rowKey="value"
      autoHeight
      data={data}
      /** shouldUpdateScroll: whether to update the scroll bar after data update **/
      shouldUpdateScroll={false}      
      renderTreeToggle={(icon, rowData) => {
        if (rowData?.children && rowData?.children.length === 0) {
          return <SpinnerIcon spin />;
        }
        return icon;
      }}
    >
      <Column width={60}>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="value" />
      </Column>
      <Column treeCol flexGrow={1}>
        <HeaderCell>E-Content Link</HeaderCell>
        <Cell>
          {rowData => {
            return rowData?.link === 'ICT' ? rowData?.label : rowData?.link?.length > 0 ? <Button appearance="link" onClick={()=>handleDownload(rowData.link)}>{rowData?.label}</Button> : rowData?.label 
          }}
        </Cell>
        {/* <Cell dataKey="label" /> */}
      </Column>
    </Table>
  );
};

export const TraningMaterial = (props) => {
  const data = [
    {
      id : 1,
      label : 'Session 1',
      link : 'https://firebasestorage.googleapis.com/v0/b/ictstore-6be36.appspot.com/o/Session%201_26-12-2023.pptx?alt=media&token=3611dbdb-f2f9-4e08-9290-5703e80592bc'
    },
    {
      id : 2,
      label : 'Session 2',
      link : 'https://firebasestorage.googleapis.com/v0/b/ictstore-6be36.appspot.com/o/Session%202_26-12-2023.pptx?alt=media&token=e6648c23-2c4e-4591-b5c8-e31f40594499'
    },
    {
      id : 3,
      label : 'Session 3',
      link : 'https://firebasestorage.googleapis.com/v0/b/ictstore-6be36.appspot.com/o/Session%203_26-12-2023.pptx?alt=media&token=5ad5a28c-876f-40db-9ed8-01d59c370bea'
    },
    {
      id : 4,
      label : 'Session 4',
      link : 'https://firebasestorage.googleapis.com/v0/b/ictstore-6be36.appspot.com/o/Session%204_26-12-2023.pptx?alt=media&token=ecd33b6c-246e-459f-a45a-40c09cbf9021'
    }
  ]
  return (
    <Table
      isTree
      bordered
      cellBordered
      rowKey="id"
      autoHeight
      data={data}
    >
      <Column width={60}>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="id" />
      </Column>
      <Column treeCol flexGrow={1}>
        <HeaderCell>Link</HeaderCell>
        <Cell>
          {rowData => {
            return <Button appearance="link" onClick={()=>handleDownload(rowData.link)}>{rowData.label}</Button>
          }}
        </Cell>
        {/* <Cell dataKey="label" /> */}
      </Column>
    </Table>
  );
}

export const StudentDataTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data,setData] = useState([]);
  const [instuctorInfo,setInstuctorInfo] = useState(null);
  const [selectGrade,setSelectedGrade] = useState('');
  const [selectedTable,setSelectedTable] = useState('Student Data');
  const [limit,setLimit] = useState(10);
  const [page,setPage] = useState(1);
  const [isEditMode,setIsEditMode] = useState(false);
  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };
  const formatedData = data.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find(item => item.id === id)[key] = value;
    setData(nextData);
  };
  const handleEditState = id => {
    const nextData = Object.assign([], data);
    const activeItem = nextData.find(item => item.id === id);
    activeItem.status = activeItem.status ? null : 'EDIT';
    setIsEditMode(true)
    if(activeItem.status === null){
      updateStudents(activeItem)
      setIsEditMode(false)
    }
    setData(nextData);
  };
  const fetchStudents = async (json) => {
    try {
      setSelectedGrade(json.class)
      setLoading(true)
      const response = await getStudentsByUdiseCode(json);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data);
              // Get marks based on grade
      const marks = assignMarksBasedOnGrades(json.class);

      // Overwrite marks in student data
      data = data.map(student => ({
        ...student,
        pretest_totalMarks: marks.pretest_totalMarks,
        assessment1_totalMarks: marks.assessment1_totalMarks,
        assessment2_totalMarks: marks.assessment2_totalMarks,
        assessment3_totalMarks: marks.assessment3_totalMarks,
        assessment4_totalMarks: marks.assessment4_totalMarks,
        summative_totalMarks: marks.summative_totalMarks,
      }));

      setData(data);
        setData(data);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const updateStudents = async (payload) => {
    try {
      setLoading(true);
      const response = await updateStudentsByUdiseCode(payload);
      if(response.data.status){
        const json = {
          udiseCode : payload.udiseCode,
          class : selectGrade
        }
        fetchStudents(json)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleDeleteStudent = async (id) => {
    try {
      const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
      const payload = {
        "id": id
      }
      const response = await removeAStudent(payload);
      if(response.data.status){
        const json = {
          udiseCode : instuctor.udiseCode,
          class : selectGrade
        }
        fetchStudents(json)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleGrade = async (e) => {
    setSelectedGrade(e);
    const json = {
      udiseCode : instuctorInfo.udiseCode,
      class : e
    }
    fetchStudents(json)
  }
  const handleTable = async (e) => {
    setSelectedTable(e);
  }
  const ActionCellSchool = ({ rowData, dataKey, onClick,disabled, ...props }) => {
    return (
      <Cell {...props} style={{ padding: '6px' }}>
        <Button
          appearance="link"
          disabled={!rowData['firstName']?.length || !rowData['lastName']?.length || !rowData['class']?.length || !rowData['divison']?.length || !rowData['academicYear']?.length || !rowData['gender']?.length || !rowData['medium']?.length}
          onClick={() => {
            onClick(rowData?.id);
          }}
        >
          {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>
      </Cell>
    );
  };
  const ActionCellSchoolProgress = ({ rowData, dataKey, onClick,disabled, ...props }) => {
    // Validation for decimal numbers
    const decimalRegex = /^\d+(\.\d{0,2})?$/;

    return (
      <Cell {...props} style={{ padding: '6px' }}>
        {selectGrade === '6' || selectGrade === '7' || selectGrade === '8' ? <Button
          appearance="link"
          disabled={
            !rowData['firstName']?.length 
            || !rowData['lastName']?.length
            || !rowData['class']?.length
            || !rowData['divison']?.length
            || !rowData['academicYear']?.length
            || !rowData['gender']?.length
            || !rowData['medium']?.length
            || !rowData['pretest_date']?.length
            || !(parseFloat(rowData['pretest_marksObtained']) <=10)
            || !(parseFloat(rowData['assessment1_marksObtained']) <=25)
            || !(parseFloat(rowData['assessment2_marksObtained']) <=25)
            || !(parseFloat(rowData['assessment3_marksObtained']) <=25)
            || !(parseFloat(rowData['assessment4_marksObtained']) <=25)
            || !(parseFloat(rowData['summative_marksObtained']) <=50) ||
            !decimalRegex.test(rowData['pretest_marksObtained']) ||
            !decimalRegex.test(rowData['assessment1_marksObtained']) ||
            !decimalRegex.test(rowData['assessment2_marksObtained']) ||
            !decimalRegex.test(rowData['assessment3_marksObtained']) ||
            !decimalRegex.test(rowData['assessment4_marksObtained']) ||
            !decimalRegex.test(rowData['summative_marksObtained'])
            || !rowData['assessment1_date']?.length
            || !rowData['assessment2_date']?.length
            || !rowData['assessment3_date']?.length
            || !rowData['assessment4_date']?.length
            || !rowData['summative_date']?.length
          }
          onClick={() => {
            onClick(rowData?.id);
          }}
        >
          {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button> : selectGrade === '9' || selectGrade === '10' ? <Button
          appearance="link"
          disabled={
            !rowData['firstName']?.length 
            || !rowData['lastName']?.length
            || !rowData['class']?.length
            || !rowData['divison']?.length
            || !rowData['academicYear']?.length
            || !rowData['gender']?.length
            || !rowData['medium']?.length
            || !rowData['pretest_date']?.length
            || !(parseFloat(rowData['pretest_marksObtained']) <=15)
            || !(parseFloat(rowData['assessment1_marksObtained']) <=25)
            || !(parseFloat(rowData['assessment2_marksObtained']) <=50)
            || !(parseFloat(rowData['assessment3_marksObtained']) <=25)
            || !(parseFloat(rowData['assessment4_marksObtained']) <=50)
            || !(parseFloat(rowData['summative_marksObtained']) <=100) ||
            !decimalRegex.test(rowData['pretest_marksObtained']) ||
            !decimalRegex.test(rowData['assessment1_marksObtained']) ||
            !decimalRegex.test(rowData['assessment2_marksObtained']) ||
            !decimalRegex.test(rowData['assessment3_marksObtained']) ||
            !decimalRegex.test(rowData['assessment4_marksObtained']) ||
            !decimalRegex.test(rowData['summative_marksObtained'])
            || !rowData['assessment1_date']?.length
            || !rowData['assessment2_date']?.length
            || !rowData['assessment3_date']?.length
            || !rowData['assessment4_date']?.length
            || !rowData['summative_date']?.length
          }
          onClick={() => {
            onClick(rowData?.id);
          }}
        >
          {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button> : <Button
          appearance="link"
          disabled={
            !rowData['firstName']?.length 
            || !rowData['lastName']?.length
            || !rowData['class']?.length
            || !rowData['divison']?.length
            || !rowData['academicYear']?.length
            || !rowData['gender']?.length
            || !rowData['medium']?.length
            || !rowData['pretest_date']?.length
            || !(parseFloat(rowData['pretest_marksObtained']) <=15)
            || !(parseFloat(rowData['assessment1_marksObtained']) <=50)
            || !(parseFloat(rowData['assessment2_marksObtained']) <=50)
            || !(parseFloat(rowData['assessment3_marksObtained']) <=50)
            || !(parseFloat(rowData['assessment4_marksObtained']) <=50)
            || !(parseFloat(rowData['summative_marksObtained']) <=100) ||
            !decimalRegex.test(rowData['pretest_marksObtained']) ||
            !decimalRegex.test(rowData['assessment1_marksObtained']) ||
            !decimalRegex.test(rowData['assessment2_marksObtained']) ||
            !decimalRegex.test(rowData['assessment3_marksObtained']) ||
            !decimalRegex.test(rowData['assessment4_marksObtained']) ||
            !decimalRegex.test(rowData['summative_marksObtained'])
            || !rowData['assessment1_date']?.length
            || !rowData['assessment2_date']?.length
            || !rowData['assessment3_date']?.length
            || !rowData['assessment4_date']?.length
            || !rowData['summative_date']?.length
          }
          onClick={() => {
            onClick(rowData?.id);
          }}
        >
          {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>}
        
      </Cell>
    );
  };
  React.useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor) {
      if(!instuctorInfo) {
        setInstuctorInfo(instuctor);
        const json = {
          udiseCode : instuctor.udiseCode,
          class : selectGrade
        }
        fetchStudents(json)
      }
    } else {
      navigate("/home")
    }
  },[])
  return (
    <>
  <Dropdown disabled={loading} color="green" appearance="primary" className='mb-3 mt-3' title={selectedTable} endIcon={<UserBadgeIcon />} onSelect={handleTable}>
    <Dropdown.Item active={selectedTable === "Student Data"} eventKey="Student Data" icon={<UserBadgeIcon />}>Student Data</Dropdown.Item>
    <Dropdown.Item active={selectedTable === "Progress Report"} eventKey="Progress Report" icon={<UserBadgeIcon />}>Progress Report</Dropdown.Item>
  </Dropdown>
  
    <Dropdown disabled={loading} color="violet" appearance="primary" style={{marginLeft : '10px'}} className='mb-3 mt-3' title={selectGrade === "" ? 'Select Grade' : `Grade ${selectGrade}`} endIcon={<UserBadgeIcon />} onSelect={handleGrade}>
    <Dropdown.Item active={selectGrade === "6"} eventKey="6" icon={<UserBadgeIcon />}>Grade 6</Dropdown.Item>
    <Dropdown.Item active={selectGrade === "7"} eventKey="7" icon={<UserBadgeIcon />}>Grade 7</Dropdown.Item>
    <Dropdown.Item active={selectGrade === "8"} eventKey="8" icon={<UserBadgeIcon />}>Grade 8</Dropdown.Item>
    <Dropdown.Item active={selectGrade === "9"} eventKey="9" icon={<UserBadgeIcon />}>Grade 9</Dropdown.Item>
    <Dropdown.Item active={selectGrade === "10"} eventKey="10" icon={<UserBadgeIcon />}>Grade 10</Dropdown.Item>
    <Dropdown.Item active={selectGrade === "11"} eventKey="11" icon={<UserBadgeIcon />}>Grade 11</Dropdown.Item>
    <Dropdown.Item active={selectGrade === "12"} eventKey="12" icon={<UserBadgeIcon />}>Grade 12</Dropdown.Item>
  </Dropdown>

  <AddNewStudent onFetch={fetchStudents} loading={loading || isEditMode} />
    <div className="rs-table-column-group-header-content table-heading2 text-white">School UDISE Code : {instuctorInfo?.schoolInfo[0]?.udise_code ? instuctorInfo.schoolInfo[0].udise_code : ''}</div>
    <div className={ selectedTable === "Student Data" ? "rs-table-column-group-header-content table-heading text-white" : "d-none"}>School Name : {instuctorInfo?.schoolInfo[0]?.school_name ? instuctorInfo.schoolInfo[0].school_name : ''}</div>
    <div className={ selectedTable === "Progress Report" ? "rs-table-column-group-header-content table-heading text-white" : "d-none"}>Progress Report</div>
    {selectedTable === "Student Data" ? <Table bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <ColumnGroup header="Student Name" align="center">
        <Column width={130} colSpan={2}>
          <HeaderCell>First Name</HeaderCell>
          <EditableCell dataKey="firstName" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Last Name</HeaderCell>
          <EditableCell dataKey="lastName" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      <ColumnGroup header="Grade" align="center">
        <Column width={130} colSpan={2}>
          <HeaderCell>Class</HeaderCell>
          <EditableCell dataKey="class" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Divison</HeaderCell>
          <EditableCell dataKey="divison" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Academic Year</HeaderCell>
        <EditableCell dataKey="academicYear" />
      </Column>
      <ColumnGroup header="Gender" align="center">
        <Column width={130} colSpan={2}>
          <HeaderCell>Male/Female</HeaderCell>
          <EditableCell dataKey="gender" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      <ColumnGroup header="Medium" align="center">
        <Column width={250} colSpan={2}>
          <HeaderCell>Hindi / English / Marathi / Urdu</HeaderCell>
          <EditableCell dataKey="medium" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <ActionCellSchool dataKey="_id" onClick={handleEditState} />
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Remove</HeaderCell>
        <Cell>
            {rowData => (
              <TrashIcon className='pointer' color='red' onClick={()=>handleDeleteStudent(rowData?._id)} />
          )}
        </Cell>
      </Column>
    </Table> :     <Table bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <ColumnGroup header="Student Name" align="center">
        <Column width={130} colSpan={2}>
          <HeaderCell>First Name</HeaderCell>
          <EditableCell dataKey="firstName" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Last Name</HeaderCell>
          <EditableCell dataKey="lastName" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      {/* Pretest */}
      <ColumnGroup header="Pre - Test" align="center">
        <Column width={200} colSpan={2}>
          <HeaderCell>Date</HeaderCell>
          <EditableCell dataKey="pretest_date" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Total Marks</HeaderCell>
          <Cell dataKey="pretest_totalMarks" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Marks Obtained</HeaderCell>
          <EditableCell dataKey="pretest_marksObtained" onChange={handleChange} />
        </Column>
      </ColumnGroup>
        {/* Assessment 1 */}
      <ColumnGroup header="Formative Assessment 1" align="center">
        <Column width={200} colSpan={2}>
          <HeaderCell>Date</HeaderCell>
          <EditableCell dataKey="assessment1_date" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Total Marks</HeaderCell>
          <Cell dataKey="assessment1_totalMarks" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Marks Obtained</HeaderCell>
          <EditableCell dataKey="assessment1_marksObtained" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      {/* Assessment 2 */}
      <ColumnGroup header="Formative Assessment 2" align="center">
        <Column width={200} colSpan={2}>
          <HeaderCell>Date</HeaderCell>
          <EditableCell dataKey="assessment2_date" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Total Marks</HeaderCell>
          <Cell dataKey="assessment2_totalMarks" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Marks Obtained</HeaderCell>
          <EditableCell dataKey="assessment2_marksObtained" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      {/* Assessment 3 */}
      <ColumnGroup header="Formative Assessment 3" align="center">
        <Column width={200} colSpan={2}>
          <HeaderCell>Date</HeaderCell>
          <EditableCell dataKey="assessment3_date" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Total Marks</HeaderCell>
          <Cell dataKey="assessment3_totalMarks" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Marks Obtained</HeaderCell>
          <EditableCell dataKey="assessment3_marksObtained" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      {/* Assessment 4 */}
      <ColumnGroup header="Formative Assessment 4" align="center">
        <Column width={200} colSpan={2}>
          <HeaderCell>Date</HeaderCell>
          <Cell dataKey="assessment4_date" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Total Marks</HeaderCell>
          <Cell dataKey="assessment4_totalMarks" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Marks Obtained</HeaderCell>
          <EditableCell dataKey="assessment4_marksObtained" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      <ColumnGroup header="Summative Assessment" align="center">
        <Column width={200} colSpan={2}>
          <HeaderCell>Date</HeaderCell>
          <EditableCell dataKey="summative_date" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Total Marks</HeaderCell>
          <Cell dataKey="summative_totalMarks" onChange={handleChange} />
        </Column>
        <Column width={130}>
          <HeaderCell>Marks Obtained</HeaderCell>
          <EditableCell dataKey="summative_marksObtained" onChange={handleChange} />
        </Column>
      </ColumnGroup>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <ActionCellSchoolProgress dataKey="_id" onClick={handleEditState} />
      </Column>
      {/* <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Remove</HeaderCell>
        <Cell>
            {rowData => (
              <TrashIcon className='pointer' color='red' onClick={()=>handleDeleteStudent(rowData?._id)} />
          )}
        </Cell>
      </Column> */}
    </Table> }
    <div className='mt-2'>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={data.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  );
}

export const SchoolAttendanceTable = () => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data,setData] = useState([]);
  const [limit,setLimit] = useState(10);
  const [page,setPage] = useState(1);
  const [instuctorInfo,setInstuctorInfo] = useState(null);
  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const formatedData = data.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const pushNotification = (type,header,message) => {
    toaster.push(<Message showIcon type={type} header={header}>{message}</Message>, {
      placement : 'topEnd',
      duration: 3000
    })
  }
  const fetchAttendance = async (json) => {
    try {
      setLoading(true)
      const response = await getInstuctorAttendance(json);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const hanldePunchIn = async () => {
    getCurrentLocation()
    .then(async (coords) => {
      try {
        const json = {
          username : instuctorInfo.username,
          divison : instuctorInfo.schoolInfo[0].division_name,
          punchInLat : coords.latitude,
          punchInLong : coords.longitude,
          punchInTime : new Date(),
          udiseInfo : instuctorInfo.schoolInfo[0]
        }
        const response = await instuctorPunchIn(json);
        if(response.data.status) {
          fetchAttendance(response.data.data.username);
          pushNotification('success','Success',response.data.message)
        } else {
          pushNotification('warning','Note',response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
      
    })
    .catch((error) => {
      console.error(error.message);
    });
  }
  const hanldePunchOut = () => {
    getCurrentLocation()
    .then(async (coords) => {
      try {
        const attendance = data.filter((el)=>moment(el.punchInTime).format('DDD-MMM-YYYY') === moment(new Date()).format('DDD-MMM-YYYY'))
        const json = {
          id : attendance[0]._id,
          division : instuctorInfo.schoolInfo[0].division_name,
          username :instuctorInfo.username,
          punchOutLat : coords.latitude,
          punchOutLong : coords.longitude,
          isPunchedOut : true
        }
        const response = await instuctorPunchOut(json);
        if(response.data.status) {
          fetchAttendance(response.data.data.username);
          pushNotification('success','Success',response.data.message);
        } else {
          pushNotification('warning','Note',response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
      
    })
    .catch((error) => {
      console.error(error.message);
    });
  }
  React.useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor) {
      if(!instuctorInfo) {
        setInstuctorInfo(instuctor);
        fetchAttendance(instuctor.username);
      }
    } else {
      navigate("/home")
    }
  },[])
  return (
    <>
    <ButtonToolbar>
    <Button disabled={loading} color="green" appearance="primary" onClick={hanldePunchIn} endIcon={<CharacterAuthorize />}>Punch In</Button>
    <Button disabled={loading} color="red" appearance="primary" onClick={hanldePunchOut} endIcon={<IdMapping />}>Punch Out</Button>
    </ButtonToolbar>
    <Divider />
    <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Division</HeaderCell>
        <Cell>
          {rowData=>{
            return rowData?.udiseCodesData ? rowData?.udiseCodesData?.division_name : ''
          }}
        </Cell>
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell>
          {rowData=>{
            return rowData?.udiseCodesData ? rowData?.udiseCodesData?.district : ''
          }}
        </Cell>
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <Cell>
          {rowData=>{
            return rowData?.udiseCodesData? rowData?.udiseCodesData?.udise_code : ''
          }}
        </Cell>
      </Column>
      <Column width={300} colSpan={2} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell>
          {rowData=>{
            return rowData?.udiseCodesData ? rowData?.udiseCodesData?.school_name : ''
          }}
        </Cell>
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Username</HeaderCell>
        <Cell dataKey="username" />
      </Column>
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Punch In Time</HeaderCell>
        <Cell>
          {rowData => {
            return `${moment(rowData?.punchInTime).format('DD-MMM YYYY hh:mm a')}`
          }}
        </Cell>
      </Column>
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Punch Out Time</HeaderCell>
        <Cell>
        {rowData => {
          return rowData?.punchOutTime ? `${moment(rowData?.punchOutTime).format('DD-MMM YYYY hh:mm a')}` :  <Badge color="orange" content="Pending" />
        }}
        </Cell>
      </Column>
      <ColumnGroup header="Punch In Location" align="center">
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Latitude</HeaderCell>
        <Cell>
          {rowData=>{
            return rowData.punchInLat !== null ? rowData.punchInLat : 'No Latitude'
          }}
        </Cell>
      </Column>
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Longitude</HeaderCell>
        <Cell>
          {rowData=>{
            return rowData.punchInLong !== null ? rowData.punchInLong : 'No Longitude'
          }}
        </Cell>
      </Column>
      </ColumnGroup>
      <ColumnGroup header="Punch Out Location" align="center">
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Latitude</HeaderCell>
        <Cell>
        {rowData => {
          return rowData?.punchOutLat ? rowData?.punchOutLat :  <Badge color="orange" content="Pending" />
        }}
        </Cell>
      </Column>
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Longitude</HeaderCell>
        <Cell>
        {rowData => {
          return rowData?.punchOutLong ? rowData?.punchOutLong :  <Badge color="orange" content="Pending" />
        }}
        </Cell>
      </Column>
      </ColumnGroup>

      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Location View</HeaderCell>
        <Cell>
          {rowData => {
            return <ButtonToolbar>
              <Button size="xs" color="green" appearance="primary" onClick={()=>handleMap(rowData?.punchInLat,rowData?.punchInLong)}>Punch In</Button>
              <Button size="xs" color="red" appearance="primary" onClick={()=>handleMap(rowData?.punchOutLat,rowData?.punchOutLong)}>Punch Out</Button>
            </ButtonToolbar> 
          }}
        </Cell>
      </Column>
      {/* <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Help</HeaderCell>
        <Cell>
          {rowData => {
            return <Button size="xs" color="blue" appearance="primary">Report</Button>
          }}
        </Cell>
      </Column> */}
    </Table>
    <div className='mt-2'>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={data.length}
          limitOptions={[9, 30, 48]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  );
}

export const AdminTable = forwardRef(({selectedTab,selectedValue},ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [adminInfo,setAdminInfo] = useState({
    _id : '',
    username : ''
  })
  const [data,setData] = useState([]);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [open,setOpen] = useState(false);
  const [openView,setOpenView] = useState(false);
  const [openSessions,setOpenSessions] = useState(false);
  const [viewData,setViewData] = useState([]);
  const [sessionPlanData,setSessionPlanData] = useState([]);
  const [imageList,setImageList] = useState([]);
  const [openAsset,setOpenAsset] = useState(false);
  const [openSchool,setOpenSchool] = useState(false);
  const [asset,setAsset] = useState([]);
  const [school,setSchool] = useState([]);
  const [active, setActive] = useState('Activity Logs');
  const [sortByDate,setSortByDate] = useState({
    startDate : '',
    endDate : ''
  })
  const [selectContent,setSelectContent] = useState('');
  const viewOpen = (rowData) => {
    setOpen(true);
    setImageList(rowData);
  }
  const handleAssets = (rowData) => {
    setOpenAsset(true);
    setAsset(rowData);
  }
  const handleSchools = (rowData) => {
    setOpenSchool(true);
    setSchool(rowData);
  }
  const handleActive = (e) => {
    setActive(e);
    handleSwitch(e,page,limit);
   }
  const handleClose = () => {
    setOpen(false);
    setOpenView(false);
    setOpenSessions(false);
    setOpenAsset(false);
    setOpenSchool(false);
  }
  const viewSessions = (rowData) => {
    setOpenSessions(true);
    setSessionPlanData(rowData);
  }
  const handlePage = (dataKey) => {
    setPage(dataKey);
    if(active === 'Activity Monthly') {
      handleSwitch('Activity Monthly',dataKey,limit)
    } else if(selectedTab !== "Instuctor Session") {
      handleSwitch(selectedTab,dataKey,limit)
    }
  }
  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
    if(active === 'Activity Monthly') {
      handleSwitch('Activity Monthly',1,dataKey)
    } else if(selectedTab !== "Instuctor Session") {
      handleSwitch(selectedTab,1,dataKey)
    }
  };

  const formatedData = data.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const Navbar = ({ active, onSelect, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 50 }}>
       <Nav.Item eventKey="Activity Logs">Daily Activity</Nav.Item>
        <Nav.Item eventKey="Activity Monthly">Monthly Report</Nav.Item>
      </Nav>
    );
  };
 const handleSwitch = (tab,page,limit) => {
  const pageNumber = page || 1;
  const pageSize = limit || 10;
  const selectedValue = localStorage.getItem('selectedValue');
  switch (tab) {
    case "Admin":
      fetchAdmins(page,limit);
      break;
    case "Instuctor OldAttendance":
      fetchOldInstuctors(selectedValue,pageNumber,pageSize);
      break;
    case "Instuctor Attendance":
      fetchInstuctors(selectedValue,pageNumber,pageSize);
      break;
    case "Instuctor Users":
      fetchUsers(selectedValue,'',pageNumber,pageSize);
      break;
    case "Instuctor Session":
      fetchInstuctorsSessionPlan(selectedValue,pageNumber,pageSize);
      break;
    case "Instuctor Content":
      fetchUploadedContent(selectedValue,pageNumber,pageSize);
      break;
    case "School Report":
      fetchForms(selectedValue,pageNumber,pageSize);
      break;
    case "Progress Report":
      fetchSchoolProgress(selectedValue,'',pageNumber,pageSize);
      break;
    case "Activity Logs":
      fetchAllActivitiesLogs(selectedValue,pageNumber,pageSize);
      break;
    case "Activity Monthly":
      fetchAllActivitiesMonthly(selectedValue,pageNumber,pageSize);
      break;
    case "Head Details":
      fetchAllHeads(selectedValue,pageNumber,pageSize);
      break;
    case "ICT Lab Asset Report":
      fetchAllBRCForm(selectedValue,pageNumber,pageSize);
      break;
    default:
      break;
  }
 } 
 const fetchUsers = async (sortBy,search,pageNumber,pageSize) => {
  try {
    setLoading(true)
    const response = await getAllUsers(sortBy,search,pageNumber,pageSize);
    if(response.data.status){
      let data = arrayOfJSONWithCount(response.data.data)
      setData(data);
      setTotal(response.data.total)
      setTimeout(()=>{
        setLoading(false);
      },100)
    }
  } catch (error) {
    console.log(error)
  }
 }
 const fetchAllBRCForm = async (sortBy,pageNumber,pageSize) => {
  try {
    setLoading(true)
    const response = await getAllBRCForm(sortBy,pageNumber,pageSize);
    if(response.data.status){
      let data = arrayOfJSONWithCount(response.data.data)
      setData(data);
      setTotal(response.data.total)
      setTimeout(()=>{
        setLoading(false);
      },100)
    }
  } catch (error) {
    console.log(error)
  }
 }
 const fetchOldInstuctors = async (sortBy) => {
  try {
    setLoading(true)
    const response = await getAllOldInstuctor(sortBy);
    if(response.data.status){
      let data = arrayOfJSONWithCount(response.data.data)
      setData(data);
      setTimeout(()=>{
        setLoading(false);
      },100)
    }
  } catch (error) {
    console.log(error)
  }
}
 const fetchInstuctorsSessionPlan = async (sortBy,pageNumber,pageSize) => {
  try {
    setLoading(true)
    const response = await getAllSessionsPlan(sortBy,pageNumber,pageSize);
    if(response.data.status){
      
      if(response.data.data.length) {
        let data = arrayOfJSONWithCount(response.data.data)
        setData(filterSessionPlansByStatus(data));
        setTotal(response.data.total)
      } else {
        setData([])
      }
      
      setTimeout(()=>{
        setLoading(false);
      },100)
    }
  } catch (error) {
    console.log(error)
  }
}

  const fetchAdmins = async (pageNumber,pageSize) => {
    try {
      setLoading(true)
      const response = await adminGet(pageNumber,pageSize);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTotal(response.data.total)
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchAllHeads = async (sortBy,pageNumber,pageSize) => {
    try {
      setLoading(true)
      const response = await getAllHeadDetails(sortBy,pageNumber,pageSize);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTotal(response.data.total)
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchAllActivitiesLogs = async (sortBy,pageNumber,pageSize) => {
    try {
      setLoading(true)
      const response = await getAllActivities(sortBy,pageNumber,pageSize);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTotal(response.data.total)
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchAllActivitiesMonthly = async (sortBy,pageNumber,pageSize) => {
    try {
      setLoading(true)
      const response = await getAllActivitiesMonthly(sortBy,pageNumber,pageSize);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTotal(response.data.total)
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchInstuctors = async (sortBy,pageNumber,pageSize) => {
    try {
      setLoading(true)
      let finalSort = '';
      if(sortByDate.startDate && sortByDate.endDate){
        finalSort = `${sortBy}&startDate=${sortByDate.startDate}&endDate=${sortByDate.endDate}`
      } else {
        finalSort = sortBy
      }
      
      const response = await getAllInstuctor(finalSort,pageNumber,pageSize);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTotal(response.data.total)
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchForms= async (sortBy,pageNumber,pageSize) => {
    try {
      setLoading(true)
      const response = await getAllForm(sortBy,pageNumber,pageSize);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTotal(response.data.total);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSchoolProgress= async (sortBy,search,pageNumber,pageSize) => {
    try {
      setLoading(true)
      const response = await getAllSchoolProgress(sortBy,search,pageNumber,pageSize);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTotal(response.data.total);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchUploadedContent= async (sortBy,pageSize,pageNumber) => {
    try {
      setLoading(true)
      const response = await getAllUploadedContent(sortBy,pageSize,pageNumber);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTotal(response.data.total)
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // Expose childFunction to parent component
    useImperativeHandle(ref, () => ({
      fetchInstuctors
    }));
  // edit ground exercie 
  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find(item => item.id === id)[key] = value;
    setData(nextData);
  };
  const handleEditState = id => {
    const nextData = Object.assign([], data);
    const activeItem = nextData.find(item => item.id === id);
    activeItem.status = activeItem.status ? null : 'EDIT';
    if(activeItem.status === null){
      updateFiles(activeItem)
    }
    setData(nextData);
  };
  const handleDeleteExercie = async (id) =>{
    try {
      const payload = {
        "id": id
      }
      const response = await deleteAFile(payload);
      if(response.data.status){
        handleSwitch('Instuctor Content',page,limit)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const updateFiles = async (payload) => {
    try {
      const response = await updateFilesByUsername(payload);
      if(response.data.status){
        handleSwitch('Instuctor Content',page,limit)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // edit ground exerice
  const ActionCellGroundExercise = ({ rowData, dataKey, onClick,disabled, ...props }) => {
    return (
      <Cell {...props} style={{ padding: '6px' }}>
       <Button
          appearance="link"
          onClick={() => {
            onClick(rowData?.id);
          }}
        >
          {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>
      </Cell>
    );
  }; 

  useEffect(()=>{
    const admin = JSON.parse(localStorage.getItem('userInfoAdmin'));
    if(admin) {
      setAdminInfo(admin)
    }
    if(query.get('isSuperAdmin') === 'true'){
      if(selectedTab === 'admin') {
        fetchAdmins(page,limit);
      } 
    }
    if(selectedTab) {
      setActive('Activity Logs');
      setPage(1)
      handleSwitch(selectedTab,1,limit);
    }
  },[selectedTab,selectedValue,sortByDate])
  return <>
{query.get('isSuperAdmin') === 'true' && selectedTab === 'Admin' ? <AddNewAdmin onFetch={fetchAdmins} loading={loading} /> : ''}
<ViewImages data={imageList} isOpen={open} handleClose={handleClose} />
<ViewAttendance data={viewData} isOpen={openView} handleClose={handleClose} />
<ViewSessionPlans data={removeDuplicateWeek(sessionPlanData)} isOpen={openSessions} handleClose={handleClose} />
<ViewAssets data={asset} isOpen={openAsset} handleClose={handleClose} />
<ViewSchoolList data={school} isOpen={openSchool} handleClose={handleClose} />
{selectedTab === "Admin" ? <Table width={550} bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={50} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Username</HeaderCell>
        <Cell dataKey="username" />
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Status</HeaderCell>
        <Cell>
          {rowData => {
            return rowData?.isActive ? <Badge color="green" content="Active" /> : <Badge color="red" content="Deactivate" />
          }}
        </Cell>
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Created At</HeaderCell>
        <Cell dataKey="createdAt" />
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <Cell>
          {rowData => {
            return <AdminStatus adminId={rowData?._id} onFetch={fetchAdmins} loading={loading} />
          }}
        </Cell>
      </Column>
    </Table> :  selectedTab === "Instuctor Users" ? <>
    <div className='d-flex mb-2'>
    <AddNewUDISE loading={loading} onFetch={fetchUsers} division={selectedValue} />
    <UDISEFileUploader loadingUsers={loading} />
    </div>
    <CustomInputGroup className='mb-2' size="md" placeholder="Search..." onChange={(e)=>fetchUsers(selectedValue,e.target.value,1,10)} />
     <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={140} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <Cell dataKey="udiseCode" />
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Division</HeaderCell>
        <Cell dataKey="udiseData.division_name" />
      </Column>
      <Column width={300} colSpan={2} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell dataKey="udiseData.school_name" />
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Username</HeaderCell>
        <Cell dataKey="username" />
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Status</HeaderCell>
        <Cell>
          {rowData => {
            return <UserStatus onFetch={fetchUsers} userStatus={rowData.status} userId={rowData._id} division={selectedValue} /> 
          }}
        </Cell>
      </Column>
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <Cell>
          {rowData => {
            return <div className='d-flex'>
              <UpdateAUDISECode udiseData={rowData?.udiseData} loading={loading} onFetch={fetchUsers} division={selectedValue} />
              <UserPasswordUpdate userId={rowData?._id} userName={rowData.username} onFetch={fetchUsers} loading={loading} division={selectedValue} />
              </div>
          }}
        </Cell>
      </Column>
    </Table> 
    <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={
      async()=>{
        setLoading(true)
        const selectedValue = localStorage.getItem('selectedValue');
        const response = await getAllUsers(selectedValue,'',1,total);
        if(response.data.status){
          const instuctorData = arrayOfJSONWithCount(response.data.data)
          setLoading(false);
          exportToExcel(instuctorData,`${'Instuctor_Users'}-${new Date()}.xlsx`,'InstuctorUsers')
         }
      
      }} startIcon={<FileDownloadIcon />}>Download</Button>
    </>  :  selectedTab === "Instuctor Attendance" ? <>
    <div className='d-flex mb-2 mt-2 align-items-center'>
    <h6>Filters - </h6> <DateRangePicker disabled={loading} onChange={(e)=>{
      try {
        const sortByDateFilter = {
          startDate : e[0],
          endDate : e[1]
        }
        sortByDateFilter.endDate.setDate(sortByDateFilter.endDate.getDate() + 1);
        setSortByDate({
          startDate : sortByDateFilter.startDate,
          endDate : sortByDateFilter.endDate
        })
      } catch (error) {
        console.log(error)
      }

    }} style={{width : '300px'}} format="MM/dd/yyyy" character=" – " />
    </div>
    <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
      
      <ColumnGroup header="Instructor Attendance Report" align="left">
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <Cell>
          {rowData => {
            return `${moment(rowData?.punchInTime).format('DD-MMM YYYY')}`
          }}
        </Cell>
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Division</HeaderCell>
        <Cell dataKey='udiseInfo.division_name' />
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell dataKey='udiseInfo.district' />
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <Cell dataKey='udiseInfo.udise_code' />
      </Column>
      <Column width={300} colSpan={2} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell dataKey='udiseInfo.school_name' />
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Username</HeaderCell>
        <Cell dataKey="username" />
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Punch In Time</HeaderCell>
        <Cell>
          {rowData => {
            return `${moment(rowData?.punchInTime).format('hh:mm a')}`
          }}
        </Cell>
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Punch Out Time</HeaderCell>
        <Cell>
        {rowData => {
          return rowData?.punchOutTime ? `${moment(rowData?.punchOutTime).format('hh:mm a')}` :  <Badge color="orange" content="Pending" />
        }}
        </Cell>
      </Column>
      <ColumnGroup header="Punch In Location" align="center">
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Latitude</HeaderCell>
        <Cell>
          {rowData=>{
            return rowData.punchInLat !== null ? rowData.punchInLat : 'No Latitude'
          }}
        </Cell>
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Longitude</HeaderCell>
        <Cell>
          {rowData=>{
            return rowData.punchInLong !== null ? rowData.punchInLong : 'No Longitude'
          }}
        </Cell>
      </Column>
      </ColumnGroup>
      <ColumnGroup header="Punch Out Location" align="center">
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Latitude</HeaderCell>
        <Cell>
        {rowData => {
          return rowData?.punchOutLat ? rowData?.punchOutLat :  <Badge color="orange" content="Pending" />
        }}
        </Cell>
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Longitude</HeaderCell>
        <Cell>
        {rowData => {
          return rowData?.punchOutLong ? rowData?.punchOutLong :  <Badge color="orange" content="Pending" />
        }}
        </Cell>
      </Column>
      </ColumnGroup>

      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Location View</HeaderCell>
        <Cell>
          {rowData => {
            return <ButtonToolbar>
              <Button size="xs" color="green" appearance="primary" onClick={()=>handleMap(rowData?.punchInLat,rowData?.punchInLong)}>Punch In</Button>
              <Button size="xs" color="red" appearance="primary" onClick={()=>handleMap(rowData?.punchOutLat,rowData?.punchOutLong)}>Punch Out</Button>
            </ButtonToolbar> 
          }}
        </Cell>
      </Column>
      </ColumnGroup>
    </Table>
    <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={
      async()=>{
        setLoading(true)
        const selectedValue = localStorage.getItem('selectedValue');
        let finalSort = '';
        if(sortByDate.startDate && sortByDate.endDate){
          finalSort = `${selectedValue}&startDate=${sortByDate.startDate}&endDate=${sortByDate.endDate}`
        } else {
          finalSort = selectedValue
        }
        const response = await getAllInstuctor(finalSort,1,total);
        if(response.data.status){
          const instuctorData = arrayOfJSONWithCount(response.data.data)
          setLoading(false);
          exportToExcel(instuctorData,`${'Instuctor_Attendance'}-${new Date()}}.xlsx`,'InstuctorAttendance')
         }
      
      }} startIcon={<FileDownloadIcon />}>Download</Button>
     </>: selectedTab === "School Report" ? <>
     
      <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
        <ColumnGroup header='UDISE Form Report' align='left'>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Status</HeaderCell>
        <Cell>
          {rowData => {
            return rowData?.formDetails?.status === "Completed" ?  <Button size="xs" color="green" appearance="primary">Completed</Button> : <Button size="xs" color="orange" appearance="primary">{rowData?.formDetails?.status}</Button>
          }}
        </Cell>
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>UDISE+ Code</HeaderCell>
        <Cell dataKey="udise_code" />
      </Column>
      <Column width={300} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell dataKey="school_name" />
      </Column>
      <Column width={140} align="center" verticalAlign='center'>
        <HeaderCell>Division Name</HeaderCell>
        <Cell dataKey="division_name" />
      </Column>
      <Column width={140} align="center" verticalAlign='center'>
        <HeaderCell>Block Name</HeaderCell>
        <Cell dataKey="block_name" />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell dataKey="district" />
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <Cell>
          {rowData => {
            return `${moment(rowData?.formDetails?.createdAt).format('DD-MMM YYYY')}`
          }}
        </Cell>
      </Column>
      </ColumnGroup>
    </Table> 
    <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={
      async()=>{
        setLoading(true)
        const selectedValue = localStorage.getItem('selectedValue');
        const response = await getAllForm(selectedValue,1,total);
        if(response.data.status){
          const instuctorData = arrayOfJSONWithCount(response.data.data)
          setLoading(false);
          exportToExcel(instuctorData,`${'School_UDISE_Progress'}-${new Date()}.xlsx`,'SchoolUDISEReport')
         }
      
      }} startIcon={<FileDownloadIcon />}>Download</Button>
     </>: selectedTab === "Progress Report" ? <> 
    <CustomInputGroup className='mb-2' size="md" placeholder="Search..." onChange={(e)=>fetchSchoolProgress(selectedValue,e.target.value,page,limit)} />
    <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
    <ColumnGroup header='Student Progress Report' align='left'> 
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>UDISE+ Code</HeaderCell>
        <Cell dataKey="udise_code" />
      </Column>
      <Column width={300} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell dataKey="school_name" />
      </Column>
      <Column width={120} align="center" verticalAlign='center'>
        <HeaderCell>Division Name</HeaderCell>
        <Cell dataKey="division_name" />
      </Column>
      <Column width={120} align="center" verticalAlign='center'>
        <HeaderCell>Block Name</HeaderCell>
        <Cell dataKey="block_name" />
      </Column>
      <Column width={120} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell dataKey="district" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Pre - Test</HeaderCell>
        <Cell>
          {rowData => {
            let isPretestDone = []
            rowData?.studentDetails?.map((item)=>{
              if(item.pretest_date && item.pretest_totalMarks && item.pretest_marksObtained){
                  isPretestDone.push(true)
              } else {
                isPretestDone.push(false)
              }
            })
            return isPretestDone.includes(false) ? <Button size="xs" color="orange" appearance="primary">Pending</Button> : <Button size="xs" color="green" appearance="primary">Completed</Button>
          }}
        </Cell>
      </Column>
      <Column width={170} align="center" verticalAlign='center'>
        <HeaderCell>Formative Assessment 1</HeaderCell>
        <Cell>
          {rowData => {
            let isPretestDone = []
            rowData?.studentDetails?.map((item)=>{
              if(item.assessment1_date && item.assessment1_totalMarks && item.assessment1_marksObtained){
                  isPretestDone.push(true)
              } else {
                isPretestDone.push(false)
              }
            })
            return isPretestDone.includes(false) ? <Button size="xs" color="orange" appearance="primary">Pending</Button> : <Button size="xs" color="green" appearance="primary">Completed</Button>
          }}
        </Cell>
      </Column>
      <Column width={170} align="center" verticalAlign='center'>
        <HeaderCell>Formative Assessment 2</HeaderCell>
        <Cell>
          {rowData => {
            let isPretestDone = []
            rowData?.studentDetails?.map((item)=>{
              if(item.assessment2_date && item.assessment2_totalMarks && item.assessment2_marksObtained){
                  isPretestDone.push(true)
              } else {
                isPretestDone.push(false)
              }
            })
            return isPretestDone.includes(false) ? <Button size="xs" color="orange" appearance="primary">Pending</Button> : <Button size="xs" color="green" appearance="primary">Completed</Button>
          }}
        </Cell>
      </Column>
      <Column width={170} align="center" verticalAlign='center'>
        <HeaderCell>Formative Assessment 3</HeaderCell>
        <Cell>
          {rowData => {
            let isPretestDone = []
            rowData?.studentDetails?.map((item)=>{
              if(item.assessment3_date && item.assessment3_totalMarks && item.assessment3_marksObtained){
                  isPretestDone.push(true)
              } else {
                isPretestDone.push(false)
              }
            })
            return isPretestDone.includes(false) ? <Button size="xs" color="orange" appearance="primary">Pending</Button> : <Button size="xs" color="green" appearance="primary">Completed</Button>
          }}
        </Cell>
      </Column>
      <Column width={170} align="center" verticalAlign='center'>
        <HeaderCell>Formative Assessment 4</HeaderCell>
        <Cell>
          {rowData => {
            let isPretestDone = []
            rowData?.studentDetails?.map((item)=>{
              if(item.assessment4_date && item.assessment4_totalMarks && item.assessment4_marksObtained){
                  isPretestDone.push(true)
              } else {
                isPretestDone.push(false)
              }
            })
            return isPretestDone.includes(false) ? <Button size="xs" color="orange" appearance="primary">Pending</Button> : <Button size="xs" color="green" appearance="primary">Completed</Button>
          }}
        </Cell>
      </Column>
      <Column width={170} align="center" verticalAlign='center'>
        <HeaderCell>Summative Assessment</HeaderCell>
        <Cell>
          {rowData => {
            let isPretestDone = []
            rowData?.studentDetails?.map((item)=>{
              if(item.summative_date && item.summative_totalMarks && item.summative_marksObtained){
                  isPretestDone.push(true)
              } else {
                isPretestDone.push(false)
              }
            })
            return isPretestDone.includes(false) ? <Button size="xs" color="orange" appearance="primary">Pending</Button> : <Button size="xs" color="green" appearance="primary">Completed</Button>
          }}
        </Cell>
      </Column>
      </ColumnGroup>
    </Table>
    <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={async()=>{
        setLoading(true)
        const selectedValue = localStorage.getItem('selectedValue');
        const response = await getAllSchoolProgress(selectedValue,'',1,total);
        if(response.data.status){
          const instuctorData = arrayOfJSONWithCount(response.data.data)
          exportToExcel(instuctorData,`${'School_Progress_Report'}-${new Date()}.xlsx`,'SchoolReport')
            setLoading(false);
         }
      
      }} startIcon={<FileDownloadIcon />}>Download</Button>
    </> : selectedTab === "Instuctor Content" ? <> <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <Cell dataKey="createdAt" />
        {/* <Cell>
          {rowData=>moment(rowData?.createdAt).format('DD-MM-YYYY')}
        </Cell> */}
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Division</HeaderCell>
        <Cell dataKey="udiseInfo.division_name" />
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell dataKey="udiseInfo.district" />
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <Cell dataKey="udiseInfo.udise_code" />
      </Column>
      <Column width={300} colSpan={2} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell dataKey="udiseInfo.school_name" />
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Username</HeaderCell>
        <EditableCell dataKey="username" onChange={handleChange} />
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Activity Name</HeaderCell>
        <EditableCell dataKey="activityName" onChange={handleChange} />
      </Column>
      <Column width={200} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Uploaded Files</HeaderCell>
        <Cell>
          {rowData=>{
            return <div className='d-flex'>
            <Button style={{marginRight : '10px'}} color="blue" appearance="primary" size='xs' onClick={()=>viewOpen([rowData?.link])}>View</Button>
            <FileReplace onFetch={fetchUploadedContent} color={"orange"} size={'xs'} title={'Update File'} rowData={rowData} type={'admin'} />
            </div>
          }}
        </Cell>
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <ActionCellGroundExercise dataKey="_id" onClick={handleEditState} />
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Remove</HeaderCell>
        <Cell>
            {rowData => (
              <TrashIcon className='pointer' color='red' onClick={()=>handleDeleteExercie(rowData?._id)} />
          )}
        </Cell>
      </Column>
    </Table> 
    <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={
      async()=>{
        setLoading(true)
        const selectedValue = localStorage.getItem('selectedValue');
        const response = await getAllUploadedContent(selectedValue,1,total);
        if(response.data.status){
          const instuctorData = arrayOfJSONWithCount(response.data.data)
          setLoading(false);
          exportToExcel(instuctorData,`${'Instuctor_Content'}-${new Date()}.xlsx`,'InstuctorContent')
         }
      
      }} startIcon={<FileDownloadIcon />}>Download</Button>
    </> : selectedTab === "Activity Logs" ? <>
    <Navbar appearance="tabs" active={active} onSelect={handleActive} />
    {active === "Activity Logs" ? <> <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Division</HeaderCell>
        <Cell dataKey="divisionName" />
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell>
          {rowData=>`${rowData?.udiseCodesData ? rowData?.udiseCodesData.district : ""}`}
        </Cell>
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <Cell>
          {rowData=>`${rowData?.udiseCodesData ? rowData?.udiseCodesData.udise_code : ""}`}
        </Cell>
      </Column>
      <Column width={100} colSpan={2} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell>
          {rowData=>`${rowData?.udiseCodesData ? rowData?.udiseCodesData.school_name : ""}`}
        </Cell>
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <EditableCell dataKey="createdAt" />
      </Column>
      <Column width={120} align="center" verticalAlign='center'>
        <HeaderCell>Day</HeaderCell>
        <Cell>
          {rowData=>moment(rowData.createdAt).format('dddd') === 'Invalid date' ? 'Day' : moment(rowData.createdAt).format('dddd')}
        </Cell>
      </Column>
      <Column width={200} align="left" verticalAlign='center'>
        <HeaderCell>Activity Description</HeaderCell>
        <EditableCell dataKey="activityDescription" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Start Time</HeaderCell>
        <EditableCell dataKey="startTime" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>End Time</HeaderCell>
        <EditableCell dataKey="endTime" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Comment</HeaderCell>
        <EditableCell dataKey="comment" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Photo (Evidence)</HeaderCell>
        <Cell>
          {rowData => {
            return <Button color="blue" appearance="primary" size="xs" onClick={()=>{
              window.open(rowData?.evidenceLink, '_blank');
            }}>View</Button>
          }}
        </Cell>
      </Column>
    </Table> <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={
      async()=>{
        setLoading(true)
        const selectedValue = localStorage.getItem('selectedValue');
        const response = await getAllActivities(selectedValue,1,total);
        if(response.data.status){
          const instuctorData = arrayOfJSONWithCount(response.data.data)
          setLoading(false);
          exportToExcel(instuctorData,`${'BRC_Activity'}-${new Date()}.xlsx`,'BRCActivityAdmin')
         }
      
      }} startIcon={<FileDownloadIcon />}>Download</Button> </> :
    
    <> <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Division</HeaderCell>
        <Cell dataKey="udiseCodesData.division_name" />
      </Column>
      <Column width={130} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell dataKey='udiseCodesData.district' />
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <Cell dataKey='udiseCodesData.udise_code' />
      </Column>
      <Column width={100} colSpan={2} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell dataKey='udiseCodesData.school_name' />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <EditableCell dataKey="createdAt" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Username</HeaderCell>
        <EditableCell dataKey="username" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Report (Evidence)</HeaderCell>
        <Cell>
          {rowData => {
            return <Button color="blue" appearance="primary" size="xs" onClick={()=>{
              window.open(rowData?.link, '_blank');
            }}>View</Button>
          }}
        </Cell>
      </Column>
    </Table> <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={
      async()=>{
        setLoading(true)
        const selectedValue = localStorage.getItem('selectedValue');
        const response = await getAllActivitiesMonthly(selectedValue,1,total);
        if(response.data.status){
          const instuctorData = arrayOfJSONWithCount(response.data.data)
          setLoading(false);
          exportToExcel(instuctorData,`${'BRC_Activity_Monthly'}-${new Date()}.xlsx`,'BRCActivityMonthly')
         }
      
      }} startIcon={<FileDownloadIcon />}>Download</Button> </> }
     </>
      : selectedTab === "Head Details" ? <> <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Division Name</HeaderCell>
        <EditableCell dataKey="divisionName" />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <EditableCell dataKey="udiseCode" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>First Name</HeaderCell>
        <EditableCell dataKey="firstName" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Last Name</HeaderCell>
        <EditableCell dataKey="lastName" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Designation</HeaderCell>
        <EditableCell dataKey="destination" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Phone Number</HeaderCell>
        <EditableCell dataKey="phoneNumber" />
      </Column>
      <Column width={200} align="left" verticalAlign='center'>
        <HeaderCell>Email</HeaderCell>
        <EditableCell dataKey="email" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Comment</HeaderCell>
        <EditableCell dataKey="comment" />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>Created At</HeaderCell>
        <EditableCell dataKey="createdAt" />
      </Column>
    </Table>
    <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={
      async()=>{
        setLoading(true)
        const selectedValue = localStorage.getItem('selectedValue');
        const response = await getAllHeadDetails(selectedValue,1,total);
        if(response.data.status){
          const instuctorData = arrayOfJSONWithCount(response.data.data)
          setLoading(false);
          exportToExcel(instuctorData,`${'HeadDetails'}-${new Date()}.xlsx`,'HeadDetails')
         }
      
      }} startIcon={<FileDownloadIcon />}>Download</Button>
    </>
     : selectedTab === "Instuctor OldAttendance" ? <> <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Division Name</HeaderCell>
        <EditableCell dataKey="Division Name" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>District Name</HeaderCell>
        <EditableCell dataKey="District Name" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <EditableCell dataKey="UDISE Code" />
      </Column>
      <Column width={300} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <EditableCell dataKey="School Name " />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Attendance</HeaderCell>
        <Cell>
          {rowData=><Button size="xs" color="blue" appearance="primary" onClick={()=>{
            setOpenView(true);
            setViewData(rowData.attendances);
          }}>View</Button>}
        </Cell>
      </Column>
    </Table> 
    <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={()=>exportToExcel(data,`${'Instuctor_Old_Attendance'}-${new Date()}.xlsx`,'InstuctorOldAttendance')} startIcon={<FileDownloadIcon />}>Download</Button>
     </>: selectedTab === "Instuctor Session" ? <>
     <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <EditableCell dataKey="udiseCode" />
      </Column>
      <Column width={130} align="center" verticalAlign='center'>
        <HeaderCell>Division</HeaderCell>
        <Cell>
          {rowData=>`${rowData?.udiseCodesData?.length ? rowData?.udiseCodesData[0]?.division_name : ""}`}
        </Cell>
      </Column>
      <Column width={120} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell>
          {rowData=>`${rowData?.udiseCodesData?.length ? rowData?.udiseCodesData[0]?.district : ""}`}
        </Cell>
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>Block</HeaderCell>
        <Cell>
          {rowData=>`${rowData?.udiseCodesData?.length ? rowData?.udiseCodesData[0]?.block_name : ""}`}
        </Cell>
      </Column>
      <Column width={300} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <Cell>
          {rowData=>`${rowData?.udiseCodesData?.length ? rowData?.udiseCodesData[0]?.school_name : ""}`}
        </Cell>
      </Column>
      <Column width={80} align="center" verticalAlign='center'>
        <HeaderCell>Grade 6</HeaderCell>
        <Cell>
        {rowData=>rowData?.sessionPlansByGrade?.grade6 ? rowData?.sessionPlansByGrade.grade6?.length + `/96` : `0/96`}
        </Cell>
      </Column>
      <Column width={80} align="center" verticalAlign='center'>
        <HeaderCell>Grade 7</HeaderCell>
        <Cell>
        {rowData=>rowData?.sessionPlansByGrade?.grade7 ? rowData?.sessionPlansByGrade.grade7?.length + `/96` : `0/96`}
        </Cell>
      </Column>
      <Column width={80} align="center" verticalAlign='center'>
        <HeaderCell>Grade 8</HeaderCell>
        <Cell>
        {rowData=>rowData?.sessionPlansByGrade?.grade8 ? rowData?.sessionPlansByGrade.grade8?.length + `/96` : `0/96`}
        </Cell>
      </Column>
      <Column width={80} align="center" verticalAlign='center'>
        <HeaderCell>Grade 9</HeaderCell>
        <Cell>
        {rowData=>rowData?.sessionPlansByGrade?.grade9 ? rowData?.sessionPlansByGrade.grade9?.length + `/96` : `0/96`}
        </Cell>
      </Column>
      <Column width={80} align="center" verticalAlign='center'>
        <HeaderCell>Grade 10</HeaderCell>
        <Cell>
        {rowData=>rowData?.sessionPlansByGrade?.grade10 ? rowData?.sessionPlansByGrade.grade10?.length + `/96` : `0/96`}
        </Cell>
      </Column>
      <Column width={80} align="center" verticalAlign='center'>
        <HeaderCell>Grade 11</HeaderCell>
        <Cell>
          {rowData=>rowData?.sessionPlansByGrade?.grade11 ? rowData?.sessionPlansByGrade.grade11?.length + `/96`: `0/96`}
        </Cell>
      </Column>
      <Column width={80} align="center" verticalAlign='center'>
        <HeaderCell>Grade 12</HeaderCell>
        <Cell>
          {rowData=>rowData?.sessionPlansByGrade?.grade12 ? rowData?.sessionPlansByGrade.grade12?.length + `/96`: `0/96`}
        </Cell>
      </Column>
    </Table> 
    <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : 'mt-2'} style={{width : '150px'}} color='violet' appearance='primary' onClick={()=>exportToExcel(data,`${'Instuctor_Sessions'}-${new Date()}.xlsx`,'InstuctorSession')} startIcon={<FileDownloadIcon />}>Download</Button>
    </>
     : selectedTab === "Instuctor OldAttendance" ? <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Division Name</HeaderCell>
        <EditableCell dataKey="Division Name" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>District Name</HeaderCell>
        <EditableCell dataKey="District Name" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>UDISE Code</HeaderCell>
        <EditableCell dataKey="UDISE Code" />
      </Column>
      <Column width={300} align="left" verticalAlign='center'>
        <HeaderCell>School Name</HeaderCell>
        <EditableCell dataKey="School Name " />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Attendance</HeaderCell>
        <Cell>
          {rowData=><Button size="xs" color="blue" appearance="primary" onClick={()=>{
            setOpenView(true);
            setViewData(rowData.attendances);
          }}>View</Button>}
        </Cell>
      </Column>
    </Table> : selectedTab === "ICT Lab Asset Report" ? <Table width={900} bordered cellBordered autoHeight headerHeight={80} data={data} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={150} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Name Of The BRC</HeaderCell>
        <Cell dataKey="school_name" />
      </Column>
      <Column width={100} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Code</HeaderCell>
        <Cell dataKey='udise_code' />
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>District</HeaderCell>
        <Cell dataKey='district' />
      </Column>
      <Column width={120} colSpan={2} align="center" verticalAlign='center'>
        <HeaderCell>Block</HeaderCell>
        <Cell dataKey='block_name' />
      </Column>
      <Column width={180} align="center" verticalAlign='center'>
        <HeaderCell>Assest Registry</HeaderCell>
        <Cell>
          {rowData => {
            return <>
            <Button loading={loading} style={{marginRight : '10px'}} color="blue" appearance="primary" size="xs" onClick={()=>{
              handleAssets(rowData.brcassetdetails)
            }}>View</Button>
            <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : ''} color="violet" appearance="primary" size="xs" onClick={()=>{
              exportToExcel(rowData.brcassetdetails,`${'BRC_Assets'}-${new Date()}.xlsx`,'BRCAssets')
            }}>Download</Button>
            </>
          }}
        </Cell>
      </Column>
      <Column width={180} align="center" verticalAlign='center'>
        <HeaderCell>School List</HeaderCell>
        <Cell>
          {rowData => {
            return <>
              <Button loading={loading} style={{marginRight : '10px'}} color="blue" appearance="primary" size="xs" onClick={()=>{
                    handleSchools(rowData.schoolListData)
              }}>View</Button>
              <Button loading={loading} className={adminInfo._id === '65c12562b56f94f01c695aa5' ? 'd-none' : ''} color="violet" appearance="primary" size="xs" onClick={()=>{
                    exportToExcel(rowData.schoolListData,`${'BRC_School_Equipment_List'}-${new Date()}.xlsx`,'BRCSchool')
              }}>Download</Button>
              </>
          }}
        </Cell>
      </Column>
    </Table> :   ""}
    <div style={{ padding: 20,display : selectedTab === 'E-Content' ? 'none' : '' }}>
        <Pagination
          disabled={loading}
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={selectedTab !== "Instuctor Session" ? total : data.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={handlePage}
          onChangeLimit={handleChangeLimit}
        />
      </div>

  </>

})

export const InstuctorTableData = (props) => {
  const data = props.data

  return (
    <Table
      isTree
      bordered
      cellBordered
      rowKey="value"
      autoHeight
      data={data}
      /** shouldUpdateScroll: whether to update the scroll bar after data update **/
      shouldUpdateScroll={false}
      onExpandChange={(isOpen, rowData) => {
        console.log(isOpen, rowData);
      }}
      renderTreeToggle={(icon, rowData) => {
        if (rowData?.children && rowData?.children.length === 0) {
          return <SpinnerIcon spin />;
        }
        return icon;
      }}
    >
      <Column width={60}>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="value" />
      </Column>
      <Column treeCol flexGrow={1}>
        <HeaderCell>Lesson Plans</HeaderCell>
        <Cell>
          {rowData => {
            return rowData?.label[0] === "E" || rowData?.label[0] === "H" || rowData?.label[0] === "M" ? <Button disabled={rowData.label !== 'English'} appearance="link" onClick={()=>handleDownload(rowData.link)}>{rowData?.label}</Button> : rowData?.label
          }}
        </Cell>
      </Column>
    </Table>
  );
};

export const BRCTable = () => {
  const [loading, setLoading] = useState(false);
  const [data,setData] = useState([]);
  const [instuctorInfo,setInstuctorInfo] = useState(null);
  const [selectedTable,setSelectedTable] = useState('BRC Activity Log');
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find(item => item.id === id)[key] = value;
    setData(nextData);
  };
  const handleEditState = id => {
    const nextData = Object.assign([], data);
    const activeItem = nextData.find(item => item.id === id);
    activeItem.status = activeItem.status ? null : 'EDIT';
    if(activeItem.status === null){
      updateActivityLogs(activeItem)
    }
    setData(nextData);
  };

  const handleEditHead = id => {
    const nextData = Object.assign([], data);
    const activeItem = nextData.find(item => item.id === id);
    activeItem.status = activeItem.status ? null : 'EDIT';
    if(activeItem.status === null){
      updateAHead(activeItem)
    }
    setData(nextData);
  };
  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const formatedData = data.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const fetchBRCProfile = async (username) => {
    try {
      setLoading(true)
      const response = await getAllActivityLogs(username);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchBRCMonthlyLogs = async (username) => {
    try {
      setLoading(true)
      const response = await getAllActivityMonthlyLogs(username);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchBRCHeads = async (json) => {
    try {
      setLoading(true)
      const response = await getAllHeads(json);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const updateActivityLogs = async (payload) => {
    try {
      const response = await updateActivityLog(payload);
      if(response.data.status){
        fetchBRCProfile(response.data.data.username)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const updateAHead = async (payload) => {
    try {
      const response = await updateHead(payload);
      if(response.data.status){
        fetchBRCHeads(response.data.data.udiseCode)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleDeleteActivity = async (id) => {
    try {
      const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
      const payload = id;
      const response = await removeActivityLog(payload);
      if(response.data.status){
        fetchBRCProfile(instuctorInfo.username);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleDeleteHead = async (id) => {
    try {
      const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
      const payload = id;
      const response = await removeHead(payload);
      if(response.data.status){
        fetchBRCHeads(instuctorInfo.udiseCode)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleTable = async (e) => {
    setSelectedTable(e);
    switch (e) {
      case 'BRC Activity Log':
        fetchBRCProfile(instuctorInfo.username);
        break;
      case 'BRC Monthly Activity Log':
        fetchBRCMonthlyLogs(instuctorInfo.username);
        break;
      case 'BRC Head Details':
        fetchBRCHeads(instuctorInfo.udiseCode)
        break;
    
      default:
        break;
    }
  }
  const handleExport = () => {
    exportToExcel(data, `${instuctorInfo.username}-${new Date()}.xlsx`,'BRCActivity');
  }
  const ActionCellBRC = ({ rowData, dataKey, onClick,disabled, ...props }) => {
    return (
      <Cell {...props} style={{ padding: '6px' }}>
        <Button
          appearance="link"
          disabled={(rowData?.status === 'EDIT' && !rowData['createdAt']?.length) || (rowData?.status === 'EDIT' && !rowData['activityDescription']?.length) || (rowData?.status === 'EDIT' && !rowData['startTime']?.length) || (rowData?.status === 'EDIT' && !rowData['endTime']?.length)}
          onClick={() => {
            onClick(rowData?.id);
          }}
        >
          {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>
      </Cell>
    );
  };

  const ActionCellHead = ({ rowData, dataKey, onClick,disabled, ...props }) => {
    return (
      <Cell {...props} style={{ padding: '6px' }}>
        <Button
          appearance="link"
          disabled={ rowData?.status === 'EDIT' && !rowData['firstName']?.length || rowData?.status === 'EDIT' && !rowData['lastName']?.length || rowData?.status === 'EDIT' && !rowData['destination']?.length || rowData?.status === 'EDIT' && !rowData['phoneNumber'] || rowData?.status === 'EDIT' && !rowData['email']?.length}
          onClick={() => {
            onClick(rowData?.id);
          }}
        >
          {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>
      </Cell>
    );
  };

  React.useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor) {
      if(!instuctorInfo) {
        setInstuctorInfo(instuctor);
        fetchBRCProfile(instuctor.username);
      }
    }
  },[])
  return (
    <>
  <Nav disabled={loading} appearance="tabs" className='mb-3 mt-3' title={selectedTable} onSelect={handleTable}>
    <Nav.Item active={selectedTable === "BRC Activity Log"} eventKey="BRC Activity Log" icon={<UserBadgeIcon />}>BRC Daily Activity Log</Nav.Item>
    <Nav.Item active={selectedTable === "BRC Monthly Activity Log"} eventKey="BRC Monthly Activity Log" icon={<UserBadgeIcon />}>BRC Monthly Activity Log</Nav.Item>
    <Nav.Item active={selectedTable === "BRC Head Details"} eventKey="BRC Head Details" icon={<AdminIcon />}>BRC Administration Details</Nav.Item>
  </Nav>
  {selectedTable === "BRC Activity Log" ? <AddNewActivity onFetch={fetchBRCProfile} loading={loading} heading={'Add A New Activity Log'} /> : 
  selectedTable === "BRC Head Details" ? <AddNewHead onFetch={fetchBRCHeads} loading={loading} /> : ''
  }
  
  
    <div className="rs-table-column-group-header-content table-heading2 text-white">School UDISE Code : {instuctorInfo?.schoolInfo[0]?.udise_code ? instuctorInfo.schoolInfo[0].udise_code : ''}</div>
    <div className={ selectedTable === "BRC Activity Log" ? "rs-table-column-group-header-content table-heading text-white" : "d-none"}>BRC Daily Activity Log</div>
    <div className={ selectedTable === "BRC Monthly Activity Log" ? "rs-table-column-group-header-content table-heading text-white" : "d-none"}>BRC Monthly Activity Log</div>
    <div className={ selectedTable === "BRC Head Details" ? "rs-table-column-group-header-content table-heading text-white" : "d-none"}>BRC Administration Details </div>
    {selectedTable === "BRC Activity Log" ? <Table wordWrap="break-all" bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={120} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <EditableCell dataKey="createdAt" onChange={handleChange} type={'date'} />
      </Column>
      <Column width={120} align="center" verticalAlign='center'>
        <HeaderCell>Day</HeaderCell>
        <Cell>
          {rowData=>moment(rowData.createdAt).format('dddd') === 'Invalid date' ? 'Day' : moment(rowData.createdAt).format('dddd')}
        </Cell>
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Activity Description</HeaderCell>
        <EditableCell dataKey="activityDescription" onChange={handleChange} />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Start Time</HeaderCell>
        <EditableCell dataKey="startTime" onChange={handleChange} type={'time'} step="3600" />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>End Time</HeaderCell>
        <EditableCell dataKey="endTime" onChange={handleChange} type={'time'} step="3600" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Comment</HeaderCell>
        <EditableCell dataKey="comment" onChange={handleChange} />
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <ActionCellBRC dataKey="_id" onClick={handleEditState} />
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Remove</HeaderCell>
        <Cell>
            {rowData => (
              <TrashIcon className='pointer' color='red' onClick={()=>handleDeleteActivity(rowData?._id)} />
          )}
        </Cell>
      </Column>
    </Table> : selectedTable === "BRC Monthly Activity Log" ? <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <EditableCell dataKey="createdAt" onChange={handleChange} />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Username</HeaderCell>
        <EditableCell dataKey="username" onChange={handleChange} />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Report Proof</HeaderCell>
        <Cell>
          {rowData=>{
            return <Button size='xs' color='blue' appearance='primary' onClick={()=>window.open(rowData.link)}>View</Button>
           
          }}
        </Cell>
      </Column>
      {/* <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <ActionCellHead dataKey="_id" onClick={handleEditHead} />
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Remove</HeaderCell>
        <Cell>
            {rowData => (
              <TrashIcon className='pointer' color='red' onClick={()=>handleDeleteHead(rowData?._id)} />
          )}
        </Cell>
      </Column> */}
    </Table> : <Table wordWrap="break-word" bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>First Name</HeaderCell>
        <EditableCell dataKey="firstName" onChange={handleChange} />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>Last Name</HeaderCell>
        <EditableCell dataKey="lastName" onChange={handleChange} />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Designation</HeaderCell>
        <EditableCell dataKey="destination" onChange={handleChange} />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Phone Number</HeaderCell>
        <EditableCell dataKey="phoneNumber" onChange={handleChange} />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Email</HeaderCell>
        <EditableCell dataKey="email" onChange={handleChange} />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Comment</HeaderCell>
        <EditableCell dataKey="comment" onChange={handleChange} />
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <ActionCellHead dataKey="_id" onClick={handleEditHead} />
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Remove</HeaderCell>
        <Cell>
            {rowData => (
              <TrashIcon className='pointer' color='red' onClick={()=>handleDeleteHead(rowData?._id)} />
          )}
        </Cell>
      </Column>
    </Table>}
    <div style={{ padding: 20}}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={data.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
      <Button loading={loading} className={selectedTable === "BRC Activity Log" ? '' : 'd-none'} style={{marginRight : '10px'}} color="violet" appearance="primary" startIcon={<FileDownloadIcon />} onClick={handleExport}>Download Monthly Report</Button>
      {selectedTable === "BRC Monthly Activity Log" ? <FileUploader color={'orange'} title={'Upload Monthly Report'} isSignedCopy={true} onFetch={handleTable} /> : ''}
            
    </>
  );
}
export const GroundExercise = () => {
  const uploader = useRef();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [data,setData] = useState([]);
  const [instuctorInfo, setInstuctorInfo] = useState({username : '', schoolInfo : [{division_name : ''}]});
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [fileList, setFileList] = useState([]);
  const [imageList,setImageList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpenView(false)
    fetchFilesByUsername(instuctorInfo.username);
    reset();
  };
  const [formValue, setFormValue] = useState({
      date: '',
      activityName: '',
      isGroundExercise : true
  });
  const reset = () => {
    setFormValue({
      date : '',
      activityName : '',
    });
    setFileList([]);
  }
  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find(item => item.id === id)[key] = value;
    setData(nextData);
  };
  const handleEditState = id => {
    const nextData = Object.assign([], data);
    const activeItem = nextData.find(item => item.id === id);
    activeItem.status = activeItem.status ? null : 'EDIT';
    if(activeItem.status === null){
      updateFiles(activeItem)
    }
    setData(nextData);
  };

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const formatedData = data.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const fetchFilesByUsername = async (json) => {
    try {
      setLoading(true)
      const response = await getFilesByUsername(json);
      if(response.data.status){
        let data = arrayOfJSONWithCount(response.data.data)
        setData(data);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const updateFiles = async (payload) => {
    try {
      const response = await updateFilesByUsername(payload);
      if(response.data.status){
        fetchFilesByUsername(response.data.data.username)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const ActionCellGround = ({ rowData, dataKey, onClick,disabled, ...props }) => {
    return (
      <Cell {...props} style={{ padding: '6px' }}>
        <Button
          appearance="link"
          disabled={!rowData['createdAt']?.length || !rowData['activityName']?.length}
          onClick={() => {
            onClick(rowData?.id);
          }}
        >
          {rowData?.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>
      </Cell>
    );
  };
  const handleDeleteExercie = async (id) =>{
    try {
      const payload = {
        "id": id
      }
      const response = await deleteAFile(payload);
      if(response.data.status){
        fetchFilesByUsername(instuctorInfo.username)
      }
    } catch (error) {
      console.log(error)
    }
  }
  React.useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor) {
        setInstuctorInfo(instuctor);
        fetchFilesByUsername(instuctor.username);
    }
  },[])
  return (
    <>
    <div>
    <ViewImages data={imageList} isOpen={openView} handleClose={handleClose} />
      <p className='mb-3'>Upload Images From On Ground Exercise</p>
        <Button color="blue" appearance="primary" onClick={handleOpen} startIcon={<FileUpload />}>Upload</Button>
        <Modal backdrop={'static'} keyboard={false} open={open} onClose={()=>setOpen(false)}>
            <Modal.Header closeButton={fileList.length === 0}>
            <Modal.Title>Images From On Ground Exercise</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                >
                <Form.Group controlId="date">
                  <Form.ControlLabel>Date</Form.ControlLabel>
                  <Form.Control name="date" type='date' />
                </Form.Group>
                <Form.Group controlId="activityName">
                  <Form.ControlLabel>Activity Name</Form.ControlLabel>
                  <Form.Control name="activityName" />
                </Form.Group>
                <p>Photo (Evidance) </p>
                <Uploader
                    fileList={fileList}
                    listType="picture-text"
                    action={`${url}/instuctor/file/upload?username=${instuctorInfo.username}&isSignedCopy=${false}&createdAt=${new Date(formValue.date)}&activityName=${formValue.activityName}&divisionName=${instuctorInfo.schoolInfo[0].division_name}&isGroundExercise=${true}`}
                    onSuccess={(response)=>{
                        console.log(response)
                    }}
                    onChange={setFileList}
                    ref={uploader}
                    disabled={!formValue.activityName.length || !formValue.date.length}
            >
        <Button disabled={!formValue.activityName.length || !formValue.date.length}>Select files...</Button>
      </Uploader>
            </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button disabled={!fileList.length} onClick={handleClose} appearance="primary">
                Ok
            </Button>
            </Modal.Footer>
        </Modal>
            <Divider />
            
      </div>
     <Table bordered cellBordered autoHeight headerHeight={80} data={formatedData} loading={loading}>
      <Column width={50} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>
      <Column width={150} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <EditableCell dataKey="createdAt" onChange={handleChange} />
      </Column>
      <Column width={300} align="center" verticalAlign='center'>
        <HeaderCell>Activity Name</HeaderCell>
        <EditableCell dataKey="activityName" onChange={handleChange} />
      </Column>
      <Column width={120} align="center" verticalAlign='center'>
        <HeaderCell>Username</HeaderCell>
        <Cell dataKey="username" onChange={handleChange} />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Division Name</HeaderCell>
        <Cell dataKey="divisionName" onChange={handleChange} />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Photo (Evidance)</HeaderCell>
        <Cell>
          {rowData => {
            return <div className='d-flex'>
              <Button style={{marginRight : '10px'}} color="blue" appearance="primary" size="xs" onClick={()=>{
              setOpenView(true)
              setImageList([rowData.link])
            }}>View</Button>
            <FileReplace onFetch={fetchFilesByUsername} color={"orange"} size={'xs'} title={'Update File'} rowData={rowData} type={'instuctor'} />
            </div> 
          }}
        </Cell>
      </Column>
      <Column align="center" verticalAlign='center'>
        <HeaderCell>Action</HeaderCell>
        <ActionCellGround disabled={false} dataKey="_id" onClick={handleEditState} />
      </Column>
      <Column flexGrow={1} align="center" verticalAlign='center'>
        <HeaderCell>Remove</HeaderCell>
        <Cell>
            {rowData => (
              <TrashIcon className='pointer' color='red' onClick={()=>handleDeleteExercie(rowData?._id)} />
          )}
        </Cell>
      </Column>
    </Table>
    <div className='mt-2'>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={data.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  );
}

export const SessionPlan = () => {
  const [loading, setLoading] = useState(false);
  const [data,setData] = useState({_id: "",udiseCode: "",grade: "",divison: "",sessionPlans : []});
  const [instuctorInfo, setInstuctorInfo] = useState({udiseCode : '',schoolInfo : [{division_name : ''}]});
  const [selectedGrade,setSelectedGrade] = useState("");
  const [limit, setLimit] = React.useState(9);
  const [page, setPage] = React.useState(1);
  const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />;
  const handleGrade = async (e) => {
    setSelectedGrade(e);
    fetchSessionPlans(e,instuctorInfo.udiseCode,instuctorInfo.schoolInfo[0].division_name);
  }

  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find(item => item.id === id)[key] = value;
    setData(nextData);
  };
  const updateStatus = async (numberToUpdate, newStatus) => {
      const updatedArray = data.sessionPlans.map(item => {
        if (item.number === numberToUpdate) {
            // Update status based on the provided newStatus
            return { ...item, status: newStatus };
        }
        return item;
    });
    updateASessionPlan(updatedArray);
  }
  const updateASessionPlan = async (json) => {
    try {
      setLoading(true)
      const payload = {
        _id : data._id,
        udiseCode : data.udiseCode,
        grade : data.grade,
        divison : data.divison,
        sessionPlans : json
      }
      const response = await updateSessionPlans(payload);
      if(response.data.status){
        fetchSessionPlans(response.data.data.grade,response.data.data.udiseCode,response.data.data.division)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const formatedData = data.sessionPlans.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const fetchSessionPlans = async (grade,udiseCode,division) => {
    try {
      setLoading(true)
      const json = {
        udiseCode : udiseCode,
        grade : grade,
        division : division
      }
      const response = await getOrAddSessionPlans(json);
      if(response.data.isCreated){
        fetchSessionPlans(grade,udiseCode,division)
      } else if(response.data.status){
        setData(response.data.data);
        setTimeout(()=>{
          setLoading(false);
        },100)
      } else {
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  React.useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor) {
        setInstuctorInfo(instuctor);
        fetchSessionPlans(selectedGrade,instuctor.udiseCode,instuctor.schoolInfo[0].division_name);
    }
  },[])
  return<>
      <Dropdown disabled={loading} color="violet" appearance="primary" style={{marginLeft : '10px'}} className='mb-3 mt-3' title={selectedGrade === "" ? 'Select Grade' : `Grade ${selectedGrade}`} endIcon={<UserBadgeIcon />} onSelect={handleGrade}>
    <Dropdown.Item active={selectedGrade === "6"} eventKey="6" icon={<UserBadgeIcon />}>Grade 6</Dropdown.Item>
    <Dropdown.Item active={selectedGrade === "7"} eventKey="7" icon={<UserBadgeIcon />}>Grade 7</Dropdown.Item>
    <Dropdown.Item active={selectedGrade === "8"} eventKey="8" icon={<UserBadgeIcon />}>Grade 8</Dropdown.Item>
    <Dropdown.Item active={selectedGrade === "9"} eventKey="9" icon={<UserBadgeIcon />}>Grade 9</Dropdown.Item>
    <Dropdown.Item active={selectedGrade === "10"} eventKey="10" icon={<UserBadgeIcon />}>Grade 10</Dropdown.Item>
    <Dropdown.Item active={selectedGrade === "11"} eventKey="11" icon={<UserBadgeIcon />}>Grade 11</Dropdown.Item>
    <Dropdown.Item active={selectedGrade === "12"} eventKey="12" icon={<UserBadgeIcon />}>Grade 12</Dropdown.Item>
  </Dropdown>
  {/* <Dropdown disabled={true} color="green" appearance="primary" style={{marginLeft : '10px'}} title={'English'}>
    <Dropdown.Item active={true} eventKey="6" icon={<UserBadgeIcon />}>English</Dropdown.Item>
    <Dropdown.Item eventKey="7" icon={<UserBadgeIcon />}>Hindi</Dropdown.Item>
    <Dropdown.Item eventKey="8" icon={<UserBadgeIcon />}>Marathi</Dropdown.Item>
  </Dropdown> */}
     <Table bordered cellBordered autoHeight headerHeight={80} data={removeDuplicateWeek(formatedData)} loading={loading} wordWrap>
      {/* <Column width={70} align="center" verticalAlign='center'>
        <HeaderCell>Sr No</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="number" />
      </Column> */}
      <ColumnGroup style={{color : '#000',fontWeight : 'bold'}} header="Session Plan - Information Communication & Technology (Daywise)" align="center" verticalAlign='center'>
      <Column width={50} align="center" verticalAlign='center' rowSpan={rowData => {
          return rowData.rowSpan;
        }}>
        <HeaderCell>Week</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="week" />
      </Column>
      <Column width={50} align="center" verticalAlign='center'>
        <HeaderCell>Day</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="number" />
      </Column>
      <Column width={300} align="left" verticalAlign='center'>
        <HeaderCell>Topic/Chapter Name</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="topicChapterName" />
      </Column>
      <Column width={300} align="center" verticalAlign='center'>
        <HeaderCell>Session</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="session" />
      </Column>
      <Column width={50} align="center" verticalAlign='center'>
        <HeaderCell>#</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="number" />
      </Column>
      <Column width={400} className="fs-14" align="left" verticalAlign='center'>
        <HeaderCell>Sub topic</HeaderCell>
        <Cell style={{fontSize : '12px'}}>
          {rowData => {
            const formattedTopic = rowData.subtopic.split("●").filter(item => item.trim() !== "");
            return <ul>
            {formattedTopic.map((item, index) => (
              <li key={index}>● {item.trim()}</li>
            ))}
          </ul>
          }}
        </Cell>
      </Column>
      </ColumnGroup>
      <ColumnGroup header="School Training" align="center" verticalAlign='center'>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Theory</HeaderCell>
        <Cell style={{fontSize : '12px'}}>
          {rowData=>{
            return rowData.theory === '√' ? '✓' : rowData.theory
          }}
        </Cell>
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Practical</HeaderCell>
        <Cell style={{fontSize : '12px'}}>
          {rowData=>{
            return rowData.practical === '√' ? '✓' : rowData.practical
          }}
        </Cell>
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Class Exercise (Worksheet)</HeaderCell>
        <Cell style={{fontSize : '12px'}}>
          {rowData=>{
            return rowData.classExercise === '√' ? '✓' : rowData.classExercise
          }}
        </Cell>
      </Column>
      <Column width={160} align="center" verticalAlign='center'>
        <HeaderCell>PBL (Activity/Project)</HeaderCell>
        <Cell style={{fontSize : '12px'}}>
          {rowData=>{
            return rowData.PBL === '√' ? '✓' : rowData.PBL
          }}
        </Cell>
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Assessment</HeaderCell>
        <Cell style={{fontSize : '12px'}}>
          {rowData=>{
            return rowData.assessment === '√' ? '✓' : rowData.assessment
          }}
        </Cell>
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Status</HeaderCell>
        <Cell>
          {rowData=><Checkbox defaultChecked={rowData.status} onChange={(e,checked)=>updateStatus(rowData.number,checked)}></Checkbox>}
        </Cell>
      </Column>
      </ColumnGroup>
    </Table>
    <div className='mt-2'>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={data.sessionPlans.length}
          limitOptions={[9, 30, 48]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
      </>
}

export const BRCFormTable = () => {
   const [active, setActive] = useState('BRC');
   const [data,setData] = useState([]);
   const [loading,setLoading] = useState(false);
   const [limit, setLimit] = React.useState(10);
   const [page, setPage] = React.useState(1);
   const [instructorInfo,setInstuctorInfo] = useState({schoolInfo : [
    {
        "_id": "",
        "slno": "",
        "division_name": "",
        "division_head": "",
        "district": "",
        "block_name": "",
        "udise_code": "",
        "school_name": ""
    }
]});
   const infoTableData = [
    {
      name : 'Name Of The BRC',
      value : instructorInfo.schoolInfo[0].school_name
    },
    {
      name : 'Code',
      value : instructorInfo.schoolInfo[0].udise_code
    },
    {
      name : 'District',
      value : instructorInfo.schoolInfo[0].district
    },
    {
      name : 'Block',
      value : instructorInfo.schoolInfo[0].block_name
    },
   ]

   const fetchAllAssets = async (udiseCode) => {
      try {
        setLoading(true)
        const response = await getAllAssets(udiseCode);
        if(response.data.status){
          setData(response.data.data);
          setTimeout(()=>{
            setLoading(false);
          },100)
        }
      } catch (error) {
        console.log(error)
      }
   }

   const fetchAllSchoolList = async (udiseCode) => {
    try {
      setLoading(true)
      const response = await getAllSchoolList(udiseCode);
      if(response.data.status){
        setData(response.data.data);
        setTimeout(()=>{
          setLoading(false);
        },100)
      }
    } catch (error) {
      console.log(error)
    }
   }
   const formatedData = arrayOfJSONWithCount(data);
   const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };
   useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor){
      setInstuctorInfo(instuctor);
      if(active === "BRC") {
        fetchAllAssets(instuctor.udiseCode)
      } else if(active === "SchoolList") {
        fetchAllSchoolList(instuctor.udiseCode)
      }
      
    }
   },[active])
    return  <div>
      <div className='d-flex justify-content-center'>
        <Table cellBordered width={400} height={320} data={infoTableData}>
      <Column width={200} align="center">
        <HeaderCell>Entilements</HeaderCell>
        <Cell dataKey="name" />
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Inputs</HeaderCell>
        <Cell dataKey="value" />
      </Column>
    </Table>
   </div>
   <Nav appearance="tabs" active={active} onSelect={setActive} style={{ marginBottom: 50 }}>
        <Nav.Item active={active === "BRC"} eventKey="BRC">BRC - Assets Register</Nav.Item>
        <Nav.Item active={active === "SchoolList"} eventKey="SchoolList">BRC - Aligned School List</Nav.Item>
      </Nav>
   <div className={active === "BRC" ? 'w-100' : 'd-none'}>
    <AddNewAsset onFetch={fetchAllAssets} loading={loading} />
   <Table
      data={formatedData}
      bordered
      cellBordered
      autoHeight
      affixHeader
      affixHorizontalScrollbar
      className='mb-4'
      loading={loading}
    >
      <Column width={50} align="center">
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>

      <Column width={200}>
        <HeaderCell>Name Of The Equipment</HeaderCell>
        <Cell dataKey="nameOfTheEquipment" />
      </Column>

      <Column width={100}>
        <HeaderCell>Quantity</HeaderCell>
        <Cell dataKey="quantity" />
      </Column>

      <Column width={200}>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey="status" />
      </Column>

      <Column width={100}>
        <HeaderCell>Remarks</HeaderCell>
        <Cell dataKey="remarks" />
      </Column>
      {/* <Column width={125}>
        <HeaderCell>Action</HeaderCell>
        <Cell dataKey="gender" />
      </Column> */}
    </Table>
    </div>
    <div className={active === "SchoolList" ? 'w-100' : 'd-none'}>
    <AddNewSchoolList onFetch={fetchAllSchoolList} loading={loading} />
    <Table
      data={formatedData}
      bordered
      cellBordered
      autoHeight
      affixHeader
      affixHorizontalScrollbar
      width={'100%'}
      loading={loading}
    >
      <Column width={50} align="center">
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>

      <Column width={200}>
        <HeaderCell>Name Of The Schools</HeaderCell>
        <Cell dataKey="nameOfTheEquipment" />
      </Column>

      <Column width={100}>
        <HeaderCell>UDISE Code</HeaderCell>
        <Cell dataKey="code" />
      </Column>

      <Column width={200}>
        <HeaderCell>Block</HeaderCell>
        <Cell dataKey="block" />
      </Column>

      <Column width={100}>
        <HeaderCell>HM Name</HeaderCell>
        <Cell dataKey="hmName" />
      </Column>
      <Column width={100}>
        <HeaderCell>HM Contact</HeaderCell>
        <Cell dataKey="hmContact" />
      </Column>
      {/* <Column width={155}>
        <HeaderCell>Action</HeaderCell>
        <Cell dataKey="gender" />
      </Column> */}
    </Table>
    </div>
    <div className='mt-2'>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={formatedData.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
      </div>
}

export const AdminSessionPlans = ({selectedValue}) =>{
  const [loading, setLoading] = useState(false);
  const [data,setData] = useState([]);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [openSessions,setOpenSessions] = useState(false);
  const [sessionPlanData,setSessionPlanData] = useState([]);
  const handleClose = () => {
    setOpenSessions(false);
  }
  const viewSessions = (rowData) => {
    setOpenSessions(true);
    setSessionPlanData(rowData);
  }
  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const formatedData = data.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const fetchInstuctorsSessionPlan = async (sortBy) => {
    try {
      setLoading(true)
        setData(arrayOfJSONWithCount(STATIC_JSON.sessionPlansForAdmin));
        setTimeout(()=>{
          setLoading(false);
        },100)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    fetchInstuctorsSessionPlan(selectedValue);
  },[])
  return <>
  <ViewSessionPlans data={removeDuplicateWeek(sessionPlanData)} isOpen={openSessions} handleClose={handleClose} />
  <Table bordered cellBordered autoHeight width={470} headerHeight={80} data={formatedData} loading={loading}>
  <Column width={70} align="center" verticalAlign='center'>
    <HeaderCell>Sr No</HeaderCell>
    <Cell dataKey="count" />
  </Column>
  <Column width={200} align="center" verticalAlign='center'>
    <HeaderCell>Grade</HeaderCell>
    <EditableCell dataKey="grade" />
  </Column>
  <Column width={200} align="center" verticalAlign='center'>
    <HeaderCell>Session Plan</HeaderCell>
    <Cell>
      {rowData =><Button size='xs' color='violet' appearance='primary' onClick={()=>viewSessions(rowData.sessionPlans)}>View</Button>}
    </Cell>
  </Column>
</Table>
  </>
}