import React , {useEffect, useRef} from 'react';
import { Modal, Button,Form, Schema } from 'rsuite';
import AdminIcon from '@rsuite/icons/Admin';
import { addAHead, adminAdd } from '../../services/services';
// Notifications
import { Message, useToaster,InputGroup } from 'rsuite';

const { StringType,NumberType } = Schema.Types;

const data = ['Amravati', 'Aurangabad', 'Kokan', 'Nagpur','Nashik','Pune'];

const model = Schema.Model({
    firstName: StringType().containsLetterOnly().isRequired('This field is required.'),
    lastName: StringType().containsLetterOnly().isRequired('This field is required.'),
    destination: StringType().isRequired('This field is required.'),
    phoneNumber: NumberType().min(999999999,'Mobile Number must 10 digit long!').max(9999999990,'Mobile Number must 10 digit!').isRequired('This field is required.'),
    email: StringType().isEmail().pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?:com|in|org)$/).isRequired('This field is required.'),
});

function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

const AddNewHead = ({onFetch,loading}) => {
  const toaster = useToaster();
  const pushNotification = (type,header,message) => {
      toaster.push(<Message showIcon type={type} header={header}>{message}</Message>, {
        placement : 'topEnd',
        duration: 3000
      })
    }
  const formRef = useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    udiseCode: '',
    firstName: '',
    lastName: '',
    destination : '',
    phoneNumber : '',
    email : ''
  });
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const [value, setValue] = React.useState([]);

  const handleCheckAll = (value, checked) => setValue(checked ? data : []);
  const handleChange = value => setValue(value);

  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const reset = () => {
    setFormValue({
        udiseCode: '',
        firstName: '',
        lastName: '',
        destination : '',
        phoneNumber : '',
        divisionName : '',
        email : ''
    });
    setValue([]);
  }
  const handleAddHead= async () => {
    if (!formRef.current.check()) {
      console.error('Form Error',formError);
      return;
    } else {
      try {
        let response = await addAHead(formValue);
        if(response.data.status) {
        onFetch(response.data.data.udiseCode);
        pushNotification('success','Created',response.data.message)
          setOpen(false);
          reset();
        } else {
            pushNotification('warning','Note',response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    }

  }
  useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor){
        setFormValue({...formValue,udiseCode :instuctor.udiseCode ,divisionName : instuctor.schoolInfo[0].division_name})
    } else {

    }
    
  },[])
  return (
    <>
        <Button disabled={loading} color="green" size="md" appearance="primary" onClick={() => handleOpen('md')} endIcon={<AdminIcon />}>
        Add New Details
        </Button>
      <hr />
      <Modal backdrop={'static'} size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Add A New Administration Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  
          model={model}           
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}>
            <TextField name="firstName" label="First Name" />
            <TextField name="lastName" label="Last Name" />
            <TextField name="destination" label="Designation" />
            <p className='mb-2'>Mobile Number</p>
            <InputGroup style={{width: 300,marginBottom: 10}}>
              <InputGroup.Addon>+91</InputGroup.Addon>
              <TextField name="phoneNumber" type='text' />
            </InputGroup>
            <TextField name="email" label="Email" />
            <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button type="submit" appearance="primary" onClick={handleAddHead}>
            Ok
          </Button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default AddNewHead;