"use strict";

import React, { useState } from "react";
import { AgChartsReact } from "ag-charts-react";

export const PieChart = ({data,title}) => {
  const [options, setOptions] = useState({
    // width : 400,
    data: data,
    title: {
      text: title,
    },
    series: [
      {
        type: "pie",
        angleKey: "amount",
        calloutLabelKey: "asset",
        sectorLabelKey: "amount",
        sectorLabel: {
          color: "white",
          fontWeight: "bold",
          formatter: ({ value }) => `${(value).toFixed(0)}`,
        },
      },
    ],
  });

  return <AgChartsReact options={options} />;
};