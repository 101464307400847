import style from '../home/home.module.css';
import { StudentDataTable } from '../table/ReactTable';
import { logout } from '../../utils/utils';
import { Link, useNavigate } from 'react-router-dom';

export default function StudentData () {
    const navigate = useNavigate();
    return <div className={style.home_sec}>
    <div className="container">
        <div className={style.header}>
          <div className={style.image}>
            <img src='../../assets/images/MPSP_logo.png' alt='' className='img-fluid' onClick={()=>{
              navigate('/home')
            }} />
          </div>
          <div>
          <Link to='/instructor' className={`${style.btn} btn`} onClick={()=>logout('instructor')}>Logout</Link>
          </div>
        </div>
      </div>
    <div className={style.table_body}>
    <div className='container'><StudentDataTable /></div>
    </div>
  </div>
}