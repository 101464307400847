import React from 'react';
import { Modal, Button, Table, Badge } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;
const ViewAttendance = ({isOpen,handleClose,data}) => {
  return (
    <>
      <Modal size={"md"} open={isOpen} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
    <Table
     bordered cellBordered height={400} headerHeight={80} data={data}
     >
      <Column width={350} align="center" verticalAlign='center'>
        <HeaderCell>Date</HeaderCell>
        <Cell dataKey="date" />
      </Column>
      <Column width={400} align="center" verticalAlign='center'>
        <HeaderCell>Status</HeaderCell>
        <Cell>
            {rowData=>{
                if(rowData.status === "P"){
                   return  <Button size='xs' color='green' appearance="primary">{rowData.status}</Button>
                } else if(rowData.status === "NA") {
                    return  <Button size='xs' color='subtle' appearance="primary">{rowData.status}</Button>
                } else if(rowData.status === "WO") {
                    return  <Button size='xs' color='violet' appearance="primary">{rowData.status}</Button>
                } else if(rowData.status === "H") {
                    return  <Button size='xs' color='yellow' appearance="primary">{rowData.status}</Button>
                } else if(rowData.status === "L") {
                    return  <Button size='xs' color='blue' appearance="primary">{rowData.status}</Button>
                } else if(rowData.status === "A"){
                    return  <Button size='xs' color='red' appearance="primary">{rowData.status}</Button>
                }
                 else {
                    return `No Status`
                }
            }}
        </Cell>
      </Column>
    </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewAttendance;