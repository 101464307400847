import { Uploader, Button } from 'rsuite';
import url from "../../api/api";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Notifications
import { Message, useToaster } from 'rsuite';
import FileUploadIcon from '@rsuite/icons/FileUpload';
import FileDownload from '@rsuite/icons/FileDownload';
import axios from 'axios';

const UDISEFileUploader = ({loadingUsers}) => {
    const toaster = useToaster();
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const [fileList,setFileList] = useState([]);
    const [username,setUsername] = useState('');

    const imageFileTypes = [,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const maxFileSize = 1024 * 1024; // 1MB in bytes

    const pushNotification = (type,header,message) => {
        toaster.push(<Message showIcon type={type} header={header}>{message}</Message>, {
          placement : 'topEnd',
          duration: 3000
        })
      }
      const handleUpload = async (files) => {
        try {
          if (files.length) {
            if (!imageFileTypes.includes(files[0]?.blobFile?.type)) {
              pushNotification(
                "error",
                "Note",
                "Invalid file type. Please upload only xlsx.",
              );
              return false;
            }
      
            if (files[0]?.blobFil?.size > maxFileSize) {
              pushNotification(
                "error",
                "Note",
                "File size exceeds 5MB limit. Please choose a smaller file.",
              );
              return false;
            }
            // Manually trigger the upload
      
            const formData = new FormData();
      
            // Append each file to the FormData object
            files.forEach((file) => {
              formData.append("file", file.blobFile, file.name);
            });
      
            // Add any additional data to the FormData object if needed
            // formData.append('key', 'value');
      
            // Send FormData to your API endpoint
            setLoading(true);
            const adminToken = localStorage.getItem("adminPermissionToken");
            await axios
              .post(
                `${url}/admin/udise/file/upload`,
                formData,
                {
                  headers: {
                     Authorization: `Bearer ${adminToken}`
                  }
               }
              )
              .then((data) => {
                if(data.data.status) {
                  pushNotification("success", "Success", data.data.message);
                  setLoading(false);
                } else {
                  pushNotification("warning", "Note", data.data.message);
                  setLoading(false);
                }

              })
              .catch((error) => {
                // Handle errors
                pushNotification("error", "Note", "File Upload Failed");
                setLoading(false);
              });
          }
        } catch (error) {
          console.log(error);
        }
      };
    useEffect(()=>{
    let userInfo = localStorage.getItem("adminPermissionToken");
    if(userInfo) {

    } else {
        navigate("/");
    }
  })
  return <>
  <Uploader
    fileList={[]}
    listType="picture-text"
    accept=".xlsx"
    loading={loading}
    autoUpload={false}
    onChange={handleUpload}
    disabled={loadingUsers}
  >
    <Button loading={loading} disabled={loadingUsers} color='orange' appearance="primary" startIcon={<FileUploadIcon />}>Bulk Upload</Button>
  </Uploader>
  <Button loading={loading} disabled={loadingUsers} color='blue' appearance="link" onClick={()=>{
    window.open('https://api.ict-trainingmaharashtra.co.in/uploads/1713952910837.xlsx')
  }} startIcon={<FileDownload />}>Bulk Upload Format</Button>
  </>
};

export default UDISEFileUploader;