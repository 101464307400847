import axios from "axios"
import url from "../api/api"

// User
const userLogin = async (json) => {
   try {
      let URL = `${url}/udise/verify`
      return await axios.post(URL,json)
   } catch (error) {
      console.log(error)
   }

}

const instuctorLogin = async (json) => {
   const udiseToken = localStorage.getItem("accessToken");
   let URL = `${url}/instuctor/auth`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${udiseToken}`
      }
   })
}

const getStudentsByUdiseCode = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/student?udiseCode=${json.udiseCode}&class=${json.class}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
const addNewStudent = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/student/add`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
const removeAStudent = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/student/remove`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
const updateStudentsByUdiseCode = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/student/update`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
const getFilesByUsername = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/instuctor/files?username=${json}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const updateFilesByUsername = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/instuctor/files?username=${json.username}`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
// form
const getOrAddUDISEForm = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/form/get`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
const updateUDISEForm = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/form/update?id=${json._id}`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
const getInstuctorAttendance = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/instuctor/attendance?username=${json}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const instuctorPunchIn = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/instuctor/attendance/punchIn`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const instuctorPunchOut = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/instuctor/attendance/punchOut`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

// sessions plan
const getOrAddSessionPlans = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/instuctor/sessions?grade=${json.grade}&udiseCode=${json.udiseCode}&division=${json.division}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const updateSessionPlans = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/instuctor/sessions`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}


// Admin
const adminAuth = async (json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/auth`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const adminAdd = async (json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/add`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}

const adminGet = async (page,limit) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin?page=${page}&size=${limit}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const adminUpdateStatus = async (json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/status`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const userUpdateStatus = async (json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/user/status`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}

const getAllInstuctor = async (json,page,size) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/instuctor?sortBy=${json}&page=${page}&size=${size}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getAllOldInstuctor = async (json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/old/instuctor?sortBy=${json}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getAllHeadDetails = async (json,page,size) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/brc/head?sortBy=${json}&page=${page}&size=${size}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getAllActivities = async (json,page,size) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/brc/activity?sortBy=${json}&page=${page}&size=${size}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getAllActivitiesMonthly= async (json,page,size) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/brc/monthly?sortBy=${json}&page=${page}&size=${size}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getAllForm = async (json,page,limit) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/school?sortBy=${json}&page=${page}&size=${limit}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getAllSessionsPlan = async (json,page,limit) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/school/sessions?sortBy=${json}&page=${page}&size=${limit}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}

const getAllSchoolProgress = async (json,search,page,limit) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/school/progress?sortBy=${json}&search=${search}&page=${page}&size=${limit}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getAllUploadedContent = async (json,page,limit) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/instuctor/content?sortBy=${json}&page=${page}&size=${limit}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getChartData = async (json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/chart/data?sortBy=${json}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const getAllBRCForm = async (json,page,limit) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/brc/form?sortBy=${json}&page=${page}&size=${limit}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}

const getAllUsers = async (sortBy,search,page,limit) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/instuctor/users?sortBy=${sortBy}&search=${search}&page=${page}&size=${limit}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const updateUserPassword = async(json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/instuctor/users`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}
const addUDISECode = async(json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/udise/add`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}

const updateUDISECode = async(json) => {
   const adminToken = localStorage.getItem("adminPermissionToken");
   let URL = `${url}/admin/school`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${adminToken}`
      }
   })
}

//  BRC 

const getAllActivityLogs = async (username) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/activity/logs?username=${username}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
const getAllActivityMonthlyLogs = async (username) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/activity/monthly/logs?username=${username}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const addActivityLog = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/activity/add`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const updateActivityLog = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/activity/update`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const removeActivityLog = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/activity/remove?id=${json}`
   return await axios.delete(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
// BRC Head
const getAllHeads = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/head?udiseCode=${json}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const addAHead = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/head/add`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const updateHead = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/head/update`
   return await axios.patch(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const removeHead = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/head/remove?id=${json}`
   return await axios.delete(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

// BRC Form

const getAllAssets = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/form/asset?udiseCode=${json}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const getAllSchoolList = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/form/school?udiseCode=${json}`
   return await axios.get(URL,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const addAAsset = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/form/asset`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const addASchool = async (json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/brc/form/school`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}

const deleteAFile = async(json) => {
   const instuctorToken = localStorage.getItem("permissionToken");
   let URL = `${url}/instuctor/file/delete`
   return await axios.post(URL,json,{
      headers: {
         Authorization: `Bearer ${instuctorToken}`
      }
   })
}
export {
  userLogin,
  instuctorLogin,
  getStudentsByUdiseCode,
  updateStudentsByUdiseCode,
  addNewStudent,
  removeAStudent,
  getOrAddUDISEForm,
  updateUDISEForm,
  getInstuctorAttendance,
  instuctorPunchIn,
  instuctorPunchOut,
  adminAuth,
  adminAdd,
  adminGet,
  adminUpdateStatus,
  getAllInstuctor,
  getAllForm,
  getAllSchoolProgress,
  getAllUploadedContent,
  getChartData,
  getAllActivityLogs,
  getAllHeadDetails,
  getAllActivities,
  addActivityLog,
  updateActivityLog,
  removeActivityLog,
  getAllHeads,
  addAHead,
  updateHead,
  removeHead,
  getFilesByUsername,
  updateFilesByUsername,
  getAllOldInstuctor,
  updateSessionPlans,
  getOrAddSessionPlans,
  getAllSessionsPlan,
  getAllAssets,
  getAllSchoolList,
  addAAsset,
  addASchool,
  getAllBRCForm,
  getAllUsers,
  updateUserPassword,
  addUDISECode,
  userUpdateStatus,
  updateUDISECode,
  getAllActivitiesMonthly,
  deleteAFile,
  getAllActivityMonthlyLogs
}