"use strict";

import React, { useState } from "react";
import { AgChartsReact } from "ag-charts-react";

export const LineChart = ({data,title,xKey,yKey,yName,fill}) => {
  const [options, setOptions] = useState({
    // width : 600,
    title: {
      text: title,
    },
    data: data,
    series: [
      {
        type: "line",
        xKey: xKey,
        yKey: yKey,
        yName: yName,
        stroke : fill,
        marker: {
            fill: fill,
            // size: 10,
            stroke: fill,
            strokeWidth: 3,
            // shape: "diamond",
          },
      },
    ],
    navigator: {
        enabled: true,
      },
  });

  return <AgChartsReact options={options} />;
};

