import React, { useEffect, useState, useRef, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import style from '../home/home.module.css';
import { getCurrentYear } from '../../utils/utils';
import { getOrAddUDISEForm, updateUDISEForm } from '../../services/services';
import { debounce } from 'lodash';
import { useToaster, Message } from 'rsuite';
export default function FormPage() {
  const navigate = useNavigate();
  const toaster = useToaster();
  const [formValues, setFormValues] = useState({
    _id: '',
    udiseCode: '',
    schoolName: '',
    nameOfTheDistrict: '',
    nameOfTheUDISEBlock: '',
    schoolLocationType: '',
    schoolAddress: '',
    pinCode: '',
    nameOfTheClusterResourceCentre: '',
    schoolContactDetails: {
      stdCode: '',
      landlineNumber: '',
      mobileNumber: '',
      email: '',
      websiteOfTheSchool: '',
    },
    headOfTheSchoolInchargeDetails: {
      inchargeType: '',
      inchargeName: '',
      mobileNumber: '',
      hosEmail: '',
    },
    schoolCategoryCode: '',
    lowestClassInSchool: '',
    highestClassInSchool: '',
    typeOfTheSchool: '',
    affiliationBoardOfTheSchoolForSecondarySections: '',
    affiliationBoardOfTheSchoolForHigherScondarySections: '',
    respondentDetails: {
      respondentType: '',
      respondentName: '',
      mobileNumber: '',
      respondentEmail: '',
    },
    academicSessionStartDate: '',
    academicSessionStartEnd: '',
    mediumOfInstructionsInTheSchool: {
      medium1: '',
      medium2: '',
      medium3: '',
      medium4: '',
    },
    isThisASpecialSchoolForCWSN: {
      isCWSN: '',
      typeOfSpecialSchool: '',
    },
    motherTongue: '',
    teachersAttendanceCaptured: '',
    inventoryRegisterOnICTItems: '',
    numberOfIncludingComputerLabs: '',
    equipmentFacility: '',
    itemsDesktopTotal: '',
    itemsDesktopOutOfTotal: '',
    itemsLaptopTotal: '',
    itemsLaptopOutOfTotal: '',
    itemsTabletsTotal: '',
    itemsTabletsOutOfTotal: '',
    itemsPCWithLearningDevicesTotal: '',
    itemsPCWithLearningDevicesOutOfTotal: '',
    itemsCMSTotal: '',
    itemsCMSOutOfTotal: '',
    itemsProjectorTotal: '',
    itemsProjectorOutOfTotal: '',
    itemsPrinterTotal: '',
    itemsPrinterOutOfTotal: '',
    itemsScannerTotal: '',
    itemsScannerOutOfTotal: '',
    itemsServerTotal: '',
    itemsServerOutOfTotal: '',
    itemsWebCameraTotal: '',
    itemsWebCameraOutOfTotal: '',
    itemsSmartTVTotal: '',
    itemsSmartTVOutOfTotal: '',
    itemsSmartClassroomTotal: '',
    itemsSmartClassroomOutOfTotal: '',
    itemsMobileTotal: '',
    itemsMobileOutOfTotal: '',
    itemsPowerBackupTotal: '',
    itemsPowerBackupOutOfTotal: '',
    internetFacility: '',
    typeOfInternetFacilityInTheSchool: '',
    ictLabAvailableInSchool: '',
    numberOfICTLabsAvailable: '',
    yearOfImplementation: '',
    whetherTheICTLabIsFunctionalOrNot: '',
    typeOfTheICTInstructorInTheSchool: '',
    facilityICTToolsForTeaching: '',
    facilityAccessToEContent: '',
    facilityTechBasedCWSN: '',
    facilityAccessToDTH: '',
    doesTheSchoolHaveDigitalLibrary: '',
    numberOfEBooksContentsavailable: '',
    trainedForTeachingCWSN: '',
    trainedInComputer: '',
    trainingReceived: '',
    trainingNeeds: '',
    trainingWorkingDays: '',
    trainingLanguagesKnowlegde: '',
    trainingLanguage1: '',
    trainingLanguage2: '',
    trainingLanguage3: '',
    trainingChildSafety: '',
    trainingReceivedOnCyberSafety: '',
    trainingReceivedIdentificationSupportCWSN: '',
    trainingRemoteClasses: '',
    trainingCapableHandlingDigitalDevices: '',
    status: 'In-Progress',
  });
  const handleForm = async (event) => {
    const { name, value } = event.target;
  // Remove the input-error class if it exists
  const inputRef = inputRefs.find(ref => ref.current.name === name);
  if (inputRef && inputRef.current.classList.contains('input-error')) {
    inputRef.current.classList.remove('input-error');
  }
    // If the property is nested, you need to create a copy of the nested object
    // and update the specific property within that nested object
    if (name.includes('.')) {
      const [parent, nested] = name.split('.');
      setFormValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [nested]: value,
        },
      }));
      updateUDISEFormById({
        ...formValues,
        [parent]: {
          ...formValues[parent],
          [nested]: value,
        },
      });
    } else {
      // If the property is not nested, update it directly
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      formValues.status = 'In-Progress';
      updateUDISEFormById({ ...formValues, [name]: value });
    }
  };
  const inputRefs = Array.from({ length: 97 }, () => createRef(null));
  const [currentFocusIndex, setCurrentFocusIndex] = useState(0);

  const handleNextButtonClick = () => {
    const nextIndex = (currentFocusIndex + 1) % inputRefs.length;
    inputRefs[nextIndex].current.focus();
    setCurrentFocusIndex(nextIndex);
  };

  const handlePrevButtonClick = () => {
    const prevIndex =
      currentFocusIndex === 0 ? inputRefs.length - 1 : currentFocusIndex - 1;
    inputRefs[prevIndex].current.focus();
    setCurrentFocusIndex(prevIndex);
  };
  const fetchUDISEForm = async (udiseCode) => {
    try {
      formValues.udiseCode = udiseCode;
      const response = await getOrAddUDISEForm(formValues);
      if (response.data.status) {
        setFormValues(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
// Inside the handleSubmit function
// Function to recursively flatten an object
const flattenObject = (obj, parentKey = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      return { ...acc, ...flattenObject(obj[key], prefixedKey) };
    }
    return { ...acc, [prefixedKey]: obj[key] };
  }, {});
};

// Get all keys including nested keys
const allKeys = Object.keys(flattenObject(formValues));

// Filter out non-mandatory keys (e.g., _id, status)
const mandatoryFields = allKeys.filter(key => key !== '_id' && key !== 'status' && key !== '__v');

// Now you can use the mandatoryFields array in your handleSubmit function
const handleSubmit = async () => {
  const udiseCode = JSON.parse(localStorage.getItem('instuctorInfo'));
  formValues.udiseCode = udiseCode.udiseCode;
  formValues.status = 'Completed';

  // Check if any mandatory fields are empty
  const emptyFields = mandatoryFields.filter(field => {
    // Handle nested fields
    const keys = field.split('.');
    if (keys.length === 1) {
      return !formValues[field];
    } else {
      return !keys.reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : null), formValues);
    }
  });

  // If any mandatory fields are empty, highlight them red
  if (emptyFields.length > 0) {
    emptyFields.forEach(field => {
      const inputRef = inputRefs.find(ref => ref.current.name === field);
      if (inputRef) {
        inputRef.current.classList.add('input-error'); // Add a CSS class to highlight red
      }
    });
    toaster.push(
      <Message showIcon type="error" header="Error">
        <strong>Please fill in all mandatory fields.</strong>
      </Message>,
      {
        placement: 'topEnd',
        duration: 3000,
      }
    );
    return; // Exit the function early if any mandatory fields are empty
  }

  const response = await updateUDISEForm(formValues);
  if (response.data.data.status === 'Completed') {
    toaster.push(
      <Message showIcon type="success" header="Success">
        <strong>Your UDISE+ Form Submitted.</strong>
      </Message>,
      {
        placement: 'topEnd',
        duration: 3000,
      }
    );
  }
};
  const updateUDISEFormById = debounce(async (payload) => {
    try {
      const response = await updateUDISEForm(payload);
      if (response.data.status) {
        // setFormValues(response.data.data)
      }
    } catch (error) {
      console.log(error);
    }
  }, 500);
  useEffect(() => {
    const udiseCode = JSON.parse(localStorage.getItem('instuctorInfo'));
    if (udiseCode) {
      fetchUDISEForm(udiseCode.udiseCode);
    }
  }, []);

  return (
    <div className="container2 bg-white min-vh-100 pt-4 pb-5 position-relative">
      <div className="static-buttons">
        <a className={`${style.btn} btn`} onClick={handlePrevButtonClick}>
          Previous
        </a>
        <a className={`${style.btn} btn`} onClick={handleNextButtonClick}>
          Next
        </a>
        <a className={`${style.btn} btn`} onClick={handleSubmit}>
          Submit
        </a>
      </div>
      <p className="text-center pt-5 fs_26 fw_500 black">
        Unified District Information System for Education Plus
      </p>
      <p className="text-center fs_26 fw_500 black">(UDISE+) </p>
      <p className="text-center pt-5 fs_26 fw_600 black">Data Capture Format</p>
      <p className="fs_20 fw_500 black text-center">for</p>
      <p className="fs_22 fw_600 black text-center mt-3">
        Academic Year {getCurrentYear()}
      </p>
      <p className="fs_22 fw_600 black text-center mt-3">
        For Schools of Grade Pre-Primary to Class-12
      </p>
      <p className="fs_22 fw_400 black text-center mt-3">
        Department of School Education and Literacy <br />
        Ministry of Education
      </p>
      <p className="fs_22 fw_600 black text-center">Government of India</p>
      <div className="border_black mt-5">
        <div className="d-flex align-items-center bg_light_gray">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Sections
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">Details</p>
        </div>
        <p className="fs_17 fw_500 black border_top_black p-2">
          SCHOOL PROFILE AND OTHER DETAILS
        </p>
        <div className="d-flex align-items-center border_top_black">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Section 1A
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">School Profile Details</p>
        </div>
        <div className="d-flex align-items-center border_top_black">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Section 1B
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">
            School Safety and Other Indicators
          </p>
        </div>
        <div className="d-flex align-items-center border_top_black">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Section 1C
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">
            Receipts and Expenditure
          </p>
        </div>
        <div className="d-flex align-items-center border_top_black">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Section 1D
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">
            Vocational Education Details
          </p>
        </div>
        <p className="fs_17 fw_500 black border_top_black p-2">
          SCHOOL FACILITY
        </p>
        <div className="d-flex align-items-center border_top_black">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Section 2
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">
            Physical Facilities, Equipment, Computer and Digital initiative
          </p>
        </div>
        <p className="fs_17 fw_500 black border_top_black p-2">
          TEACHING AND NON-TEACHING STAFF DETAILS
        </p>
        <div className="d-flex align-items-center border_top_black">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Section 3
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">
            Teaching and Non-Teaching Staff Details
          </p>
        </div>
        <p className="fs_17 fw_500 black border_top_black p-2">
          STUDENT DETAILS
        </p>
        <div className="d-flex align-items-center border_top_black">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Section 4
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">Student Details</p>
        </div>
        <div className="d-flex align-items-center border_top_black">
          <p className="w_150 border_right_gray p-2 fs_16 fw_400 black">
            Section 5
          </p>
          <p className="w-100 p-2 fs_16 fw_400 black">
            Enrolment in other type of Schools
          </p>
        </div>
      </div>
      <p className="fs_16 fw_400 black mt-5">
        NOTE: All fields are mandatory (if not specifically mentioned Optional).
        Hence mandatory fields should not be left blank.
      </p>
      <p
        style={{ textDecorationThickness: 5 }}
        className="fs_17 fw_600 black mt-5 black_underline"
      >
        Section 1A-School Profile
      </p>
      <div className="d-flex align-items-center justify-content-between mt-4 flex-wrap">
        <p className="fs_16 fw_500 bg_green">UDISE Code:</p>
        <div className="d-inline-block">
          <div className="d-flex align-items-center border_black me-5">
            {formValues.udiseCode?.split('').map((item, index) => {
              return (
                <input
                  ref={inputRefs[0]}
                  className={
                    index + 1 === formValues.udiseCode?.length
                      ? 'w_h_30px text-center'
                      : 'border_right_black w_h_30px text-center ms-0'
                  }
                  maxLength={1}
                  type="text"
                  value={item}
                  readOnly
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <p className="fs_16 fw_400 black bg_green">
          <span className="fw_600">1.1</span> School Name (In capital letters) :
        </p>
        <input
          name="schoolName"
          value={formValues.schoolName}
          onChange={handleForm}
          ref={inputRefs[1]}
          className="w-75 border_black bg-white px-2 py-1"
          type="text"
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <p className="fs_16 fw_400 black bg_green">
          <span className="fw_600">1.2</span> Name of the District :{' '}
        </p>
        <input
          name="nameOfTheDistrict"
          value={formValues.nameOfTheDistrict}
          onChange={handleForm}
          ref={inputRefs[2]}
          className="w_500 border_black bg-white px-2 py-1"
          type="text"
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <p className="fs_16 fw_400 black bg_green">
          <span className="fw_600">1.3</span> Name of the UDISE+ Block:
        </p>
        <input
          name="nameOfTheUDISEBlock"
          value={formValues.nameOfTheUDISEBlock}
          onChange={handleForm}
          ref={inputRefs[3]}
          className="w_500 border_black bg-white px-2 py-1"
          type="text"
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <p className="fs_16 fw_400 black bg_green">
          <span className="fw_600">1.4</span> School Location Type :{' '}
          <span className="fw_500">(1-RURAL, 2-URBAN)</span>
        </p>
        <input
          name="schoolLocationType"
          value={formValues.schoolLocationType}
          onChange={handleForm}
          ref={inputRefs[4]}
          className="w_500 border_black bg-white px-2 py-1"
          type="text"
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <p className="fs_16 fw_400 black white_space_no_wrap me-4 bg_green">
          <span className="fw_600">1.5</span> School Address:
        </p>
        <input
          name="schoolAddress"
          value={formValues.schoolAddress}
          onChange={handleForm}
          ref={inputRefs[5]}
          className="w-100 border_black bg-white px-2 py-1"
          type="text"
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <p className="fs_16 fw_400 black bg_green">
          <span className="fw_600">1.6</span> Pin Code :
        </p>
        <input
          name="pinCode"
          value={formValues.pinCode}
          onChange={handleForm}
          ref={inputRefs[6]}
          className="w_500 border_black bg-white px-2 py-1"
          type="number"
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <p className="fs_16 fw_400 black bg_green">
          <span className="fw_600">1.7</span> Name of the Cluster Resource
          Centre(CRC):
        </p>
        <input
          name="nameOfTheClusterResourceCentre"
          value={formValues.nameOfTheClusterResourceCentre}
          onChange={handleForm}
          ref={inputRefs[7]}
          className="w_500 border_black bg-white px-2 py-1"
          type="text"
        />
      </div>
      <p className="fs_16 fw_400 black mt-2 bg_green">
        <span className="fw_600">1.10</span> School Contact Details :
      </p>
      <div className="d-flex mt-3 ms-0 ms-md-4 row">
        <div className="w-100 col-12 col-md-6">
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="fs_16 fw_400 black white_space_no_wrap me-4 bg_green">
              (a) STD Code:
            </p>
            <input
              name="schoolContactDetails.stdCode"
              value={formValues.schoolContactDetails?.stdCode}
              onChange={handleForm}
              ref={inputRefs[8]}
              className="w-75 bg-white px-2 py-1 border_black"
              type="number"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 mt-2">
            <p className="fs_16 fw_400 black white_space_no_wrap me-4 bg_green">
              (c) Mobile Number:
            </p>
            <input
              name="schoolContactDetails.mobileNumber"
              value={formValues.schoolContactDetails?.mobileNumber}
              onChange={handleForm}
              ref={inputRefs[9]}
              className="w-75 bg-white px-2 py-1 border_black"
              type="number"
            />
          </div>
        </div>
        <div className="w-100 ms-0 ms-md-0 col-12 col-md-6 mt-2 mt-md-0">
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="fs_16 fw_400 black white_space_no_wrap me-4 bg_green">
              (b) Landline Number:
            </p>
            <input
              name="schoolContactDetails.landlineNumber"
              value={formValues.schoolContactDetails?.landlineNumber}
              onChange={handleForm}
              ref={inputRefs[10]}
              className="w-75 bg-white px-2 py-1 border_black"
              type="number"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 mt-2">
            <p className="fs_16 fw_400 black white_space_no_wrap me-4 bg_green">
              (d) Email:
            </p>
            <input
              name="schoolContactDetails.email"
              value={formValues.schoolContactDetails?.email}
              onChange={handleForm}
              ref={inputRefs[11]}
              className="w-75 bg-white px-2 py-1 border_black"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center w-100 mt-2 ms-0 ms-md-4">
        <p className="fs_16 fw_400 black white_space_no_wrap bg_green">
          (e) Website of the School:
        </p>
        <input
          name="schoolContactDetails.websiteOfTheSchool"
          value={formValues.schoolContactDetails?.websiteOfTheSchool}
          onChange={handleForm}
          ref={inputRefs[12]}
          className="w-100 bg-white px-2 py-1 border_black mx-4"
          type="text"
        />
      </div>
      <p className="fs_16 fw_400 black mt-2 bg_green">
        <span className="fw_600">1.11</span> Head of the School (HoS) /
        In-Charge Details :
      </p>
      <div className="ms-4">
        <p className="fs_16 fw_400 black mt-2 bg_green">
          (a) HoS / In-Charge Type:{' '}
          <span>
            <input
              name="headOfTheSchoolInchargeDetails.inchargeType"
              value={formValues.headOfTheSchoolInchargeDetails?.inchargeType}
              onChange={handleForm}
              ref={inputRefs[13]}
              className="border_black px-2 py-1 mx-2"
              type="text"
            />
          </span>{' '}
          <span className="fw_600 bg_green">
            (1- Head Master/Principal, 2- Asst. Head Master/Vice Principal,
            3-Acting Head Teacher, 4-In-Charge from Other School, 5-In-Charge
            from Block/District, 6-Others)
          </span>
        </p>
        <div className="d-flex align-items-center mt-2">
          <div>
            <p className="fs_16 fw_400 black bg_green">
              (b) HoS /In-Charge Name:
            </p>
            <p className="fs_16 fw_400 black py-3 my-1 bg_green">
              (c) Mobile Number:
            </p>
            <p className="fs_16 fw_400 black bg_green">(d) HoS Email:</p>
          </div>
          <div className="d-flex flex-column ms-5">
            <input
              name="headOfTheSchoolInchargeDetails.inchargeName"
              value={formValues.headOfTheSchoolInchargeDetails?.inchargeName}
              onChange={handleForm}
              ref={inputRefs[14]}
              className="w_500 border_black bg-white px-2 py-1 mb-1"
              type="text"
            />
            <input
              name="headOfTheSchoolInchargeDetails.mobileNumber"
              value={formValues.headOfTheSchoolInchargeDetails?.mobileNumber}
              onChange={handleForm}
              ref={inputRefs[15]}
              className="w_500 border_black bg-white px-2 py-1 my-1"
              type="number"
            />
            <input
              name="headOfTheSchoolInchargeDetails.hosEmail"
              value={formValues.headOfTheSchoolInchargeDetails?.hosEmail}
              onChange={handleForm}
              ref={inputRefs[16]}
              className="w_500 border_black bg-white px-2 py-1 mt-1"
              type="text"
            />
          </div>
        </div>
      </div>
      <p className="fs_16 fw_400 black mt-2">
        <span className="fw_600 bg_green">
          1.13 <span> School Category (code): </span>
        </span>
        <input
          name="schoolCategoryCode"
          value={formValues.schoolCategoryCode}
          onChange={handleForm}
          ref={inputRefs[17]}
          className="w-25 border_black ms-4"
          type="number"
        />
      </p>

      <table className="w-100 mt-4">
        <tbody>
          <tr className="bg_light_gray">
            <th className="fs_16 fw_600 black text-center">
              Details of Category
            </th>
            <th className="fs_16 fw_600 black text-center">Code</th>
            <th className="fs_16 fw_600 black text-center">Broad Category</th>
          </tr>
          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Primary only with grades 1 to 5{' '}
              <span className="fw_600">(PRY)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">1</td>
            <td className="fs_16 fw_400 black text-center">Primary School</td>
          </tr>
          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Upper Primary with grades 1 to 8{' '}
              <span className="fw_600">(PRY-UPR)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">2</td>
            <td className="fs_16 fw_400 black text-center">
              Upper primary School
            </td>
          </tr>
          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Higher Secondary with grades 1 to 12{' '}
              <span className="fw_600">(PRY-UPR-SEC-HSEC)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">3</td>
            <td className="fs_16 fw_400 black text-center">
              Higher Secondary School
            </td>
          </tr>
          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Upper Primary only with grades 6 to 8{' '}
              <span className="fw_600">(UPR)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">4</td>
            <td className="fs_16 fw_400 black text-center">
              Upper primary School
            </td>
          </tr>
          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Higher Secondary with grades 6 to 12{' '}
              <span className="fw_600">(UPR-SEC-HSEC)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">5</td>
            <td className="fs_16 fw_400 black text-center">
              Higher Secondary School
            </td>
          </tr>

          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Secondary with grades 6 to 10{' '}
              <span className="fw_600">(UPR-SEC)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">7</td>
            <td className="fs_16 fw_400 black text-center">Secondary School</td>
          </tr>
          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Secondary only with grades 9 &amp; 10{' '}
              <span className="fw_600">(SEC)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">8</td>
            <td className="fs_16 fw_400 black text-center">Secondary School</td>
          </tr>
          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Higher Secondary with grades 9 to 12{' '}
              <span className="fw_600">(SEC-HSEC)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">9</td>
            <td className="fs_16 fw_400 black text-center">
              Higher Secondary School
            </td>
          </tr>
          <tr>
            <td className="fs_16 fw_400 black bg_green">
              Hr. Sec. /Jr. College only with grades 11 &amp; 12{' '}
              <span className="fw_600">(HSEC)</span>
            </td>
            <td className="fs_16 fw_400 black text-center">10</td>
            <td className="fs_16 fw_400 black text-center">
              Higher Secondary School
            </td>
          </tr>
        </tbody>
      </table>

      <p className="fs_16 fw_400 black mt-3 ">
        <span className="fw_600  bg_green">1.14</span>{' '}
        <span className="bg_green">
          {' '}
          Classes and Streams available in School :
        </span>
      </p>
      <div className="ms-4">
        <p className="fs_16 fw_400 black mt-2">
          <span className="bg_green">
            (a) Lowest and Highest Class in School{' '}
          </span>
          <span className="fw_600 bg_green">(e.g. 1 to 5) :</span>{' '}
          <span>
            <input
              name="lowestClassInSchool"
              onChange={handleForm}
              value={formValues.lowestClassInSchool}
              ref={inputRefs[18]}
              className="border_black"
              type="number"
            />
          </span>{' '}
          <span className="mx-3 bg_green">TO</span>
          <span>
            <input
              name="highestClassInSchool"
              onChange={handleForm}
              value={formValues.highestClassInSchool}
              ref={inputRefs[19]}
              className="border_black"
              type="number"
            />
          </span>
        </p>
      </div>

      <p className="fs_16 fw_400 black mt-3">
        <span className="bg_green">
          <span className="fw_600">1.15</span> Type of the School:
        </span>
        <span>
          <input
            name="typeOfTheSchool"
            onChange={handleForm}
            value={formValues.typeOfTheSchool}
            ref={inputRefs[20]}
            className="border_black mx-3"
            type="text"
          />
        </span>
        <span className="fw_600 bg_green">
          (1-Boys, 2-Girls, 3-Co-Educational)
        </span>
      </p>
      <p className="fs_16 fw_400 black mt-3">
        <span className="bg_green">
          <span className="fw_600 ">1.16</span> Affiliation Board of the school
          for <span className="fw_600">SECONDARY</span> Sections:
        </span>
        <span>
          <input
            name="affiliationBoardOfTheSchoolForSecondarySections"
            onChange={handleForm}
            value={formValues.affiliationBoardOfTheSchoolForSecondarySections}
            ref={inputRefs[21]}
            className="border_black mx-3"
            type="text"
          />
        </span>
      </p>
      <p className="fs_16 fw_600 black mt-2 bg_green">
        (1-CBSE, 2-State Board, 3-ICSE, 4-International Board, 6-Both CBSE and
        State Board, 7-Madarsa Board, 8-Sanskrit Board)
      </p>
      <p className="fs_16 fw_400 black mt-2">
        <span className="bg_green">
          <span className="fw_600">1.17</span> Affiliation Board of the school
          for <span className="fw_600">HIGHER SECONDARY</span> Sections:{' '}
        </span>
        <span>
          <input
            name="affiliationBoardOfTheSchoolForHigherScondarySections"
            onChange={handleForm}
            value={
              formValues.affiliationBoardOfTheSchoolForHigherScondarySections
            }
            ref={inputRefs[22]}
            className="border_black ms-3"
            type="text"
          />
        </span>
      </p>
      <p className="fs_16 fw_600 black mt-2 bg_green">
        (1-CBSE, 2-State Board, 3-ICSE, 4-International Board, 6-Both CBSE and
        State Board, 7-Madarsa Board, 8-Sanskrit Board)
      </p>
      <p className="fs_16 fw_400 black mt-2 bg_green">
        <span className="fw_600">1.18 </span> Respondent Details :(*Respondent =
        Person actually Responsible for filling this form)
      </p>
      <div className="ms-4">
        <p className="fs_16 fw_400 black mt-2">
          <span className="bg_green">(a) Respondent Type: </span>
          <span>
            <input
              name="respondentDetails.respondentType"
              onChange={handleForm}
              value={formValues.respondentDetails?.respondentType}
              ref={inputRefs[23]}
              className="border_black ms-3"
              type="text"
            />
          </span>
        </p>
        <p className="fs_16 fw_600 black mt-1 bg_green">
          (1- Head of the School/In-Charge, 2-Teacher, 3-School Administration
          Staff/Official/Clerk, 4-InCharge from Block/District/Cluster,
          5-In-Charge from Other School)
        </p>
        <p className="fs_16 fw_400 black mt-2">
          <span className="bg_green">(b) Respondent Name:</span>
          <span>
            <input
              name="respondentDetails.respondentName"
              onChange={handleForm}
              value={formValues.respondentDetails?.respondentName}
              ref={inputRefs[24]}
              className="border_black ms-3"
              type="text"
            />
          </span>
        </p>
        <p className="fs_16 fw_400 black mt-2">
          <span className="bg_green">(c) Mobile Number </span>
          <span>
            <input
              name="respondentDetails.mobileNumber"
              onChange={handleForm}
              value={formValues.respondentDetails?.mobileNumber}
              ref={inputRefs[25]}
              className="border_black ms-3"
              type="number"
            />
          </span>
        </p>
        <p className="fs_16 fw_400 black mt-2">
          <span className="bg_green">(d) Respondent Email: </span>
          <span>
            <input
              name="respondentDetails.respondentEmail"
              onChange={handleForm}
              value={formValues.respondentDetails?.respondentEmail}
              ref={inputRefs[26]}
              className="border_black ms-3"
              type="text"
            />
          </span>
        </p>
      </div>
      <p className="fs_16 fw_400 black mt-1">
        <span className="bg_green">
          <span className="fw_600">1.19</span> When does the academic session
          Start and End Date?{' '}
        </span>
        <span>
          <input
            name="academicSessionStartDate"
            onChange={handleForm}
            value={formValues.academicSessionStartDate}
            ref={inputRefs[27]}
            className="border_black mx-2"
            type="number"
          />
        </span>{' '}
        <span lassName="bg_green">TO </span>
        <span>
          <input
            name="academicSessionStartEnd"
            onChange={handleForm}
            value={formValues.academicSessionStartEnd}
            ref={inputRefs[28]}
            className="border_black mx-2"
            type="number"
          />
        </span>
      </p>
      <p className="fs_16 fw_600 black ms-4">
        (e.g. 5th July to be mentioned as 05/07 )
      </p>
      <p className="mt-2 bg_green">
        <span className="fw_600">1.23</span> Medium of instruction(s) in the
        School:{' '}
        <span className="fw_600">
          [Mention Mediums (if more than one) according to Number of Students
          taught in any particular Medium in descending order. Largest Medium
          should come first.]
        </span>
      </p>
      <div className="d-flex align-items-center justify-content-between ms-4 mt-2 row ">
        <div className="d-flex align-items-center col-12 col-md-6">
          <div>
            <p className="white_space_no_wrap bg_green">(i) Medium-1:</p>
            <p className="mt-2 white_space_no_wrap bg_green">(i) Medium-3:</p>
          </div>
          <div className="d-flex flex-column align-items-start ms-3 w-100">
            <input
              name="mediumOfInstructionsInTheSchool.medium1"
              onChange={handleForm}
              value={formValues.mediumOfInstructionsInTheSchool?.medium1}
              ref={inputRefs[29]}
              className="border_black w-100"
              type="text"
            />
            <input
              name="mediumOfInstructionsInTheSchool.medium3"
              onChange={handleForm}
              value={formValues.mediumOfInstructionsInTheSchool?.medium3}
              ref={inputRefs[30]}
              className="border_black mt-2 w-100"
              type="text"
            />
          </div>
        </div>
        <div className="d-flex align-items-center col-12 col-md-6">
          <div>
            <p className="white_space_no_wrap bg_green">(i) Medium-2:</p>
            <p className="mt-2 white_space_no_wrap bg_green">(i) Medium-4:</p>
          </div>
          <div className="d-flex flex-column align-items-start ms-3 w-100">
            <input
              name="mediumOfInstructionsInTheSchool.medium2"
              onChange={handleForm}
              value={formValues.mediumOfInstructionsInTheSchool?.medium2}
              ref={inputRefs[31]}
              className="border_black w-100"
              type="text"
            />
            <input
              name="mediumOfInstructionsInTheSchool.medium4"
              onChange={handleForm}
              value={formValues.mediumOfInstructionsInTheSchool?.medium4}
              ref={inputRefs[32]}
              className="border_black mt-2 w-100"
              type="text"
            />
          </div>
        </div>
      </div>
      <p className="mt-2">
        <span className="bg_green">
          <span>1.25</span> Is this a Special School for CWSN?{' '}
        </span>
        <span className="bg_green">(1-Yes, 2-No)</span>
        <span>
          <input
            name="isThisASpecialSchoolForCWSN.isCWSN"
            onChange={handleForm}
            value={formValues.isThisASpecialSchoolForCWSN?.isCWSN}
            ref={inputRefs[33]}
            className="border_black ms-3 me-2"
            type="text"
          />
        </span>{' '}
      </p>
      <p className="mt-2 ms-4">
        <span className="bg_green">
          <span>If 1-Yes</span> then mention TYPE of Special School:
        </span>
        <input
          name="isThisASpecialSchoolForCWSN.typeOfSpecialSchool"
          onChange={handleForm}
          value={formValues.isThisASpecialSchoolForCWSN?.typeOfSpecialSchool}
          ref={inputRefs[34]}
          className="border_black ms-3 me-2"
          type="text"
        />
      </p>
      <p className="mt-1 ms-4">
        <span className="bg_green">
          (1-Visual impairments, 2-Hearing impairments, 3-Motor impairments,
          4-Cognitive impairments, 5-All Types, 6-Others)
        </span>
      </p>
      <p className="mt-2">
        <span className="bg_green">
          <span>1.29</span> Are majority of the pupils taught through their
          mother tongue{' '}
        </span>
        <span className="bg_green">at the Primary level?</span>{' '}
        <span className="bg_green">(1-Yes, 2-No)</span>
        <span className="mx-2">
          <input
            name="motherTongue"
            value={formValues.motherTongue}
            onChange={handleForm}
            ref={inputRefs[35]}
            type="text"
            className="border_black"
          />
        </span>{' '}
      </p>
      <p className="black_underline my-4 bg_green">
        <span>Section 1B: School Safety and Other Parameters</span>
      </p>
      <table>
        <tbody>
          <div className="mt-3 responsive-parent">
            <tr>
              <td className="bg_green px-4">1.55.16</td>
              <td className="bg_green w-100 ps-3">
                Teachers’ attendance captured?
                <span>
                  (1- Attendance Register, 2- Electronically, 3- Biometric)
                </span>
              </td>
              <td>
                <input
                  name="teachersAttendanceCaptured"
                  value={formValues.teachersAttendanceCaptured}
                  onChange={handleForm}
                  ref={inputRefs[36]}
                  type="text"
                />
              </td>
            </tr>
            <p className="mt-3 mb-2 bg_green">
              <span>1.58</span> Whether school is maintaining for the following?{' '}
              <span>(for all Schools in Previous Academic Year)</span>
            </p>
            <table>
              <tbody>
                <tr className="bg_light_gray bg_green text-center">
                  <th className="bg_green">Sl. No</th>
                  <th className="bg_green">Details</th>
                  <th className="bg_green">Availability (1-Yes, 2-No)</th>
                </tr>
                <tr>
                  <td className="bg_green text-center">1.58.1</td>
                  <td className="text-start bg_green">
                    Inventory Register on ICT Items
                  </td>
                  <td>
                    {' '}
                    <input
                      name="inventoryRegisterOnICTItems"
                      value={formValues.inventoryRegisterOnICTItems}
                      onChange={handleForm}
                      ref={inputRefs[37]}
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="mt-3">
              <span className="bg_green">2.6</span>{' '}
              <span className="bg_green">
                {' '}
                Details of Rooms other than Classrooms (excluding toilets):
              </span>
            </p>

            <table className="mt-3">
              <tbody>
                <tr>
                  <td className="text-center bg_green">(vii)</td>
                  <td className="bg_green">
                    Number of Laboratory Rooms including Computer Labs
                  </td>
                  <td className="">
                    <input
                      name="numberOfIncludingComputerLabs"
                      value={formValues.numberOfIncludingComputerLabs}
                      onChange={handleForm}
                      ref={inputRefs[38]}
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="mt-3">
              {' '}
              <span className="bg_green">2.23</span>{' '}
              <span className="bg_green">
                {' '}
                Does the school have the following equipment(s)
              </span>
            </p>
            <table className="mt-3">
              <tbody>
                <tr className="text-center bg_gray">
                  <th>
                    <span className="bg_green">Sl. No. </span>
                  </th>
                  <th>
                    <span className="bg_green">Equipment/Facility </span>
                  </th>
                  <th>
                    <span className="bg_green">
                      Availability (1-Yes, 2-No, 3-Yes but not Functional){' '}
                    </span>
                  </th>
                </tr>

                <tr>
                  <td className="text-center bg_green">(a)</td>
                  <td className="bg_green">
                    Audio/Visual/Public Address System
                  </td>
                  <td>
                    {' '}
                    <input
                      name="equipmentFacility"
                      value={formValues.equipmentFacility}
                      onChange={handleForm}
                      ref={inputRefs[39]}
                      type="text"
                    />
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>

            <p className="mt-3 black_underline bg_green">
              <span>PART B: Computers and Digital Initiatives</span>
            </p>
            <p className="mt-3 bg_green">
              <span>2.24</span> Computer or Digital Equipment Status:{' '}
            </p>
            <table className="mt-3 double-input">
              <tbody>
                <tr className="text-center">
                  <th className="bg_green">
                    <span>Sl. No.</span>
                  </th>
                  <th className="bg_green">
                    <span>Items </span>
                  </th>
                  <th className="bg_green">
                    <span>Total Number of units available </span>
                  </th>
                  <th className="bg_green">
                    <span>
                      Number of Functional Units for pedagogical purpose (Out of
                      Total){' '}
                    </span>
                  </th>
                </tr>
                <tr>
                  <td className="text-center bg_green">(a)</td>
                  <td className="bg_green">Desktop/PCs</td>
                  <td>
                    {' '}
                    <input
                      name="itemsDesktopTotal"
                      value={formValues.itemsDesktopTotal}
                      onChange={handleForm}
                      ref={inputRefs[40]}
                      type="number"
                    />
                  </td>{' '}
                  <td>
                    {' '}
                    <input
                      name="itemsDesktopOutOfTotal"
                      value={formValues.itemsDesktopOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[41]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(b)</td>
                  <td className="bg_green">Laptop/Notebook</td>
                  <td>
                    {' '}
                    <input
                      name="itemsLaptopTotal"
                      value={formValues.itemsLaptopTotal}
                      onChange={handleForm}
                      ref={inputRefs[42]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsLaptopOutOfTotal"
                      value={formValues.itemsLaptopOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[43]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(c)</td>
                  <td className="bg_green">Tablets</td>
                  <td>
                    {' '}
                    <input
                      name="itemsTabletsTotal"
                      value={formValues.itemsTabletsTotal}
                      onChange={handleForm}
                      ref={inputRefs[44]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsTabletsOutOfTotal"
                      value={formValues.itemsTabletsOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[45]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(d)</td>
                  <td className="bg_green">
                    PCs with Integrated Teaching Learning Devices
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsPCWithLearningDevicesTotal"
                      value={formValues.itemsPCWithLearningDevicesTotal}
                      onChange={handleForm}
                      ref={inputRefs[46]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsPCWithLearningDevicesOutOfTotal"
                      value={formValues.itemsPCWithLearningDevicesOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[47]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(e)</td>
                  <td className="bg_green">
                    Digital Boards with Content Management Systems and solutions
                    (CMS)/ Learning Management System
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsCMSTotal"
                      value={formValues.itemsCMSTotal}
                      onChange={handleForm}
                      ref={inputRefs[48]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsCMSOutOfTotal"
                      value={formValues.itemsCMSOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[49]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(f)</td>
                  <td className="bg_green">Projector</td>
                  <td>
                    {' '}
                    <input
                      name="itemsProjectorTotal"
                      value={formValues.itemsProjectorTotal}
                      onChange={handleForm}
                      ref={inputRefs[50]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsProjectorOutOfTotal"
                      value={formValues.itemsProjectorOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[51]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(g)</td>
                  <td className="bg_green">Printer</td>
                  <td>
                    {' '}
                    <input
                      name="itemsPrinterTotal"
                      value={formValues.itemsPrinterTotal}
                      onChange={handleForm}
                      ref={inputRefs[52]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsPrinterOutOfTotal"
                      value={formValues.itemsPrinterOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[53]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(h)</td>
                  <td className="bg_green">Scanner</td>
                  <td>
                    {' '}
                    <input
                      name="itemsScannerTotal"
                      value={formValues.itemsScannerTotal}
                      onChange={handleForm}
                      ref={inputRefs[54]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsScannerOutOfTotal"
                      value={formValues.itemsScannerOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[55]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(i)</td>
                  <td className="bg_green">Server</td>
                  <td>
                    {' '}
                    <input
                      name="itemsServerTotal"
                      value={formValues.itemsServerTotal}
                      onChange={handleForm}
                      ref={inputRefs[56]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsServerOutOfTotal"
                      value={formValues.itemsServerOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[57]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(j)</td>
                  <td className="bg_green">Web Camera</td>
                  <td>
                    {' '}
                    <input
                      name="itemsWebCameraTotal"
                      value={formValues.itemsWebCameraTotal}
                      onChange={handleForm}
                      ref={inputRefs[58]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsWebCameraOutOfTotal"
                      value={formValues.itemsWebCameraOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[59]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(k)</td>
                  <td className="bg_green">Smart TV </td>
                  <td>
                    {' '}
                    <input
                      name="itemsSmartTVTotal"
                      value={formValues.itemsSmartTVTotal}
                      onChange={handleForm}
                      ref={inputRefs[60]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsSmartTVOutOfTotal"
                      value={formValues.itemsSmartTVOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[61]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(l)</td>
                  <td className="bg_green">
                    Smart Class rooms used for teaching with Digital boards/
                    Smart boards/ Virtual classrooms/ Smart TV
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsSmartClassroomTotal"
                      value={formValues.itemsSmartClassroomTotal}
                      onChange={handleForm}
                      ref={inputRefs[62]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsSmartClassroomOutOfTotal"
                      value={formValues.itemsSmartClassroomOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[63]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(m)</td>
                  <td className="bg_green">Mobile phone used for teaching</td>
                  <td>
                    {' '}
                    <input
                      name="itemsMobileTotal"
                      value={formValues.itemsMobileTotal}
                      onChange={handleForm}
                      ref={inputRefs[64]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsMobileOutOfTotal"
                      value={formValues.itemsMobileOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[65]}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(n)</td>
                  <td className="bg_green">
                    Generator/Invertors/Power Backup/Big UPS (above 1KVA)
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsPowerBackupTotal"
                      value={formValues.itemsPowerBackupTotal}
                      onChange={handleForm}
                      ref={inputRefs[66]}
                      type="number"
                    />
                  </td>
                  <td>
                    {' '}
                    <input
                      name="itemsPowerBackupOutOfTotal"
                      value={formValues.itemsPowerBackupOutOfTotal}
                      onChange={handleForm}
                      ref={inputRefs[67]}
                      type="number"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="mt-3">
              <span className="bg_green">
                <span>2.25</span> Does the School have Internet Facility?{' '}
              </span>
              <span className="ps-3 bg_green">(1-Yes, 2-No)</span>
              <span className="ps-3">
                <input
                  name="internetFacility"
                  onChange={handleForm}
                  value={formValues.internetFacility}
                  ref={inputRefs[68]}
                  className="w_150 border_black"
                  type="text"
                />
              </span>{' '}
            </p>

            <p className="mt-3 ps-5 bg_green">
              If <span>1-YES,</span> then
            </p>
            <p className="mt-3">
              <span className="bg_green">
                (a) Type of internet facility available in the school:{' '}
              </span>
              <span className="ps-3">
                <input
                  name="typeOfInternetFacilityInTheSchool"
                  onChange={handleForm}
                  value={formValues.typeOfInternetFacilityInTheSchool}
                  ref={inputRefs[69]}
                  className="w_300 border_black"
                  type="text"
                />
              </span>
            </p>

            <p className="mt-3 bg_green">
              <span>
                <i>
                  (1-Broadband/Leased Line, 2-USB Modem/dongle/Portable Hotspot,
                  3-Telephone line with modem, 4-Mobile phone Internet, 5-Any
                  Other type of connection, 6-VSAT)
                </i>
              </span>
            </p>
            <p className="mt-3">
              <span className="bg_green">
                <span>2.26 </span>Is Computer/ICT Lab available in school?{' '}
              </span>
              <span className="ps-3 bg_green">(1-Yes, 2-No)</span>
              <span className="ps-3">
                <input
                  name="ictLabAvailableInSchool"
                  onChange={handleForm}
                  value={formValues.ictLabAvailableInSchool}
                  ref={inputRefs[70]}
                  className="w_150 border_black"
                  type="text"
                />
              </span>{' '}
            </p>
            <p className="mt-3 bg_green">
              If 2.26 is <span>1-YES,</span>
            </p>
            <p className="ms-3 mt-2">
              <span className="bg_green">
                (a) Number of ICT Labs Available:{' '}
              </span>
              <span className="ps-3">
                <input
                  name="numberOfICTLabsAvailable"
                  onChange={handleForm}
                  value={formValues.numberOfICTLabsAvailable}
                  ref={inputRefs[71]}
                  className="w_150 border_black"
                  type="number"
                />
              </span>
            </p>
            <p className="mt-3 bg_green">
              <span className="fw_700">
                (2.26(b) IS ONLY FOR GOVT. AND GOVT. AIDED SCHOOLS)
              </span>
            </p>

            <div className="ms-5">
              <p className="mt-3 bg_green">
                If 2.26 is <span>1-YES,</span>
              </p>
              <p className="mt-3 ps-3">
                <span className="bg_green">
                  (b) Is the ICT Lab available in the school under Samagra
                  Siksha?{' '}
                </span>
                <span className="ps-3 bg_green">(1-Yes, 2-No)</span>
                <span className="ps-3">
                  <input
                    name="ictLabAvailableInSchoolUnderSamagraSiksha"
                    onChange={handleForm}
                    value={formValues.ictLabAvailableInSchoolUnderSamagraSiksha}
                    ref={inputRefs[72]}
                    className="w_150 border_black"
                    type="text"
                  />
                </span>{' '}
              </p>

              <p className="mt-3 ps-3">
                <span className="bg_green">(i) Year of implementation: </span>
                <span className="ps-3">
                  <input
                    name="yearOfImplementation"
                    onChange={handleForm}
                    value={formValues.yearOfImplementation}
                    ref={inputRefs[73]}
                    className="w_150 border_black"
                    type="Number"
                  />
                </span>
              </p>
              <p className="mt-3 ps-3">
                <span className="bg_green">
                  (ii) Whether the ICT Lab is functional or not?{' '}
                </span>
                <span className="ps-3 bg_green">(1-Yes, 2-No)</span>
                <span className="ps-3">
                  <input
                    name="whetherTheICTLabIsFunctionalOrNot"
                    onChange={handleForm}
                    value={formValues.whetherTheICTLabIsFunctionalOrNot}
                    ref={inputRefs[74]}
                    className="w_150 border_black"
                    type="text"
                  />
                </span>{' '}
              </p>
              <p className="mt-3 ps-3">
                <span className="bg_green">
                  (iii) Which model is implemented in the school :{' '}
                </span>
                <span className="ps-3 bg_green">
                  <i>(1-BOOT Model, 2-BOO Model, 3-Others)</i>
                </span>
                <span className="ps-3">
                  <input
                    name="whichModelIsImplementedInTheSchool"
                    onChange={handleForm}
                    value={formValues.whichModelIsImplementedInTheSchool}
                    ref={inputRefs[75]}
                    type="text"
                    className="border_black"
                  />
                </span>{' '}
              </p>
              <p className="mt-3 ps-3">
                <span className="bg_green">
                  (iv) Type of the ICT Instructor in the school:
                </span>
                <span className="ps-3 bg_green">
                  (1-Full Time, 2-Part Time, 3-Not Available)
                </span>
                <span className="ps-3">
                  <input
                    name="typeOfTheICTInstructorInTheSchool"
                    onChange={handleForm}
                    value={formValues.typeOfTheICTInstructorInTheSchool}
                    ref={inputRefs[76]}
                    type="text"
                    className="border_black"
                  />
                </span>{' '}
              </p>
            </div>

            <p className="mt-3 bg_green">
              <span>2.27</span> Does the school have the following facilities?
            </p>
            <table className="mt-3">
              <tbody>
                <tr className="bg_gray text-center">
                  <th className="bg_green">
                    <span>Sl. No.</span>
                  </th>
                  <th className="bg_green">
                    <span>Equipment/Facility</span>
                  </th>
                  <th className="bg_green">
                    <span>1-Yes / 2-No</span>
                  </th>
                </tr>
                <tr>
                  <td className="text-center bg_green">(a)</td>
                  <td className="bg_green">
                    Whether ICT based tools are being used for teaching?
                  </td>
                  <td>
                    {' '}
                    <input
                      name="facilityICTToolsForTeaching"
                      onChange={handleForm}
                      value={formValues.facilityICTToolsForTeaching}
                      ref={inputRefs[77]}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(b)</td>
                  <td className="bg_green">
                    Access to e-Contents/Digital Contents/resources at school
                  </td>
                  <td>
                    {' '}
                    <input
                      name="facilityAccessToEContent"
                      onChange={handleForm}
                      value={formValues.facilityAccessToEContent}
                      ref={inputRefs[78]}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(c)</td>
                  <td className="bg_green">
                    Assistive tech-based solutions for CWSN
                  </td>
                  <td>
                    {' '}
                    <input
                      name="facilityTechBasedCWSN"
                      onChange={handleForm}
                      value={formValues.facilityTechBasedCWSN}
                      ref={inputRefs[79]}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center bg_green">(d)</td>
                  <td className="bg_green">Access to DTH/TV channels</td>
                  <td>
                    {' '}
                    <input
                      name="facilityAccessToDTH"
                      onChange={handleForm}
                      value={formValues.facilityAccessToDTH}
                      ref={inputRefs[80]}
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="mt-3">
              <span className="bg_green">
                <span>2.28</span> Does the School have Digital Library?
              </span>
              <span className="ps-3 bg_green">(1-Yes, 2-No)</span>
              <span className="ps-3">
                <input
                  name="doesTheSchoolHaveDigitalLibrary"
                  onChange={handleForm}
                  value={formValues.doesTheSchoolHaveDigitalLibrary}
                  ref={inputRefs[81]}
                  className="w_150 border_black"
                  type="text"
                />
              </span>{' '}
            </p>
            <p className="mt-3 ps-3 bg_green">
              If <span> 1-YES,</span>{' '}
            </p>
            <p className="mt-3 ps-3">
              <span className="bg_green">
                Number of e-Books/e-Contents available:{' '}
              </span>
              <span>
                <input
                  name="numberOfEBooksContentsavailable"
                  value={formValues.numberOfEBooksContentsavailable}
                  onChange={handleForm}
                  ref={inputRefs[82]}
                  className="ps-3 w_150 border_black"
                  type="Number"
                />
              </span>
            </p>

            <div className="border_black mt-5 single-input">
              <div className="d-flex align-items-center bg_green">
                <p className=" w-50 border_right_gray p-2 fs_16 fw_700 black">
                  Academic Year : {getCurrentYear()}
                </p>
                <p className="w-100 p-2 fs_16 fw_700 black">
                  UDISE Code of School : {formValues.udiseCode}
                </p>
              </div>
              <div className="border_top_black d-flex">
                <p className="text_red fs_16 fw_700 p-2 border_right_gray w-100 bg_green">
                  PART C : TRAINING AND OTHER DETAILS
                </p>
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.22 Trained for teaching CWSN? (1-Yes/2-No)
                  <span className="fw_600 ms-1">(1-Yes/2-No)</span>
                </p>{' '}
                <input
                  name="trainedForTeachingCWSN"
                  value={formValues.trainedForTeachingCWSN}
                  onChange={handleForm}
                  ref={inputRefs[83]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.23 Trained in Computer for use in teaching?
                  <span className="fw_600 ms-1">(1-Yes/2-No)</span>
                </p>
                <input
                  name="trainedInComputer"
                  value={formValues.trainedInComputer}
                  onChange={handleForm}
                  ref={inputRefs[84]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.24 (a) Training received
                </p>
                <input
                  name="trainingReceived"
                  value={formValues.trainingReceived}
                  onChange={handleForm}
                  ref={inputRefs[85]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.24 (b) Training Needs
                </p>
                <input
                  name="trainingNeeds"
                  value={formValues.trainingNeeds}
                  onChange={handleForm}
                  ref={inputRefs[86]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.25 Working days spent on NON-Teaching Assignments:
                </p>
                <input
                  name="trainingWorkingDays"
                  value={formValues.trainingWorkingDays}
                  onChange={handleForm}
                  ref={inputRefs[87]}
                  type="number"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.26 Languages in which the teacher is having working
                  knowledge?
                </p>
                <input
                  name="trainingLanguagesKnowlegde"
                  value={formValues.trainingLanguagesKnowlegde}
                  onChange={handleForm}
                  ref={inputRefs[88]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  (a) Language 1:
                </p>
                <input
                  name="trainingLanguage1"
                  value={formValues.trainingLanguage1}
                  onChange={handleForm}
                  ref={inputRefs[89]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  (b) Language 2:
                </p>
                <input
                  name="trainingLanguage2"
                  value={formValues.trainingLanguage2}
                  onChange={handleForm}
                  ref={inputRefs[90]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  (c) Language 3:
                </p>
                <input
                  name="trainingLanguage3"
                  value={formValues.trainingLanguage3}
                  onChange={handleForm}
                  ref={inputRefs[91]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.27 Whether trained in safety and security audit of school
                  for ensuring child safety?{' '}
                  <em className="fw_600">(1-Yes, 2-No)</em>
                </p>
                <input
                  name="trainingChildSafety"
                  value={formValues.trainingChildSafety}
                  onChange={handleForm}
                  ref={inputRefs[92]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.28 Whether received training on cyber safety and
                  psycho-social aspects?
                  <em className="fw_600">(1-Yes, 2-No)</em>
                </p>
                <input
                  name="trainingReceivedOnCyberSafety"
                  value={formValues.trainingReceivedOnCyberSafety}
                  onChange={handleForm}
                  ref={inputRefs[93]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.29 Whether received training in early identification
                  support and classroom support of CWSN?
                  <em className="fw_600">(1-Yes, 2-No)</em>
                </p>
                <input
                  name="trainingReceivedIdentificationSupportCWSN"
                  value={formValues.trainingReceivedIdentificationSupportCWSN}
                  onChange={handleForm}
                  ref={inputRefs[94]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.30 Whether having ICT training/ knowledge to conduct
                  remote learning classes?
                  <em className="fw_600">(1-Yes, 2-No)</em>
                </p>
                <input
                  name="trainingRemoteClasses"
                  value={formValues.trainingRemoteClasses}
                  onChange={handleForm}
                  ref={inputRefs[95]}
                  type="text"
                />
              </div>
              <div className="border_top_black d-flex">
                <p className="black fw_500 fs_16 p-2 border_right_gray w-75 bg_green">
                  3.3.31 Whether capable of handling digital devices and VC
                  applications? <br />
                  (Mobile, Laptops, Smart Boards, Desktops, Projectors, the use
                  of computers <br />
                  to retrieve, assess, store, produce, present and exchange
                  information, and to <br />
                  communicate and participate in collaborative networks via the
                  Internet, interacting through digital technologies, Protecting
                  personal data and privacy)
                  <em className="fw_600 ms-1">(1-Yes, 2-No)</em>
                </p>
                <input
                  name="trainingCapableHandlingDigitalDevices"
                  value={formValues.trainingCapableHandlingDigitalDevices}
                  onChange={handleForm}
                  ref={inputRefs[96]}
                  type="text"
                />
              </div>
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
}
