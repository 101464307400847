import React , {useEffect, useRef} from 'react';
import url from "../../api/api";
import { Modal, Button,Form, Schema, useToaster, Notification, Uploader,SelectPicker } from 'rsuite';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import { addAAsset, addActivityLog } from '../../services/services';
const { StringType,DateType,NumberType } = Schema.Types;
const model = Schema.Model({
    nameOfTheEquipment: StringType().isRequired('This field is required.'),
    quantity: NumberType().isRequired('This field is required.'),
    status: StringType().isRequired('This field is required.'),
    remarks: StringType().isRequired('This field is required.'),
});

function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

const AddNewAsset = ({onFetch,loading,hide}) => {
  const toast = useToaster()
  const formRef = useRef();

  const [formError, setFormError] = React.useState({});
  const [instuctorInfo, setInstuctorInfo] = React.useState({username : ''});
  const [formValue, setFormValue] = React.useState({
    nameOfTheEquipment: '',
    quantity: '',
    status: '',
    remarks: '',
  });
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };
  const selectStatus = ['Functional', 'Non-Functional'].map(item => ({
    label: item,
    value: item
  }));
  const handleClose = () => setOpen(false);
  const reset = () => {
    setFormValue({
        nameOfTheEquipment: '',
        quantity: '',
        status: '',
        remarks: '',
    })
  }
  const handleAddAsset = async () => {
    if (!formRef.current.check()) {
      console.error('Form Error',formError);
      return;
    } else {
      try {
        const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
        const payload = {
          "udiseCode": instuctor.udiseCode,
          "divisionName" : instuctor.schoolInfo[0].division_name,
          ...formValue
        }
        let response = await addAAsset(payload);
        if(response.data.status) {
          onFetch(instuctor.udiseCode);
          setOpen(false);
          reset();
        } else {
          toast.push(<Notification type='error' header='Access Denied'>{response.data.message}</Notification>,{
            placement : 'topEnd'
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

  }
  useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor) {
      setInstuctorInfo(instuctor);
    }
  },[])
  return (
    <>
        <Button disabled={loading} style={{marginLeft : '10px' , display : hide ? 'none' : ''}} color="blue" size="md" appearance="primary" onClick={() => handleOpen('md')} endIcon={<UserInfoIcon />}>
        Add A Asset
        </Button>
        {hide ? '' : <hr />}
      <Modal backdrop={'static'} size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Add A New Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  
          model={model}           
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}>
            <TextField name="nameOfTheEquipment" label="Name Of The Equipment" placeholder='Name Of The Equipment' />
            <TextField name="quantity" label="Quantity" placeholder='Quantity' />
            <TextField name="status" label="Status" placeholder='Status' data={selectStatus} accepter={SelectPicker} />
            <TextField name="remarks" label="Remarks" placeholder='Remarks' />
            <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button type="submit" appearance="primary" onClick={handleAddAsset}>
            Ok
          </Button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default AddNewAsset;