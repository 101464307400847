import React , {useEffect, useRef} from 'react';
import url from "../../api/api";
import { Modal, Button,Form, Schema, useToaster, Notification, Uploader, SelectPicker,DatePicker } from 'rsuite';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import { addActivityLog } from '../../services/services';
import moment from 'moment';
const { StringType,DateType } = Schema.Types;
const model = Schema.Model({
    selected_date: DateType().isRequired('This field is required.'),
    // day: StringType().isRequired('This field is required.'),
    activityDescription: StringType().isRequired('This field is required.'),
    startTime: StringType().isRequired('This field is required.'),
    endTime: StringType().isRequired('This field is required.'),
});

function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

const AddNewActivity = ({onFetch,loading}) => {
  const toast = useToaster()
  const formRef = useRef();
  const uploader = React.useRef();

  const [fileList, setFileList] = React.useState([]);
  const [formError, setFormError] = React.useState({});
  const [instuctorInfo, setInstuctorInfo] = React.useState({username : ''});
  const [formValue, setFormValue] = React.useState({
    selected_date: '',
    // day: '',
    activityDescription: '',
    startTime: '',
    endTime: '',
    evidenceLink : ''
  });
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(item => ({
    label: item,
    value: item
  }));;
  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false)
    reset()
  };
  const reset = () => {
    setFormValue({
      selected_date: '',
      day: '',
      activityDescription: '',
      startTime: '',
      endTime: '',
      evidenceLink : ''
    })
    setFileList([])
  }
  const handleAddStudent = async () => {
    if (!formRef.current.check()) {
      console.error('Form Error',formError);
      return;
    } else {
      try {
        const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
        const payload = {
          "username": instuctor.username,
          "divisionName" : instuctor.schoolInfo[0].division_name,
          "createdAt" : new Date(formValue.selected_date),
          ...formValue
        }
        let response = await addActivityLog(payload);
        if(response.data.status) {
          onFetch(instuctor.username);
          setOpen(false);
          reset();
        } else {
          toast.push(<Notification type='error' header='Access Denied'>{response.data.message}</Notification>,{
            placement : 'topEnd'
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

  }
  useEffect(()=>{
    const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
    if(instuctor) {
      setInstuctorInfo(instuctor);
    }
  },[])
  return (
    <>
        <Button disabled={loading} color="blue" size="md" appearance="primary" onClick={() => handleOpen('md')} endIcon={<UserInfoIcon />}>
        Add A New Activity Log
        </Button>
        <hr />
      <Modal backdrop={'static'} size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Add A New Activity Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  
          model={model}           
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}>
            <TextField name="selected_date" label="Date" type="date" />
            <TextField name="day" label="Day" placeholder='Day' readOnly value={moment(formValue.selected_date).format('dddd') === 'Invalid date' ? 'Day' : moment(formValue.selected_date).format('dddd')} />
            <TextField name="activityDescription" label="Activity Description" placeholder='Activity Description' />
            <p className='mb-2'>Start Time</p>
            <DatePicker className='mb-3' name='startTime' placeholder='00:00 AM' format='HH:mm' onChange={(e)=>{
              setFormValue({...formValue,startTime : moment(e).format('HH:mm')})
            }} />
            <p className='mb-2'>End Time</p>
            <DatePicker className='mb-3' name='endTime' placeholder='00:00 AM' format='HH:mm' onChange={(e)=>{
              setFormValue({...formValue,endTime : moment(e).format('HH:mm')})
            }} />
            <p className='mb-2'>Evidence of the activity</p>
            <Uploader
              fileList={fileList}
              listType="picture-text"
              accept=".jpg,.png,.pdf.xlxs"
              action={`${url}/instuctor/file/upload?username=${instuctorInfo.username}&isSignedCopy=${false}`}
              onSuccess={(response)=>{
                  setFormValue({...formValue,evidenceLink : response.data.link})
              }}
              onChange={setFileList}
              ref={uploader}
            >
        <Button>Select files...</Button>
      </Uploader>
            <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button type="submit" disabled={!fileList.length} appearance="primary" onClick={handleAddStudent}>
            Ok
          </Button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default AddNewActivity;