import React from 'react';
import { Modal, Button } from 'rsuite';
import { Message } from 'rsuite';
const Messages = ({isOpen,handleClose}) => {
  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Message  showIcon type="info" header="Informational">Fill All The Green Highlighted Fields</Message>
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Messages;