import React , {useEffect, useRef} from 'react';
import { Modal, Button,Form, Schema,SelectPicker } from 'rsuite';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import { adminAdd, adminUpdateStatus, updateUserPassword } from '../../services/services';
// Notifications
import { Message, useToaster } from 'rsuite';

const { StringType } = Schema.Types;

const model = Schema.Model({
    // username: StringType().isRequired('This field is required.'),
    password: StringType().isRequired('This field is required.'),
});

function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

const UserPasswordUpdate = ({onFetch,userId,userName,loading,division}) => {
  const toaster = useToaster();
  const pushNotification = (type,header,message) => {
      toaster.push(<Message showIcon type={type} header={header}>{message}</Message>, {
        placement : 'topEnd',
        duration: 3000
      })
    }
  const formRef = useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    username : '',
    password: ''
  });
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const reset = () => {
    setFormValue({
        username : '',
        password: ''
    })
  }
  const handleAdmin= async () => {
    if (!formRef.current.check()) {
      console.error('Form Error',formError);
      return;
    } else {
      try {
        const json = {
            id : userId,
            password : formValue.password,
            sortBy : division
        }
        let response = await updateUserPassword(json);
        if(response.data.status) {
          onFetch(division,'');
          pushNotification('success','Updated',response.data.message)
          setOpen(false);
          reset();
        } else {
            pushNotification('warning','Note',response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    }

  }
  useEffect(()=>{
    setFormValue({username : userName})
  },[userName])
  return (
    <>
        <Button disabled={loading} style={{marginLeft : '10px'}} size="xs" color="cyan" appearance="primary" onClick={() => handleOpen('md')}>
        Update Password
        </Button>
      <Modal backdrop={'static'} size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Update User Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  
          model={model}           
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}>
            <Form.Group controlId="select-10">
              {/* <Form.ControlLabel>Username</Form.ControlLabel>
              <Form.Control name="username" value={formValue.username} readOnly /> */}
               <p>
                <b>Username:</b> {formValue.username}
              </p>
              <Form.ControlLabel>Password</Form.ControlLabel>
              <Form.Control name="password" />
            </Form.Group>
            <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button type="submit" appearance="primary" onClick={handleAdmin}>
            Update
          </Button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default UserPasswordUpdate;