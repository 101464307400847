import React , {useEffect, useRef} from 'react';
import { Modal, Button,Form, Schema,SelectPicker,InputGroup } from 'rsuite';
import { updateUDISECode } from '../../services/services';
// Notifications
import { Message, useToaster } from 'rsuite';
const { StringType,NumberType } = Schema.Types;

const data = ['Amravati', 'Aurangabad', 'Kokan', 'Nagpur','Nashik','Pune'];

const model = Schema.Model({
    division_name: StringType().isRequired('This field is required.'),
    division_head: StringType().isRequired('This field is required.'),
    district: StringType().isRequired('This field is required.'),
    block_name: StringType().isRequired('This field is required.'),
    udise_code: NumberType().isRequired('This field is required.'),
    school_name: StringType().isRequired('This field is required.'),
    hm_name: StringType().containsLetterOnly().isRequired('This field is required.'),
    mobile_number: NumberType().min(999999999,'Mobile Number must 10 digit long!').max(9999999990,'Mobile Number must 10 digit!').isRequired('This field is required.'),
    address: StringType().isRequired('This field is required.'),
    pin_code: NumberType().isRequired('This field is required.'),
    level: StringType().isRequired('This field is required.'),
  });
  const selectStatus = ['Amravati', 'Aurangabad', 'Kokan', 'Nagpur','Nashik','Pune'].map(item => ({
    label: item,
    value: item
  }));
function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

const UpdateAUDISECode = ({onFetch,loading,hide,udiseData,division}) => {
  const toaster = useToaster();
  const pushNotification = (type,header,message) => {
      toaster.push(<Message showIcon type={type} header={header}>{message}</Message>, {
        placement : 'topEnd',
        duration: 3000
      })
    }
  const formRef = useRef();
  const [formError, setFormError] = React.useState({});
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [formValue, setFormValue] = React.useState({
    division_name: '',
    division_head: '',
    district: '',
    block_name: '',
    udise_code: '',
    school_name: '',
    hm_name: '',
    mobile_number:'' ,
    address: '',
    pin_code: '',
    level: ''
  });
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();

  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const reset = () => {
    setFormValue({
        division_name: '',
        division_head: '',
        district: '',
        block_name: '',
        udise_code: '',
        school_name: '',
        hm_name: '',
        mobile_number:'' ,
        address: '',
        pin_code: '',
        level: ''
    });
  }
  const handleUpdate= async () => {
    if (!formRef.current.check()) {
      console.error('Form Error',formError);
      return;
    } else {
      try {
        setIsUpdating(true);
        let response = await updateUDISECode(formValue);
        if(response.data.status) {
        onFetch(division,'');
        pushNotification('success','Updated',response.data.message)
          setOpen(false);
          reset();
          setIsUpdating(false)
        } else {
            pushNotification('warning','Note',response.data.message)
            setIsUpdating(false)
        }
      } catch (error) {
        setIsUpdating(false)
        console.log(error)
      }
    }

  }
  useEffect(()=>{
    const instuctor = localStorage.getItem('adminPermissionToken');
    if(instuctor){
        setFormValue(udiseData)
    } else {

    }
    
  },[udiseData])
  return (
    <>
        <Button disabled={loading} style={{display : hide ? 'none' : 'block',marginRight : 10}} color="blue" size="xs" appearance="link" onClick={() => handleOpen('md')}>
         Edit
        </Button>
      <Modal backdrop={'static'} size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Edit School Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  
          model={model}           
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}>
            <TextField name="division_name" label="Division Name" data={selectStatus} accepter={SelectPicker} />
            <TextField name="division_head" label="Division Head" />
            <TextField name="district" label="District" />
            <TextField name="block_name" label="Block Name" />
            <TextField name="udise_code" label="UDISE Code" type='number' />
            <TextField name="school_name" label="School Name" />
            <TextField name="hm_name" label="HM Name" />
            <InputGroup style={{width: 300,marginBottom: 10}}>
              <InputGroup.Addon>+91</InputGroup.Addon>
              <TextField name="mobile_number" type='number' />
            </InputGroup>
            <TextField name="address" label="Address" />
            <TextField name="pin_code" label="Pin Code" type='number' />
            <TextField name="level" label="Level" />
            <Modal.Footer>
          <Button loading={isUpdating} onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button loading={isUpdating} type="submit" appearance="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default UpdateAUDISECode;