import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// Services
import { userLogin } from '../../services/services';
import { Loader,Message, useToaster } from 'rsuite';
// Utils
import { rememberMe } from '../../utils/utils';
import { RadioTile, RadioTileGroup,useMediaQuery } from 'rsuite';
import IdInfoIcon from '@rsuite/icons/IdInfo';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import AdminIcon from '@rsuite/icons/Admin';

export default function Login() {
    const navigate = useNavigate();
    const toaster = useToaster();
    const [isInline] = useMediaQuery('xl'); // (min-width: 1200px)
    const [isLoading, setLoading] = useState(false);
    const [udiseCode,setUdiseCode] = useState();
    const [isChecked,setIsChecked] = useState(false);
    const [error, setError] = useState({
        errorType: '',
        errorMessage: ''
    })
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!udiseCode) {
            setError({ errorType: 'udiseCode' });
        }
        else {
            try {
                setLoading(true)
                const response = await userLogin({udiseCode : udiseCode});
                if (response.data.status) {
                    setLoading(false);
                    if(localStorage.getItem('isRememberMe') === 'true'){
                      localStorage.setItem('rememberMeUdiseCode',udiseCode)
                    } else {
                      localStorage.removeItem('rememberMeUdiseCode')
                    }
                    localStorage.setItem("userInfo", JSON.stringify(response.data.data));
                    localStorage.setItem("accessToken",response.data.accessToken)
                    toaster.push(<Message showIcon type={'success'} header='Success'>{response.data.message}</Message>, {
                        placement : 'topEnd',
                        duration: 3000
                      })
                    navigate("/home");
                } else {
                  toaster.push(<Message showIcon type={'error'} header='Error'>{response.data.message}</Message>, {
                    placement : 'topEnd',
                    duration: 3000
                  })
                  setLoading(false);
                }
            } catch (error) {
                toaster.push(<Message showIcon type={'error'} header='Error'>{error.response?.data?.message}</Message>, {
                    placement : 'topEnd',
                    duration: 3000
                  })
                setLoading(false);
                console.log(error)
            }
        }
    };
    useEffect(()=>{
      const isUdiseCode = localStorage.getItem('rememberMeUdiseCode');
      const isRememberMe = localStorage.getItem('isRememberMe');
      if(isUdiseCode && isRememberMe === 'true'){
        setUdiseCode(isUdiseCode);
        setIsChecked(true);
      }
    },[])
    return (
        <>
  <div className="container">
    <div className="row login-vh flex-column-reverse flex-lg-row">
      <div className="col-12 col-lg-7 bg-white d-flex align-items-center flex-grow-1">
        <div className=" p-3 p-sm-5 w-100">
          <div className="mx-auto d-flex flex-column align-items-center gap-2">
            <img src="./assets/icon/MPSP logo.png" alt="" className="logo" onClick={()=>{
              navigate('/home')
            }} />
            <p className="fs_24 fw_600 color_blue_black white-space-nowrap text-center">
              ICT For Schools
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-grow-1 text-center">
            <form className="mt-sm-5 form_width w-100">
              <h1 className="fs_39 fw_700 color_blue_black">Namaste <img src='./assets/icon/folded.png' /></h1>
              <h3 className="fs_24 fw_600 color_blue_black mt-2 pt-1 pb-2">
                Sign in your account
              </h3>
              <div className={error.errorType === "udiseCode" ? "d-flex align-items-center input_decoration mt-sm-5 input-error" : "d-flex align-items-center input_decoration mt-sm-5"}>
                <img src="./assets/icon/key-icon.svg" alt="key-icon" />
                <input
                  className="w-100 ms-2 fs_16 fw_600 color_blue_black"
                  type="text"
                  value={udiseCode}
                  onChange={(e)=>{
                    setError({
                        errorMessage : '',
                        errorType : ''
                    })
                    setUdiseCode(e.target.value);
                  }}
                  placeholder={`School's UDISE+ Code`}
                />
              </div>
              <p className="fs_16 fw_600 text-start mt-4 pt-1 color_red">
                <span className="fw_700">*UDISE+</span> Code is required.
              </p>
              <div className="d-flex align-items-center mt-4 pt-2">
                <input type="checkbox" id="remember" checked={isChecked} onChange={(e)=>{
                  rememberMe(e.target.checked)
                  setIsChecked(e.target.checked)
                }} />
                <label
                  className="ms-2 fs_16 fw_600 color_blue_black"
                  htmlFor="remember"
                >
                  Remember me
                </label>
              </div>
              <button className="w-100 mt-4 sign_in_btn text-white" disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? <Loader size="sm" content="Loading..." /> : 'Sign In'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-5 p-5 d-flex align-items-center side_bg">
        <div className="mx-auto">
          <h2
            className="text-white fw-400 text-center"
            style={{ fontSize: 45 }}
          >
            Welcome!
          </h2>
          <p
            className="text-center fs_20 fw-400 text-white mt-2"
            style={{ lineHeight: "2.5rem" }}
          >
            Empowering Minds, Inspiring Futures
          </p>
          <RadioTileGroup disabled={true} inline={!isInline} aria-label="Visibility Level">
            <RadioTile style={{color : '#fff'}} icon={<IdInfoIcon />} label={isInline ? "UDISE School Login" : ""} value="code">
             
            </RadioTile>
            <RadioTile style={{color : '#fff',marginLeft : '50px'}} icon={<CharacterAuthorizeIcon />} label={isInline ? "Instructor Login" : ""} value="instuctor">
           
            </RadioTile>
            <RadioTile style={{color : '#fff',marginLeft : '50px'}} icon={<OperatePeopleIcon />} label={isInline ? "Admin Login" : ""} value="admin">
           
            </RadioTile>
          </RadioTileGroup>
        </div>
      </div>
    </div>
  </div>
  <section className="my-5 d-none">
    <div className="container">
      <p className="fs_39 fw_700 color_blue_black text-center py-5">
        Helpdesk Videos
      </p>
      <div className="row gy-4">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="img-area">
              <img
                src="./assets/icon/card-img.png"
                alt=""
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
              <img
                src="./assets/icon/play-icon.svg"
                alt=""
                className="play-btn"
              />
            </div>
            <div className="description-area">
              <p className="fs_20 fw_700 color_blue_black">
                {" "}
                How to use this platform?
              </p>
              <p className="fs-18 fw-400 color_blue_black mt-2">
                {" "}
                Maecenas tincidunt in odio convallis aliquet viverra at
                fermentum nibh. Massa in vel massa sit ac. Aenean facilisis
                mattis vestibulum et viverra velit volutpat. Id senectus ornare
                nunc ultricies enim sit. Arcu purus mauris nullam.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="img-area">
              <img
                src="./assets/icon/card-img.png"
                alt=""
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
              <img
                src="./assets/icon/play-icon.svg"
                alt=""
                className="play-btn"
              />
            </div>
            <div className="description-area">
              <p className="fs_20 fw_700 color_blue_black">
                {" "}
                How to use this platform?
              </p>
              <p className="fs-18 fw-400 color_blue_black mt-2">
                {" "}
                Maecenas tincidunt in odio convallis aliquet viverra at
                fermentum nibh. Massa in vel massa sit ac. Aenean facilisis
                mattis vestibulum et viverra velit volutpat. Id senectus ornare
                nunc ultricies enim sit. Arcu purus mauris nullam.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="img-area">
              <img
                src="./assets/icon/card-img.png"
                alt=""
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
              <img
                src="./assets/icon/play-icon.svg"
                alt=""
                className="play-btn"
              />
            </div>
            <div className="description-area">
              <p className="fs_20 fw_700 color_blue_black">
                {" "}
                How to use this platform?
              </p>
              <p className="fs-18 fw-400 color_blue_black mt-2">
                {" "}
                Maecenas tincidunt in odio convallis aliquet viverra at
                fermentum nibh. Massa in vel massa sit ac. Aenean facilisis
                mattis vestibulum et viverra velit volutpat. Id senectus ornare
                nunc ultricies enim sit. Arcu purus mauris nullam.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="img-area">
              <img
                src="./assets/icon/card-img.png"
                alt=""
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
              <img
                src="./assets/icon/play-icon.svg"
                alt=""
                className="play-btn"
              />
            </div>
            <div className="description-area">
              <p className="fs_20 fw_700 color_blue_black">
                {" "}
                How to use this platform?
              </p>
              <p className="fs-18 fw-400 color_blue_black mt-2">
                {" "}
                Maecenas tincidunt in odio convallis aliquet viverra at
                fermentum nibh. Massa in vel massa sit ac. Aenean facilisis
                mattis vestibulum et viverra velit volutpat. Id senectus ornare
                nunc ultricies enim sit. Arcu purus mauris nullam.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="img-area">
              <img
                src="./assets/icon/card-img.png"
                alt=""
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
              <img
                src="./assets/icon/play-icon.svg"
                alt=""
                className="play-btn"
              />
            </div>
            <div className="description-area">
              <p className="fs_20 fw_700 color_blue_black">
                {" "}
                How to use this platform?
              </p>
              <p className="fs-18 fw-400 color_blue_black mt-2">
                {" "}
                Maecenas tincidunt in odio convallis aliquet viverra at
                fermentum nibh. Massa in vel massa sit ac. Aenean facilisis
                mattis vestibulum et viverra velit volutpat. Id senectus ornare
                nunc ultricies enim sit. Arcu purus mauris nullam.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="img-area">
              <img
                src="./assets/icon/card-img.png"
                alt=""
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
              <img
                src="./assets/icon/play-icon.svg"
                alt=""
                className="play-btn"
              />
            </div>
            <div className="description-area">
              <p className="fs_20 fw_700 color_blue_black">
                {" "}
                How to use this platform?
              </p>
              <p className="fs-18 fw-400 color_blue_black mt-2">
                {" "}
                Maecenas tincidunt in odio convallis aliquet viverra at
                fermentum nibh. Massa in vel massa sit ac. Aenean facilisis
                mattis vestibulum et viverra velit volutpat. Id senectus ornare
                nunc ultricies enim sit. Arcu purus mauris nullam.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="img-area">
              <img
                src="./assets/icon/card-img.png"
                alt=""
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
              <img
                src="./assets/icon/play-icon.svg"
                alt=""
                className="play-btn"
              />
            </div>
            <div className="description-area">
              <p className="fs_20 fw_700 color_blue_black">
                {" "}
                How to use this platform?
              </p>
              <p className="fs-18 fw-400 color_blue_black mt-2">
                {" "}
                Maecenas tincidunt in odio convallis aliquet viverra at
                fermentum nibh. Massa in vel massa sit ac. Aenean facilisis
                mattis vestibulum et viverra velit volutpat. Id senectus ornare
                nunc ultricies enim sit. Arcu purus mauris nullam.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card">
            <div className="img-area">
              <img
                src="./assets/icon/card-img.png"
                alt=""
                className="h-100 w-100"
                style={{ objectFit: "cover" }}
              />
              <img
                src="./assets/icon/play-icon.svg"
                alt=""
                className="play-btn"
              />
            </div>
            <div className="description-area">
              <p className="fs_20 fw_700 color_blue_black">
                {" "}
                How to use this platform?
              </p>
              <p className="fs-18 fw-400 color_blue_black mt-2">
                {" "}
                Maecenas tincidunt in odio convallis aliquet viverra at
                fermentum nibh. Massa in vel massa sit ac. Aenean facilisis
                mattis vestibulum et viverra velit volutpat. Id senectus ornare
                nunc ultricies enim sit. Arcu purus mauris nullam.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    )
}
