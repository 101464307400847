import moment from "moment";
import {utils,writeFile} from "xlsx";

export const rememberMe = (type) => {
  if(type){
    localStorage.setItem('isRememberMe',true);
  } else {
    localStorage.setItem('isRememberMe',false);
  }
}

export const rememberMeAdmin = (type) => {
  if(type){
    localStorage.setItem('isRememberMeAdmin',true);
  } else {
    localStorage.setItem('isRememberMeAdmin',false);
  }
}

export const rememberMeInstuctor = (type) => {
  if(type){
    localStorage.setItem('isRememberMeInstuctor',true);
  } else {
    localStorage.setItem('isRememberMeInstuctor',false);
  }
}

export const arrayOfJSONWithCount = (array) => {
  let arrayWithCount = array.map((item, index) => ({
    ...item,
    count: index + 1, // Adding 1 because array indices are 0-based
    id : item._id,
    createdAt : moment(item.createdAt).format('DD-MMM-YYYY')
  }));
  return arrayWithCount
}

export const getCurrentYear = () => {
  // Get the current year
const currentYear = new Date().getFullYear() - 1;

// Calculate the next year
const nextYear = new Date().getFullYear();

// Create the dynamic year string
const dynamicYearString = `${currentYear}-${nextYear}`;

// Display the result
return dynamicYearString;

}

// geoLocation

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(new Error('Error getting geolocation: ' + error.message));
        }
      );
    } else {
      reject(new Error('Geolocation is not supported by your browser'));
    }
  });
};

// logout 
export const logout = (type) => {
  switch (type) {
    case 'admin':
      localStorage.removeItem('adminPermissionToken');
      // localStorage.removeItem('userInfoAdmin');
      break;
    case 'instructor':
      localStorage.removeItem('instuctorInfo');
      localStorage.removeItem('permissionToken');
      break;
    default:
      localStorage.removeItem('userInfo');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('instuctorInfo');
      localStorage.removeItem('adminPermissionToken');
      break;
  }
}


export const exportToExcel = (data, fileName,type) => {
  let filteredData = [];
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if(userInfo) {
    switch (type) {
      case 'BRCActivityMonthly':
                // Create a new workbook and add the worksheet
                const workbookBRCActivityMonthly = utils.book_new();
                const brcActivityMonthlyData = data.map((item, key) => ({
                  'SrNo': key + 1,
                  "Division Name": item.udiseCodesData.division_name,
                  "District": item.udiseCodesData.district,
                  "UDISE Code": item.udiseCodesData.udise_code,
                  "School Name": item.udiseCodesData.school_name,
                  'Username': item.username,
                  'Date': item.createdAt,
                  'Link' : item.link
                }));
                const wsBRCActivityMonthly= utils.json_to_sheet(brcActivityMonthlyData);
        
                // Set column width based on the header length or values length, whichever is longer
                const columnWidthsBRCActivityMonthly = Object.keys(brcActivityMonthlyData[0]).reduce((acc, key) => {
                  const maxHeaderLength = key.length;
                  const maxValuesLength = Math.max(
                    ...brcActivityMonthlyData.map((row) => String(row[key]).length)
                  );
                  acc[key] = Math.max(maxHeaderLength, maxValuesLength);
                  return acc;
                }, {});
        
                wsBRCActivityMonthly['!cols'] = Object.keys(columnWidthsBRCActivityMonthly).map((key) => ({
                  wch: columnWidthsBRCActivityMonthly[key],
                }));
        
                utils.book_append_sheet(workbookBRCActivityMonthly, wsBRCActivityMonthly, 'Sheet 1');
                writeFile(workbookBRCActivityMonthly, fileName);
                break;
      case 'InstuctorUsers':
        // Create a new workbook and add the worksheet
        const workbookInstuctorUsers = utils.book_new();
        const instuctorUsersData = data.map((item, key) => ({
          'SrNo': key + 1,
          "Division Name": item.udiseData.division_name,
          "Division Head": item.udiseData.division_head,
          "District": item.udiseData.district,
          "Block Name": item.udiseData.block_name,
          "UDISE Code": item.udiseData.udise_code,
          "School Name": item.udiseData.school_name,
          "HM Name": item.udiseData.hm_name,
          "Mobile Number": item.udiseData.mobile_number,
          "Address": item.udiseData.address,
          "Pin Code": item.udiseData.pin_code,
          "Level":item.udiseData.level,
          'Username': item.username,
          'Status': item.status,
        }));
        const wsInstuctorUsers= utils.json_to_sheet(instuctorUsersData);

        // Set column width based on the header length or values length, whichever is longer
        const columnWidthsInstuctorUsers = Object.keys(instuctorUsersData[0]).reduce((acc, key) => {
          const maxHeaderLength = key.length;
          const maxValuesLength = Math.max(
            ...instuctorUsersData.map((row) => String(row[key]).length)
          );
          acc[key] = Math.max(maxHeaderLength, maxValuesLength);
          return acc;
        }, {});

        wsInstuctorUsers['!cols'] = Object.keys(columnWidthsInstuctorUsers).map((key) => ({
          wch: columnWidthsInstuctorUsers[key],
        }));

        utils.book_append_sheet(workbookInstuctorUsers, wsInstuctorUsers, 'Sheet 1');
        writeFile(workbookInstuctorUsers, fileName);
        break;
      case 'BRCActivity':
        // Create a new workbook and add the worksheet
        const workbookBRC = utils.book_new();
        const brcActivityData = data.map((item, key) => ({
          'SrNo': key + 1,
          'UDISE CODE': userInfo.udise_code,
          'Username': item.username,
          'Date': item.createdAt,
          'Day': item.day,
          'Activity Description': item.activityDescription,
          'Start Time': item.startTime,
          'End Time': item.endTime,
          'Evidence': item.evidenceLink,
          'Comment': item.comment,
        }));

        const wsBRCActivity = utils.json_to_sheet(brcActivityData);

        // Set column width based on the header length or values length, whichever is longer
        const columnWidthsBRCActivity = Object.keys(brcActivityData[0]).reduce((acc, key) => {
          const maxHeaderLength = key.length;
          const maxValuesLength = Math.max(
            ...brcActivityData.map((row) => String(row[key]).length)
          );
          acc[key] = Math.max(maxHeaderLength, maxValuesLength);
          return acc;
        }, {});

        wsBRCActivity['!cols'] = Object.keys(columnWidthsBRCActivity).map((key) => ({
          wch: columnWidthsBRCActivity[key],
        }));

        utils.book_append_sheet(workbookBRC, wsBRCActivity, 'Sheet 1');
        writeFile(workbookBRC, fileName);
        break;
      case 'InstuctorAttendance':
        const workbook = utils.book_new();
        const instructorAttendanceData = data.map((item, key) => ({
          'SrNo': key + 1,
          'Date': moment(item.punchInTime).format('DD-MMM YYYY'),
          'Division': item.udiseInfo.division_name,
          'District': item.udiseInfo.district,
          'UDISE Code': item.udiseInfo.udise_code,
          'School Name': item.udiseInfo.school_name,
          'Username': item.username,
          'Punch In Time': moment(item.punchInTime).format('hh:mm a'),
          'Punch Out Time': moment(item.punchOutTime).format('hh:mm a'),
          'Punch In Lat': item.punchInLat,
          'Punch In Long': item.punchInLong,
          'Punch Out Lat': item.punchOutLat,
          'Punch Out Long': item.punchOutLong,
        }));

        const wsInstructorAttendance = utils.json_to_sheet(instructorAttendanceData);

        // Set column width based on the header length or values length, whichever is longer
        const columnWidthsInstructorAttendance = Object.keys(instructorAttendanceData[0]).reduce(
          (acc, key) => {
            const maxHeaderLength = key.length;
            const maxValuesLength = Math.max(
              ...instructorAttendanceData.map((row) => String(row[key]).length)
            );
            acc[key] = Math.max(maxHeaderLength, maxValuesLength);
            return acc;
          },
          {}
        );

        wsInstructorAttendance['!cols'] = Object.keys(columnWidthsInstructorAttendance).map((key) => ({
          wch: columnWidthsInstructorAttendance[key],
        }));

        utils.book_append_sheet(workbook, wsInstructorAttendance, 'Instructor Attendance');
        writeFile(workbook, fileName);
        break;
      case 'SchoolReport':
      const workbookSchoolReport = utils.book_new();
      const schoolInfoData = data.map((school) => ({
        'School Name': school.school_name,
        'Division': school.division_name,
        'District': school.district,
        'Block Name': school.block_name,
        'UDISE Code': school.udise_code,
      }));

      const schoolInfoSheet = utils.json_to_sheet(schoolInfoData);

      // Set column width based on the header length or values length, whichever is longer
      const columnWidthsSchoolInfo = Object.keys(schoolInfoData[0]).reduce((acc, key) => {
        const maxHeaderLength = key.length;
        const maxValuesLength = Math.max(
          ...schoolInfoData.map((row) => String(row[key]).length)
        );
        acc[key] = Math.max(maxHeaderLength, maxValuesLength);
        return acc;
      }, {});

      schoolInfoSheet['!cols'] = Object.keys(columnWidthsSchoolInfo).map((key) => ({
        wch: columnWidthsSchoolInfo[key],
      }));

      utils.book_append_sheet(workbookSchoolReport, schoolInfoSheet, 'School Info');

      const studentData = data.reduce((allStudents, school) => {
        return allStudents.concat(
          school.studentDetails.map((student) => ({
            'UDISE Code': student.udiseCode,
            'School Name': school.school_name,
            'Student Name': `${student.firstName} ${student.lastName}`,
            'Class': student.class,
            'Division': student.divison,
            'Gender': student.gender,
            'Medium': student.medium,
            'Pretest Date':  student.pretest_date,
            'Pretest Total Marks':  student.pretest_totalMarks,
            'Pretest Marks Obtained':  student.pretest_marksObtained,
            'Formative Assessment 1 Date':  student.assessment1_date,
            'Formative Assessment 1 Total Marks':  student.assessment1_totalMarks,
            'Formative Assessment 1 Marks Obtained':  student.assessment1_marksObtained,
            'Formative Assessment 2 Date':  student.assessment2_date,
            'Formative Assessment 2 Total Marks':  student.assessment2_totalMarks,
            'Formative Assessment 2 Marks Obtained':  student.assessment2_marksObtained,
            'Formative Assessment 3 Date':  student.assessment3_date,
            'Formative Assessment 3 Total Marks':  student.assessment3_totalMarks,
            'Formative Assessment 3 Marks Obtained':  student.assessment3_marksObtained,
            'Formative Assessment 4 Date':  student.assessment4_date,
            'Formative Assessment 4 Total Marks':  student.assessment4_totalMarks,
            'Formative Assessment 4 Marks Obtained':  student.assessment4_marksObtained,
            'Summative Date':  student.summative_date,
            'Summative Total Marks':  student.summative_totalMarks,
            'Summative Marks Obtained':  student.summative_marksObtained,
            'Summative Coverage':  student.summative_coverage,
            // Add more fields as needed
          }))
        );
      }, []);

      const studentDataSheet = utils.json_to_sheet(studentData);

      // // Set column width based on the header length or values length, whichever is longer
      // const columnWidthsStudentData = Object.keys(studentData[0]).reduce((acc, key) => {
      //   const maxHeaderLength = key.length;
      //   const maxValuesLength = Math.max(
      //     ...studentData.map((row) => String(row[key]).length)
      //   );
      //   acc[key] = Math.max(maxHeaderLength, maxValuesLength);
      //   return acc;
      // }, {});

      // studentDataSheet['!cols'] = Object.keys(columnWidthsStudentData).map((key) => ({
      //   wch: columnWidthsStudentData[key],
      // }));

      utils.book_append_sheet(workbookSchoolReport, studentDataSheet, 'Student Data');
        // Save the workbook to a file
        writeFile(workbookSchoolReport, fileName);
        break;
      case 'InstuctorOldAttendance':
        // Extract unique dates from the attendance array
        const dates = data.reduce((acc, curr) => {
          curr.attendances.forEach(item => {
            if (!acc.includes(item.date)) {
              acc.push(item.date);
            }
          });
          return acc;
        }, []);
    
        // Create a new worksheet
        const worksheet = utils.aoa_to_sheet([['S.No', 'Compan Name', 'Division Name', 'District Name', 'Employee ID', 'Employee Name', 'Designation', 'UDISE Code', 'School Name', 'Trade', 'Reporting Manager name', 'Nature of Appointment', 'DOJ (As per the letter sign by HM)', 'Date of Leaving (If applicable)', 'Salary', 'Status', 'Present (P)', 'Leave Without Pay (A)', 'Leave (L)', 'Weekly Off (WO)', 'Holiday (H)', 'Maternity Leave (ML)', 'Total Attendance (Paid days)', 'Remarks (IF any)', ...dates]]);
    
        // Populate the worksheet with data
        data.forEach((item, index) => {
          const row = [];
          Object.keys(item).forEach(key => {
            if (key === 'attendances') {
              dates.forEach(date => {
                const attendance = item.attendances.find(a => a.date === date);
                row.push(attendance ? attendance.status : '');
              });
            } else {
              row.push(item[key]);
            }
          });
          utils.sheet_add_aoa(worksheet, [row], {origin: -1});
        });
    
        // Create a new workbook and add the worksheet
        const workbookAttendance = utils.book_new();
        utils.book_append_sheet(workbookAttendance, worksheet, 'Sheet1');
    
        // Write the workbook to a file
        writeFile(workbookAttendance, `${fileName}.xlsx`);
      break;
      case 'BRCActivityAdmin':
        // Create a new workbook and add the worksheet
        const workbookBRCAdmin = utils.book_new();
        const brcActivityDataAdmin = data.map((item, key) => ({
          'SrNo': key + 1,
          'Division': item.udiseCodesData.division_name,
          'District': item.udiseCodesData.district,
          'UDISE CODE': item.udiseCodesData.udise_code,
          'School Name': item.udiseCodesData.school_name,
          'Username': item.username,
          'Date': item.createdAt,
          'Day': item.day,
          'Activity Description': item.activityDescription,
          'Start Time': item.startTime,
          'End Time': item.endTime,
          'Evidence': item.evidenceLink,
          'Comment': item.comment,
        }));

        const wsBRCActivityAdmin = utils.json_to_sheet(brcActivityDataAdmin);

        // Set column width based on the header length or values length, whichever is longer
        const columnWidthsBRCActivityAdmin = Object.keys(brcActivityDataAdmin[0]).reduce((acc, key) => {
          const maxHeaderLength = key.length;
          const maxValuesLength = Math.max(
            ...brcActivityDataAdmin.map((row) => String(row[key]).length)
          );
          acc[key] = Math.max(maxHeaderLength, maxValuesLength);
          return acc;
        }, {});

        wsBRCActivityAdmin['!cols'] = Object.keys(columnWidthsBRCActivityAdmin).map((key) => ({
          wch: columnWidthsBRCActivityAdmin[key],
        }));

        utils.book_append_sheet(workbookBRCAdmin, wsBRCActivityAdmin, 'Sheet 1');
        writeFile(workbookBRCAdmin, fileName);
        break;
        case 'BRCAssets':
          const workbookBRCAssets = utils.book_new();
          const DataBRCAssets = data.map((item, key) => ({
            'SrNo': key + 1,
            'UDISE Code': item.udiseCode,
            'Division Name': item.divisionName,
            'Name Of The Equipment': item.nameOfTheEquipment,
            'Quantity': item.quantity,
            'Status': item.status,
            'Status': item.status,
            'Remarks': item.remarks,
            'Created At': item.createdAt,
          }));
  
          const wsBRCAssets = utils.json_to_sheet(DataBRCAssets);
  
          // Set column width based on the header length or values length, whichever is longer
          const columnWidthsBRCAssets = Object.keys(DataBRCAssets[0]).reduce(
            (acc, key) => {
              const maxHeaderLength = key.length;
              const maxValuesLength = Math.max(
                ...DataBRCAssets.map((row) => String(row[key]).length)
              );
              acc[key] = Math.max(maxHeaderLength, maxValuesLength);
              return acc;
            },
            {}
          );
  
          wsBRCAssets['!cols'] = Object.keys(columnWidthsBRCAssets).map((key) => ({
            wch: columnWidthsBRCAssets[key],
          }));
  
          utils.book_append_sheet(workbookBRCAssets, wsBRCAssets, 'BRC Assets');
          writeFile(workbookBRCAssets, fileName);
          break;
        case 'BRCSchool':
          const workbookBRCSchool = utils.book_new();
          const DataBRCSchool = data.map((item, key) => ({
            'SrNo': key + 1,
            'UDISE Code': item.udiseCode,
            'Division Name': item.divisionName,
            'Name Of The Equipment': item.nameOfTheEquipment,
            'Code': item.code,
            'Block': item.block,
            'HM Name': item.hmName,
            'HM Contact': item.hmContact,
            'Created At': item.createdAt,
          }));
  
          const wsBRCSchool = utils.json_to_sheet(DataBRCSchool);
  
          // Set column width based on the header length or values length, whichever is longer
          const columnWidthsBRCSchool = Object.keys(DataBRCSchool[0]).reduce(
            (acc, key) => {
              const maxHeaderLength = key.length;
              const maxValuesLength = Math.max(
                ...DataBRCSchool.map((row) => String(row[key]).length)
              );
              acc[key] = Math.max(maxHeaderLength, maxValuesLength);
              return acc;
            },
            {}
          );
  
          wsBRCSchool['!cols'] = Object.keys(columnWidthsBRCSchool).map((key) => ({
            wch: columnWidthsBRCSchool[key],
          }));
  
          utils.book_append_sheet(workbookBRCSchool, wsBRCSchool, 'BRC School');
          writeFile(workbookBRCSchool, fileName);
          break;
        case 'InstuctorSession':
          const workbookInstuctorSession = utils.book_new();
          const DataInstuctorSession = data.map((item, key) => ({
            'SrNo': key + 1,
            'UDISE Code': item.udiseCode,
            'Division Name': item.division,
            'District': item.udiseCodesData[0].district,
            'School Name': item.udiseCodesData[0].school_name,
            'Grade 6': item?.sessionPlansByGrade.grade6 ? `${item.sessionPlansByGrade.grade6.length}/96` : '0/96',
            'Grade 7':  item?.sessionPlansByGrade.grade7 ?`${item.sessionPlansByGrade.grade7.length}/96`  : '0/96',
            'Grade 8': item?.sessionPlansByGrade.grade8 ? `${item.sessionPlansByGrade.grade8.length}/96`  : '0/96',
            'Grade 9': item?.sessionPlansByGrade.grade9 ? `${item.sessionPlansByGrade.grade9.length}/96` : '0/96',
            'Grade 10': item?.sessionPlansByGrade.grade10 ? `${item.sessionPlansByGrade.grade10.length}/96` : '0/96',
            'Grade 11': item?.sessionPlansByGrade.grade11 ? `${item.sessionPlansByGrade.grade11.length}/96` : '0/96',
            'Grade 12': item?.sessionPlansByGrade.grade12 ? `${item.sessionPlansByGrade.grade12.length}/96` : '0/96',
          }));
  
          const wsInstuctorSession = utils.json_to_sheet(DataInstuctorSession);
  
          // Set column width based on the header length or values length, whichever is longer
          const columnWidthsInstuctorSession = Object.keys(DataInstuctorSession[0]).reduce(
            (acc, key) => {
              const maxHeaderLength = key.length;
              const maxValuesLength = Math.max(
                ...DataInstuctorSession.map((row) => String(row[key]).length)
              );
              acc[key] = Math.max(maxHeaderLength, maxValuesLength);
              return acc;
            },
            {}
          );
  
          wsInstuctorSession['!cols'] = Object.keys(columnWidthsInstuctorSession).map((key) => ({
            wch: columnWidthsInstuctorSession[key],
          }));
  
          utils.book_append_sheet(workbookInstuctorSession, wsInstuctorSession, 'Instuctor Session');
          writeFile(workbookInstuctorSession, fileName);
          break;
        case 'InstuctorContent':
            // Create a new workbook
            const workbookInstuctorContent = utils.book_new();

            // Prepare data for the worksheet
            const worksheetData = data.map(item => [item.createdAt,item.udiseInfo.division_name,item.udiseInfo.district,item.udiseInfo.udise_code,item.udiseInfo.school_name,item.username,item.link]);

            // Convert the data array to a worksheet
            const worksheetInstuctorContent = utils.aoa_to_sheet([['Date','Division','District','UDISE Code','School Name','Username', 'Link'], ...worksheetData]);

            // Add the worksheet to the workbook
            utils.book_append_sheet(workbookInstuctorContent, worksheetInstuctorContent, 'Instuctor Content');

            // Write the workbook to a file
            writeFile(workbookInstuctorContent, fileName);
          break;

        case 'HeadDetails':
          const workbookHeadDetails = utils.book_new();
          const DataHeadDetails = data.map((item, key) => ({
            'SrNo': key + 1,
            'UDISE Code': item.udiseCode,
            'Division Name': item.divisionName,
            'First Name': item.firstName,
            'Last Name': item.lastName,
            'Designation': item.destination,
            'Phone Number': item.phoneNumber,
            'Email': item.email,
            'Comment': item.comment,
          }));
  
          const wsHeadDetails = utils.json_to_sheet(DataHeadDetails);
  
          // Set column width based on the header length or values length, whichever is longer
          const columnWidthsHeadDetails = Object.keys(DataHeadDetails[0]).reduce(
            (acc, key) => {
              const maxHeaderLength = key.length;
              const maxValuesLength = Math.max(
                ...DataHeadDetails.map((row) => String(row[key]).length)
              );
              acc[key] = Math.max(maxHeaderLength, maxValuesLength);
              return acc;
            },
            {}
          );
  
          wsHeadDetails['!cols'] = Object.keys(columnWidthsHeadDetails).map((key) => ({
            wch: columnWidthsHeadDetails[key],
          }));
  
          utils.book_append_sheet(workbookHeadDetails, wsHeadDetails, 'Head Details');
          writeFile(workbookHeadDetails, fileName);
          break;
        case 'SchoolUDISEReport':
          const workbookSchoolUDISEReport = utils.book_new();
          const DataSchoolUDISEReport = data.map((item, key) => ({
            'SrNo': key + 1,
            'Status': item.formDetails.status,
            'UDISE Code': item.udise_code,
            'School Name': item.school_name,
            'Division': item.division_name,
            'Block': item.block_name,
            'District': item.district,
            'Division Head': item.division_head,
            'HM Name': item.hm_name,
            'Mobile Number': item.mobile_number,
            'Address': item.address,
            'Pin Code': item.pin_code,
            'Level': item.level,
            'School Name in Form Details': item.formDetails.schoolName,
            'District in Form Details': item.formDetails.nameOfTheDistrict,
            'UDISE Block in Form Details': item.formDetails.nameOfTheUDISEBlock,
            'School Location Type': item.formDetails.schoolLocationType,
            'School Address in Form Details': item.formDetails.schoolAddress,
            'Pin Code in Form Details': item.formDetails.pinCode,
            'Name of the Cluster Resource Centre': item.formDetails.nameOfTheClusterResourceCentre,
            'School Contact STD Code': item.formDetails.schoolContactDetails.stdCode,
            'School Contact Landline Number': item.formDetails.schoolContactDetails.landlineNumber,
            'School Contact Mobile Number': item.formDetails.schoolContactDetails.mobileNumber,
            'School Contact Email': item.formDetails.schoolContactDetails.email,
            'School Contact Website': item.formDetails.schoolContactDetails.websiteOfTheSchool,
            'Incharge Type': item.formDetails.headOfTheSchoolInchargeDetails.inchargeType,
            'Incharge Name': item.formDetails.headOfTheSchoolInchargeDetails.inchargeName,
            'Incharge Mobile Number': item.formDetails.headOfTheSchoolInchargeDetails.mobileNumber,
            'Incharge Email': item.formDetails.headOfTheSchoolInchargeDetails.hosEmail,
            'School Category Code': item.formDetails.schoolCategoryCode,
            'Lowest Class in School': item.formDetails.lowestClassInSchool,
            'Highest Class in School': item.formDetails.highestClassInSchool,
            'Type of the School': item.formDetails.typeOfTheSchool,
            'Affiliation Board of the School for Secondary Sections': item.formDetails.affiliationBoardOfTheSchoolForSecondarySections,
            'Affiliation Board of the School for Higher Secondary Sections': item.formDetails.affiliationBoardOfTheSchoolForHigherScondarySections,
            'Respondent Type': item.formDetails.respondentDetails.respondentType,
            'Respondent Name': item.formDetails.respondentDetails.respondentName,
            'Respondent Mobile Number': item.formDetails.respondentDetails.mobileNumber,
            'Respondent Email': item.formDetails.respondentDetails.respondentEmail,
            'Academic Session Start Date': item.formDetails.academicSessionStartDate,
            'Academic Session End Date': item.formDetails.academicSessionStartEnd,
            'Medium of Instructions in the School - Medium 1': item.formDetails.mediumOfInstructionsInTheSchool.medium1,
            'Medium of Instructions in the School - Medium 2': item.formDetails.mediumOfInstructionsInTheSchool.medium2,
            'Medium of Instructions in the School - Medium 3': item.formDetails.mediumOfInstructionsInTheSchool.medium3,
            'Medium of Instructions in the School - Medium 4': item.formDetails.mediumOfInstructionsInTheSchool.medium4,
            'Is this a Special School for CWSN?': item.formDetails.isThisASpecialSchoolForCWSN.isCWSN,
            'Type of Special School': item.formDetails.isThisASpecialSchoolForCWSN.typeOfSpecialSchool,
            'Mother Tongue': item.formDetails.motherTongue,
            'Teachers Attendance Captured': item.formDetails.teachersAttendanceCaptured,
            'Inventory Register on ICT Items': item.formDetails.inventoryRegisterOnICTItems,
            'Number of Including Computer Labs': item.formDetails.numberOfIncludingComputerLabs,
            'Equipment Facility': item.formDetails.equipmentFacility,
            'Items Desktop Total': item.formDetails.itemsDesktopTotal,
            'Items Desktop Out of Total': item.formDetails.itemsDesktopOutOfTotal,
            'Items Laptop Total': item.formDetails.itemsLaptopTotal,
            'Items Laptop Out of Total': item.formDetails.itemsLaptopOutOfTotal,
            'Items Tablets Total': item.formDetails.itemsTabletsTotal,
            'Items Tablets Out of Total': item.formDetails.itemsTabletsOutOfTotal,
            'Items PC with Learning Devices Total': item.formDetails.itemsPCWithLearningDevicesTotal,
            'Items PC with Learning Devices Out of Total': item.formDetails.itemsPCWithLearningDevicesOutOfTotal,
            'Items CMS Total': item.formDetails.itemsCMSTotal,
            'Items CMS Out of Total': item.formDetails.itemsCMSOutOfTotal,
            'Items Projector Total': item.formDetails.itemsProjectorTotal,
            'Items Projector Out of Total': item.formDetails.itemsProjectorOutOfTotal,
            'Items Printer Total': item.formDetails.itemsPrinterTotal,
            'Items Printer Out of Total': item.formDetails.itemsPrinterOutOfTotal,
            'Items Scanner Total': item.formDetails.itemsScannerTotal,
            'Items Scanner Out of Total': item.formDetails.itemsScannerOutOfTotal,
            'Items Server Total': item.formDetails.itemsServerTotal,
            'Items Server Out of Total': item.formDetails.itemsServerOutOfTotal,
            'Items Web Camera Total': item.formDetails.itemsWebCameraTotal,
            'Items Web Camera Out of Total': item.formDetails.itemsWebCameraOutOfTotal,
            'Items Smart TV Total': item.formDetails.itemsSmartTVTotal,
            'Items Smart TV Out of Total': item.formDetails.itemsSmartTVOutOfTotal,
            'Items Smart Classroom Total': item.formDetails.itemsSmartClassroomTotal,
            'Items Smart Classroom Out of Total': item.formDetails.itemsSmartClassroomOutOfTotal,
            'Items Mobile Total': item.formDetails.itemsMobileTotal,
            'Items Mobile Out of Total': item.formDetails.itemsMobileOutOfTotal,
            'Items Power Backup Total': item.formDetails.itemsPowerBackupTotal,
            'Items Power Backup Out of Total': item.formDetails.itemsPowerBackupOutOfTotal,
            'Internet Facility': item.formDetails.internetFacility,
            'Type of Internet Facility in the School': item.formDetails.typeOfInternetFacilityInTheSchool,
            'Facility ICT Tools for Teaching': item.formDetails.facilityICTToolsForTeaching,
            'Facility Access to E-Content': item.formDetails.facilityAccessToEContent,
            'Facility Tech Based CWSN': item.formDetails.facilityTechBasedCWSN,
            'Facility Access to DTH': item.formDetails.facilityAccessToDTH,
            'ICT Lab Available in School': item.formDetails.ictLabAvailableInSchool,
            'ICT Lab Available in School under Samagra Siksha': item.formDetails.ictLabAvailableInSchoolUnderSamagraSiksha,
            'Number of ICT Labs Available': item.formDetails.numberOfICTLabsAvailable,
            'Year of Implementation': item.formDetails.yearOfImplementation,
            'Whether the ICT Lab is Functional or Not': item.formDetails.whetherTheICTLabIsFunctionalOrNot,
            'Which Model is Implemented in the School': item.formDetails.whichModelIsImplementedInTheSchool,
            'Type of the ICT Instructor in the School': item.formDetails.typeOfTheICTInstructorInTheSchool,
            'Does the School Have Digital Library': item.formDetails.doesTheSchoolHaveDigitalLibrary,
            'Number of E-Books Contents Available': item.formDetails.numberOfEBooksContentsavailable,
            'Trained for Teaching CWSN': item.formDetails.trainedForTeachingCWSN,
            'Trained in Computer': item.formDetails.trainedInComputer,
            'Training Received': item.formDetails.trainingReceived,
            'Training Needs': item.formDetails.trainingNeeds,
            'Training Working Days': item.formDetails.trainingWorkingDays,
            'Training Languages Knowlegde': item.formDetails.trainingLanguagesKnowlegde,
            'Training Language 1': item.formDetails.trainingLanguage1,
            'Training Language 2': item.formDetails.trainingLanguage2,
            'Training Language 3': item.formDetails.trainingLanguage3,
            'Training Child Safety': item.formDetails.trainingChildSafety,
            'Training Received on Cyber Safety': item.formDetails.trainingReceivedOnCyberSafety,
            'Training Received Identification Support CWSN': item.formDetails.trainingReceivedIdentificationSupportCWSN,
            'Training Remote Classes': item.formDetails.trainingRemoteClasses,
            'Training Capable Handling Digital Devices': item.formDetails.trainingCapableHandlingDigitalDevices,
          }));
  
          const wsSchoolUDISEReport = utils.json_to_sheet(DataSchoolUDISEReport);
  
          // Set column width based on the header length or values length, whichever is longer
          const columnWidthsSchoolUDISEReport = Object.keys(DataSchoolUDISEReport[0]).reduce(
            (acc, key) => {
              const maxHeaderLength = key.length;
              const maxValuesLength = Math.max(
                ...DataSchoolUDISEReport.map((row) => String(row[key]).length)
              );
              acc[key] = Math.max(maxHeaderLength, maxValuesLength);
              return acc;
            },
            {}
          );
  
          wsSchoolUDISEReport['!cols'] = Object.keys(columnWidthsSchoolUDISEReport).map((key) => ({
            wch: columnWidthsSchoolUDISEReport[key],
          }));
  
          utils.book_append_sheet(workbookSchoolUDISEReport, wsSchoolUDISEReport, 'School UDISE Report');
          writeFile(workbookSchoolUDISEReport, fileName);
          break;
      default:
        break;
    }
  }

};

export const removeChildrenWithEmptyLink = (arrayOfObjects) => {
  if (!Array.isArray(arrayOfObjects)) {
    return arrayOfObjects;
}

return arrayOfObjects.map(obj => {
    if (obj.children && Array.isArray(obj.children)) {
        obj.children = obj.children.filter(child => child.link !== "");
    }
    return obj;
});
}

export const removeDuplicateWeek = (data) => {
  let processedData = [];

  for(let i=0;i<data.length;i++){
    if (i !== 0 && data[i].week === data[i - 1].week) {;
        processedData.push({...data[i]})
    } else {
      processedData.push({...data[i],rowSpan : 3})
    }
  }
  return processedData;
}

export const filterSessionPlansByStatus = (data) => {
    // Create an object to store final output
    let finalOutput = {};

    // Iterate through each record in the data array
    data.forEach(record => {
        // Extract relevant information from the record
        const udiseCode = record.udiseCode;
        const grade = "grade" + record.grade;

        // Initialize the final output object for this udiseCode if it doesn't exist
        if (!finalOutput[udiseCode]) {
            finalOutput[udiseCode] = {
                count : record.count,
                udiseCode: udiseCode,
                division: record.division,
                udiseCodesData: record.udiseCodesData,
                sessionPlansByGrade: {}
            };
        }

        // Initialize the session plans array for this grade if it doesn't exist
        if (!finalOutput[udiseCode].sessionPlansByGrade[grade]) {
            finalOutput[udiseCode].sessionPlansByGrade[grade] = [];
        }

        // Filter session plans by status and push to the corresponding grade array
        const filteredSessionPlans = record.sessionPlans.filter(plan => plan.status);
        finalOutput[udiseCode].sessionPlansByGrade[grade].push(...filteredSessionPlans);
    });
    let index = 0;
    // Ensure that each grade has an array even if it's empty
    for (const udiseCode in finalOutput) {
      const grades = finalOutput[udiseCode].sessionPlansByGrade;
      for (let i = 6; i <= 12; i++) {
          const gradeKey = "grade" + i;
          if (!grades[gradeKey]) {
              grades[gradeKey] = [];
          }
      }
          // Increment index for each iteration
        index++;

        // Inserting count key with index into finalOutput
        finalOutput[udiseCode].count = index;
  }
    // Convert the finalOutput object into an array of final JSON objects
    return Object.values(finalOutput);
}

export const assignMarksBasedOnGrades = (grade) => {
  var marks = {
    pretest_totalMarks: 0,
    assessment1_totalMarks: 0,
    assessment2_totalMarks: 0,
    assessment3_totalMarks: 0,
    assessment4_totalMarks: 0,
    summative_totalMarks: 0
  };
  switch (grade) {
    case '6':
      marks.pretest_totalMarks = 10;
      marks.assessment1_totalMarks = 25;
      marks.assessment2_totalMarks = 25;
      marks.assessment3_totalMarks = 25;
      marks.assessment4_totalMarks = 25;
      marks.summative_totalMarks = 50;
      break;
    case '7':
      marks.pretest_totalMarks = 10;
      marks.assessment1_totalMarks = 25;
      marks.assessment2_totalMarks = 25;
      marks.assessment3_totalMarks = 25;
      marks.assessment4_totalMarks = 25;
      marks.summative_totalMarks = 50;
      break;
    case '8':
      marks.pretest_totalMarks = 10;
      marks.assessment1_totalMarks = 25;
      marks.assessment2_totalMarks = 25;
      marks.assessment3_totalMarks = 25;
      marks.assessment4_totalMarks = 25;
      marks.summative_totalMarks = 50;
      break;
    case '9':
      marks.pretest_totalMarks = 15;
      marks.assessment1_totalMarks = 25;
      marks.assessment2_totalMarks = 50;
      marks.assessment3_totalMarks = 25;
      marks.assessment4_totalMarks = 50;
      marks.summative_totalMarks = 100;
      break;
    case '10':
      marks.pretest_totalMarks = 15;
      marks.assessment1_totalMarks = 25;
      marks.assessment2_totalMarks = 50;
      marks.assessment3_totalMarks = 25;
      marks.assessment4_totalMarks = 50;
      marks.summative_totalMarks = 100;
      break;
    case '11':
      marks.pretest_totalMarks = 15;
      marks.assessment1_totalMarks = 50;
      marks.assessment2_totalMarks = 50;
      marks.assessment3_totalMarks = 50;
      marks.assessment4_totalMarks = 50;
      marks.summative_totalMarks = 100;
      break;
    case '12':
      marks.pretest_totalMarks = 15;
      marks.assessment1_totalMarks = 50;
      marks.assessment2_totalMarks = 50;
      marks.assessment3_totalMarks = 50;
      marks.assessment4_totalMarks = 50;
      marks.summative_totalMarks = 100;
      break;
    default:
      break;
  }
  return marks;
};


export const setCookie =  (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}