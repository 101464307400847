import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./component/login/login";
import Home from './component/home/home';
import './App.css';
import InstructerPage from "./component/instructor/instructorPage";
import AdminPage from "./component/admin/adminPage";
import AdminLogin from "./component/login/admin";
import FormPage from "./component/form/formPage";
import TypeChoose from "./component/choose/typeChoose";
import StudentPage from "./component/student/studentData";
import InstructorData from "./component/instructor/instructorData";
import SchoolAttendance from "./component/school/schoolAttendance";
import BRCProfile from "./component/brc/BRCProfile";
import BRCForm from "./component/brc/BRCForm";
import 'rsuite/dist/rsuite.min.css';
import { isMobile } from 'react-device-detect';
if (isMobile) {
  // Redirect to the mobile site
  window.open("https://m.ict-trainingmaharashtra.co.in/","_self")
}
function App() {
  return (
    <>
    <Routes>
      <Route path="*" exact element={< Login />}></Route>
      <Route path="/home" exact element={< Home />}></Route>
      <Route path="/instructor" exact element={< InstructerPage />}></Route>
      <Route path="/instructor/choose" exact element={< TypeChoose />}></Route>
      <Route path="/admin" exact element={< AdminPage />}></Route>
      <Route path="/admin/login" exact element={< AdminLogin />}></Route>
      <Route path="/instructor/form" exact element={< FormPage />}></Route>
      <Route path="/instructor/students" exact element={<StudentPage />}></Route>
      <Route path="/instructor/data" exact element={<InstructorData />}></Route>
      <Route path="/instructor/school/attendance" exact element={<SchoolAttendance />}></Route>
      <Route path="/brc/profile" exact element={<BRCProfile />}></Route>
      <Route path="/brc/form" exact element={<BRCForm />}></Route>
    </Routes>
    </>

  );
}

export default App;
