import React , {useEffect, useRef} from 'react';
import { Modal, Button,Form, Schema } from 'rsuite';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import { adminAdd } from '../../services/services';
// Notifications
import { Message, useToaster,Checkbox, CheckboxGroup } from 'rsuite';

const { StringType } = Schema.Types;

const data = ['Amravati', 'Aurangabad', 'Kokan', 'Nagpur','Nashik','Pune'];

const model = Schema.Model({
    username: StringType().isRequired('This field is required.'),
    password: StringType().isRequired('This field is required.'),
});

function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

const AddNewAdmin = ({onFetch,loading}) => {
  const toaster = useToaster();
  const pushNotification = (type,header,message) => {
      toaster.push(<Message showIcon type={type} header={header}>{message}</Message>, {
        placement : 'topEnd',
        duration: 3000
      })
    }
  const formRef = useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    username: '',
    password: '',
    divisions : []
  });
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const [value, setValue] = React.useState([]);

  const handleCheckAll = (value, checked) => setValue(checked ? data : []);
  const handleChange = value => setValue(value);

  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const reset = () => {
    setFormValue({
      username: '',
      password: '',
      divisions : []
    });
    setValue([]);
  }
  const handleAddAdmin= async () => {
    if (!formRef.current.check()) {
      console.error('Form Error',formError);
      return;
    } else {
      try {
        let response = await adminAdd(formValue);
        if(response.data.status) {
        onFetch();
        pushNotification('success','Created',response.data.message)
          setOpen(false);
          reset();
        } else {
            pushNotification('warning','Note',response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    }

  }
  useEffect(()=>{
    setFormValue({...formValue,divisions : value})
  },[value])
  return (
    <>
        <Button disabled={loading} style={{width : '150px'}} color="green" size="md" appearance="primary" onClick={() => handleOpen('md')} endIcon={<UserInfoIcon />}>
        Add New Admin
        </Button>
      <hr />
      <Modal backdrop={'static'} size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Add New Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  
          model={model}           
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}>
            <TextField name="username" label="Admin Username" />
            <TextField name="password" label="Admin Password" />
            <p>Assign Division</p>
            <div>
      <Checkbox
        indeterminate={value.length > 0 && value.length < data.length}
        checked={value.length === data.length}
        onChange={handleCheckAll}
      >
        All Division
      </Checkbox>
      <hr />
      <CheckboxGroup inline name="checkboxList" value={value} onChange={handleChange}>
        {data.map(item => (
          <Checkbox key={item} value={item}>
            {item}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </div>
            <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button disabled={!value.length} type="submit" appearance="primary" onClick={handleAddAdmin}>
            Ok
          </Button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default AddNewAdmin;