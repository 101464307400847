import React, { useEffect, useState } from 'react'
import { TableData } from '../table/ReactTable';
import { Link ,useNavigate } from 'react-router-dom';
import style from '../home/home.module.css';
// static
import JSON_DATA from '../../data/static.json'
import Profile from '../profile/Profile';
export default function Home() {
  //  Sort Function

  const navigate   = useNavigate();
  const [userInfo,setUserInfo] = useState({
      username : "",
      division_name: "",
      district: "",
      udise_code: "",
      school_name: "",
  });
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.serial;
    const b = rowB.serial;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const data = JSON_DATA.eContentData;

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userInfo"))

     if(!userData) {
      navigate("/")
      return
    } else {
      setUserInfo(userData)
    }
  }, [])




  return (
    <>
      <div className={style.form_table}>
        <div className={style.home_sec}>
          <div className="container">
            <div className={style.header}>
              <div className={style.image}>
                <img src='../assets/images/MPSP_logo.png' alt='' className='img-fluid' />
              </div>
              <div className='d-flex align-items-center'>
              <div>
              <Link to='/instructor' className={`${style.btn} btn`} style={{textDecorationLine : 'none'}}>ICT Instructor Login</Link>
              <Link to='/admin/login' className={`${style.btn} btn`} style={{textDecorationLine : 'none'}}>ICT Admin Login</Link>
              {/* <Link to='/' className={`${style.btn} btn`} onClick={()=>logout()}>Logout</Link> */}
              </div>
              {/* <Avatar circle className='user-ava' src="https://avatars.githubusercontent.com/u/12592949" alt="@superman66" /> */}
              <Profile logoutType={""} linkTo={"/"} username={userInfo.udise_code} udise_code={userInfo.udise_code} division_name={userInfo.division_name} district={userInfo.district} school_name={userInfo.school_name} />
              </div>
            </div>
          </div>
        </div>

        <div className={style.table_body}>
          <div className="container">
            <TableData data={data} />
          </div>
        </div>
      </div>
    </>
  )
}
