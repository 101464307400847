import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate,Link } from 'react-router-dom';
import style from '../home/home.module.css';
import { AdminSessionPlans, AdminTable, InstuctorTableData, TableData, TraningMaterial } from '../table/ReactTable';
import { PieChart } from '../chart/pieChart';
import { LineChart } from '../chart/lineChart';
import { Divider, Loader,Dropdown, Panel,Sidenav, Nav } from 'rsuite';
import { getChartData } from '../../services/services';
import GroupIcon from '@rsuite/icons/legacy/Group';
import MemberIcon from '@rsuite/icons/Member';
import TmallIcon from '@rsuite/icons/Tmall';
import ShieldIcon from '@rsuite/icons/Shield';
import PageIcon from '@rsuite/icons/Page';
import TaskIcon from '@rsuite/icons/Task';
import STATIC_JSON from '../../data/static.json';
import Profile from '../profile/Profile';
const styles = {
  width: 240,
  display: 'inline-table',
  marginRight: 10,
  borderRadius : 10
};


function AdminPage  () {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const childRef = useRef(null);
  const [loading,setLoading] = useState(false);
  const [schoolData,setSchoolData] = useState([]);
  const [instuctorData,setInstuctorData] = useState([]);
  const [selectedValue,setSelectedValue] = useState('');
  const [selectContent,setSelectContent] = useState('');
  const [divisions,setDivisions] = useState([]);
  const [selectedMenu,setSelectedMenu] = useState(query.get('isSuperAdmin') === 'true' ? 'Admin' : 'E-Content')
  const [openKeys, setOpenKeys] = useState(['1']);
  const [expanded, setExpand] = useState(true);
  const [instuctorInfo,setInstuctorInfo] = useState({
    username : '',
    });
  const [schoolPie,setSchoolPie] = useState({
    totalSchools: 0,
    activeSchools: 0,
    inActiveSchools: 0
  });
  const [udiseFormPie,setUdiseFormPie] = useState({
    totalForm: 0,
    completedForm: 0,
    inProgressForm: 0
  });
  const fetchChartData = async (sort) => {
    try {
      setLoading(true);
      const response = await getChartData(sort);
      if(response.data.status) {
        setSchoolPie(response.data.data.schoolPie);
        setUdiseFormPie(response.data.data.UDISEFormPie);
        setSchoolData(response.data.data.schoolDailyReport);
        setInstuctorData(response.data.data.instuctorDailyReport);
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleDropDown = (e) => {
      setSelectedValue(e);
      fetchChartData(e);
      localStorage.setItem('selectedValue',e)   
      setSelectContent('');   
  }
  const handleMenu = (e) => {
    setSelectedMenu(e);
    setSelectContent('');
  }
  const handleContent = (e) => {
    setSelectContent(e);
  }
  const CustomSidenav = ({ appearance, openKeys, expanded, onOpenChange, onExpand, ...navProps }) => {
    return (
      <div style={styles}>
        <Sidenav
          appearance={appearance}
          expanded={expanded}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          aria-disabled={loading}
        >
          <Sidenav.Body>
            <Nav {...navProps}>
             {query.get('isSuperAdmin') === 'true' ? <Nav.Item eventKey="Admin" icon={<GroupIcon />}>
                Admin
              </Nav.Item> : ''}
              <Nav.Item eventKey="E-Content" icon={<PageIcon />}>E-Content</Nav.Item>
              <Nav.Item eventKey="Instuctor Session" icon={<TaskIcon />}>Session Plans Progress Report</Nav.Item>
              <Nav.Menu eventKey="2" title="Instructor" icon={<MemberIcon />}>
              {query.get('isSuperAdmin') === 'true' ? <Nav.Item eventKey="Instuctor Users">Manage</Nav.Item> : ''}
                <Nav.Item eventKey="Instuctor Attendance">Attendance</Nav.Item>
                {/* <Nav.Item eventKey="Instuctor OldAttendance">Old Attendance</Nav.Item> */}
                {/* <Nav.Item eventKey="Instuctor Session">Session Plans</Nav.Item> */}
                <Nav.Item eventKey="Instuctor Content">Ground Exercise</Nav.Item>
              </Nav.Menu>
              <Nav.Menu eventKey="3" title="School" icon={<TmallIcon />}>
                <Nav.Item eventKey="School Report">Report</Nav.Item>
                <Nav.Item eventKey="Progress Report">Progress</Nav.Item>
              </Nav.Menu>
              <Nav.Menu eventKey="4" title="BRC" icon={<ShieldIcon />}>
                <Nav.Item eventKey="Activity Logs">Activity Logs</Nav.Item>
                <Nav.Item eventKey="Head Details">BRC Administration Details</Nav.Item>
                <Nav.Item eventKey="ICT Lab Asset Report">ICT Lab Asset Report</Nav.Item>
              </Nav.Menu>
            </Nav>
          </Sidenav.Body>
          <Sidenav.Toggle onToggle={onExpand} />
        </Sidenav>
      </div>
    );
  };

  useEffect(()=>{
    let adminPermissionToken = localStorage.getItem('adminPermissionToken');
    if(!adminPermissionToken) {
      navigate("/home")
    }
    let admin = JSON.parse(localStorage.getItem('userInfoAdmin'));
    if(admin.divisionAccess) {
      setInstuctorInfo(admin)
      if(!admin.divisionAccess.length){
        fetchChartData('');
        localStorage.setItem('selectedValue','superadmin')
      } else {
        fetchChartData(query.get('isSuperAdmin') === 'true' ? '' : admin.divisionAccess[0].value);
        if(selectedValue.length){
          localStorage.setItem('selectedValue',selectedValue)
        } else {
          if(query.get('isSuperAdmin') === 'true'){
            localStorage.setItem('selectedValue','');
          } else{
            localStorage.setItem('selectedValue',admin.divisionAccess[0].value);
          }
          // setSelectedValue(admin.divisionAccess[0].value);
        }
        setDivisions(admin.divisionAccess);
      }
    } 
    
  },[])
  return     <>
  <div className={style.form_table}>
    <div className={style.home_sec}>
      <div className="container">
        <div className={style.header}>
          <div className={style.image}>
            <img src='../assets/images/MPSP_logo.png' alt='' className='img-fluid' onClick={()=>{
              navigate('/home')
            }} />
          </div>
          <div className='d-flex'>
          {/* <Link to='/admin/login' className={`${style.btn} btn`} onClick={()=>logout('admin')}>Logout</Link> */}
          <Profile 
          logoutType={"admin"}
          linkTo={"/admin/login"}
          username={query.get('isSuperAdmin') === 'true' ? 'Super Admin' : instuctorInfo.username}
          divisionAccess={divisions}
            />
          </div>
        </div>
      </div>
    </div>

    <div className={style.table_body}>
      <div className="container">
        <div className='d-flex'>
      <CustomSidenav
        activeKey={selectedMenu}
        openKeys={openKeys}
        onSelect={handleMenu}
        onOpenChange={setOpenKeys}
        expanded={expanded}
        onExpand={setExpand}
        appearance="subtle"
      />
      <div className='w-100 d-flex flex-column'>
        {selectedMenu === 'Admin' ? '' : <Dropdown className='mb-2' appearance='primary' color='blue' title={selectedValue.length ?  selectedValue : query.get('isSuperAdmin') === 'true' ? 'All Division' : 'Select Division'} onSelect={handleDropDown}>
        {query.get('isSuperAdmin') === 'true' ? <Dropdown.Item active={selectedValue === ''} eventKey='' >All Division</Dropdown.Item> : ''}
        {divisions.map((item)=>{
          return  <Dropdown.Item active={selectedValue === item.value} eventKey={item.value}>{item.value}</Dropdown.Item>
        })}
    </Dropdown> }
      
    {selectedMenu === "E-Content" ? <Dropdown style={{marginLeft : '10px'}} className='mb-2' appearance='primary' color='green' title={selectContent === '' ? 'Select Type' : selectContent} onSelect={handleContent}>
      <Dropdown.Item eventKey={'Text Books'}>Text Books</Dropdown.Item>
      <Dropdown.Item eventKey={'Lesson Plans'}>Lesson Plans</Dropdown.Item>
      <Dropdown.Item eventKey={'Session Plans'}>Session Plans</Dropdown.Item>
      <Dropdown.Item eventKey={'Teacher Traning Material'}>Teacher Traning Material</Dropdown.Item>
    </Dropdown> : ""}
    <Divider />
    {selectContent === 'Text Books' ? <TableData data={STATIC_JSON.eContentDataAdmin} /> : selectContent === 'Lesson Plans' ?
    <InstuctorTableData data={STATIC_JSON.lessonPlan} /> : selectContent === 'Session Plans' ? <AdminSessionPlans selectedValue={selectedValue} /> : selectContent === 'Teacher Traning Material' ? <TraningMaterial /> : ''}
        {loading ? <Loader className={selectedMenu === 'School Report' || selectedMenu === 'Instuctor Attendance'  ? 'd-flex justify-content-center' : 'd-none'} size="md" content="Loading..." /> : <div className={selectedMenu === 'School Report' ? 'chart-flex' : 'd-none'}>
        <PieChart data={[
        { asset: 'Pending', amount: udiseFormPie.totalForm },
        { asset: 'In-Progress', amount: udiseFormPie.inProgressForm },
        { asset: 'Completed', amount: udiseFormPie.completedForm },
    ]} title={"UDISE Form Status"} />
        <LineChart data={schoolData} title={"School Daily Login Report"} xKey={"date"} yKey={"schools"} yName={"School"} fill={"green"} />
        </div>}
        {loading ? '' : <div className={selectedMenu === 'Instuctor Attendance' ? 'd-flex justify-content-center' : 'd-none'}>
        {/* <PieChart data={[
        { asset: 'Not-Started', amount: schoolPie.totalSchools },
        { asset: 'In-Active', amount: schoolPie.inActiveSchools },
        { asset: 'Active', amount: schoolPie.activeSchools },
    ]} title={"Platform Usage Started"} /> */}
      <Panel header="Note" shaded>
      <p>Hover over the graph to view details</p>
      <hr />
      <p>1. Hover over different parts of the graph to see specific data points.</p>
      <p>2. Explore different time periods by adjusting the time range selector.</p>
  </Panel>
     <LineChart data={instuctorData} title={"Instructor's Daily Login Report"} xKey={"date"} yKey={"instructor"} yName={"Instructor"} fill={"#fa8900"} />
        </div>}
        {/* Session */}
        {/* {loading ? <Loader className='d-flex justify-content-center' size="md" content="Loading..." /> : <div className={selectedMenu === 'Instuctor Session' ? 'chart-flex' : 'd-none'}>
        <PieChart data={[
        { asset: 'Not-Started', amount: schoolPie.totalSchools },
        { asset: 'In-Active', amount: schoolPie.inActiveSchools },
        { asset: 'Active', amount: schoolPie.activeSchools },
    ]} title={"Session Plans Used By Schools"} />
        <LineChart data={schoolData} title={"Session Plans Daily Activity"} xKey={"date"} yKey={"schools"} yName={"School"} fill={"green"} />
        </div>} */}
        {/* <Divider className={selectedMenu === 'Instuctor Attendance' || selectedMenu === 'Instuctor Session' ? '' : 'd-none'} /> */}
        <AdminTable ref={childRef} selectedTab={selectedMenu} selectedValue={selectedValue} />
        </div>
        </div>
      </div>
    </div>
  </div>
</>
}

export default AdminPage;