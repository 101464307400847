import { logout } from '../../utils/utils';
import { Avatar,Nav,Dropdown,Popover,Whisper } from 'rsuite';
import UserIcon from '@rsuite/icons/legacy/User';

import { Link } from 'react-router-dom';
import style from '../home/home.module.css';

function Profile ({username,udise_code,division_name,district,school_name,linkTo,logoutType,divisionAccess}) {
    return <>
    <Avatar circle>
                <UserIcon />
              </Avatar>
        <Nav className='d-flex'>
          <Dropdown title={username}>
            <Dropdown.Item>
            <Whisper placement="bottom" speaker={<Popover title={logoutType === "admin" ? "Division Access" :udise_code}>
             {logoutType === "admin" ? divisionAccess.map((item,index)=><p>
                <b> {index + 1}. Name:</b> {item.value}
              </p>) : <>
              <p>
                <b>UDISE Code:</b> {udise_code}
              </p>
              <p>
                <b>Division:</b> {division_name}
              </p>
              <p>
                <b>District:</b> {district}
              </p>
              <p>
                <b>School Name:</b> {school_name}
              </p>
              </>}
          </Popover>}>
        <a>Profile</a>
      </Whisper>
            </Dropdown.Item>
            <Dropdown.Item><Link to={linkTo} className={`${style.btn} btn`} onClick={()=>logout(logoutType)}>Logout</Link></Dropdown.Item>
          </Dropdown>
        </Nav>
    </>
}

export default Profile;