import { useEffect, useState } from 'react';
import style from '../home/home.module.css';
import { Button, Notification, useToaster } from 'rsuite';
import DataAuthorizeIcon from '@rsuite/icons/DataAuthorize';
import DocPassIcon from '@rsuite/icons/DocPass';
import { useNavigate } from 'react-router-dom';
import Message from '../popup/Message';
import { getCurrentYear } from '../../utils/utils';
import Profile from '../profile/Profile';

export default function TypeChoose () {
    const navigate = useNavigate();
    const toast = useToaster();
    const [open,setOpen] = useState(false);
    const [isBRC,setIsBRC] = useState(false);
    const [instuctorInfo,setInstuctorInfo] = useState({
      username : '',
      udiseCode : '',
      schoolInfo: [
              {
                  block_name: "",
                  district: "",
                  division_head: "",
                  division_name: "",
                  school_name: ""
              }
          ]
      });
    const handleOpen = () => {
      setOpen(true)
    }
    const handleClose = () => {
      setOpen(false);
      navigate('/instructor/form')
    }
    function executeCodeNearEndOfMonth() {
      // Get the current date
      const currentDate = new Date();
  
      // Get the last day of the current month
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
      // Set the threshold for triggering code execution (e.g., 7 days before the end of the month)
      const thresholdDays = 1;
  
      // Calculate the difference in days between the current date and the last day of the month
      const daysUntilEndOfMonth = Math.floor((lastDayOfMonth - currentDate) / (1000 * 60 * 60 * 24));
      // Check if the current date is within the threshold days of the end of the month
      if (daysUntilEndOfMonth <= thresholdDays) {
          // Your code to execute when the month is near the end
          toast.push(<Notification type='warning' header={'Note'}>Hi 👋, the end of the month is approaching. Please upload the signed copy soon.</Notification>)
      }
  }
    useEffect(()=>{
      const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
      if(instuctor) {
        if(instuctor.schoolInfo[0].school_name === 'BRC'){
          setIsBRC(true);
          executeCodeNearEndOfMonth();
        } else {
          setIsBRC(false);
          setInstuctorInfo(instuctor)
        }
        setInstuctorInfo(instuctor)
      } else {
        navigate("/home")
      }
    },[])
    return <><div className={style.home_sec}>
      <Message isOpen={open} handleClose={handleClose} />
    <div className="container">
      <div className={style.header}>
        <div className={style.image}>
          <img src='../assets/images/MPSP_logo.png' alt='' className='img-fluid' onClick={()=>{
              navigate('/home')
            }} />
        </div>
        <div>
        </div>
        <div className='d-flex'>
          {/* <Link to='/instructor' className={`${style.btn} btn`} onClick={()=>logout('instructor')}>Logout</Link> */}
          <Profile 
          logoutType={"instructor"}
          linkTo={"/instructor"}
          username={instuctorInfo.username}
          udise_code={instuctorInfo.udiseCode} 
          division_name={instuctorInfo.schoolInfo[0].division_name} 
          district={instuctorInfo.schoolInfo[0].district} 
          school_name={instuctorInfo.schoolInfo[0].school_name}
            />
          </div>
      </div>
    </div>
    <div className={style.table_body}>
      {
        isBRC ?     <div
        style={{ display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh'}}
      >
        <div className='button-flex'>
        <div style={{ marginBottom: '10px' }}>
          <Button style={{width : '250px'}} onClick={()=>navigate('/instructor/school/attendance')} size='lg' color="orange" appearance="primary" endIcon={<DataAuthorizeIcon />}>Attendance {getCurrentYear()}</Button>
          </div>
          <div style={{ marginBottom: '10px', marginLeft : '10px' }}>
          <Button style={{width : '250px', background : '#00272B'}} onClick={()=>navigate('/brc/profile')} size='lg' color="green" appearance="primary" endIcon={<DataAuthorizeIcon />}>BRC Profile {getCurrentYear()}</Button>
          </div>
        </div>
        <div className='button-flex'>
        <div style={{ marginBottom: '10px' }}>
          <Button onClick={()=>navigate('/instructor/data')} size='lg' color="green" appearance="primary" endIcon={<DataAuthorizeIcon />}>Instructor Data {getCurrentYear()}</Button>
          </div>
          <div style={{ marginBottom: '10px', marginLeft : '10px' }}>
          <Button style={{width : '250px'}} onClick={()=>navigate('/brc/form')} size='lg' color="violet" appearance="primary" endIcon={<DataAuthorizeIcon />}>ICT Lab Assets {getCurrentYear()}</Button>
          </div>
        </div>
        
      </div> :
    <div
      style={{ display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh'}}
    >
      <div className='button-flex'>
      <div style={{ marginBottom: '10px' ,marginRight : '10px' }}>
        <Button style={{width : '250px'}} onClick={()=>navigate('/instructor/school/attendance')} size='lg' color="orange" appearance="primary" endIcon={<DataAuthorizeIcon />}>Attendance {getCurrentYear()}</Button>
        </div>
        <div style={{ marginBottom: '10px', marginLeft : '10px' }}>
        <Button style={{width : '250px'}} onClick={()=>navigate('/instructor/data')} size='lg' color="green" appearance="primary" endIcon={<DataAuthorizeIcon />}>Instructor Data {getCurrentYear()}</Button>
        </div>
      </div>
      <div className='button-flex'>
      <div style={{ marginBottom: '10px' ,marginRight : '10px' }}>
        <Button style={{width : '250px'}} onClick={handleOpen} size='lg' color='blue' appearance="primary" endIcon={<DocPassIcon />}>UDISE Form {getCurrentYear()}</Button>
        </div>
        <div style={{ marginBottom: '10px', marginLeft : '10px' }}>
        <Button style={{width : '250px'}} onClick={()=>navigate('/instructor/students')} size='lg' color="violet" appearance="primary" endIcon={<DataAuthorizeIcon />}>Student Data {getCurrentYear()}</Button>
        </div>
      </div>
    </div>
}
    </div>
  </div>
  </>
}