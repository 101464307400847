import React , {useRef} from 'react';
import { Modal, Button,Form, Schema,SelectPicker } from 'rsuite';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import { addNewStudent } from '../../services/services';
import { assignMarksBasedOnGrades } from '../../utils/utils';
const { StringType } = Schema.Types;
const model = Schema.Model({
    firstName: StringType().containsLetterOnly().isRequired('This field is required.'),
    lastName: StringType().containsLetterOnly().isRequired('This field is required.'),
    class: StringType().isRequired('This field is required.'),
    divison: StringType().isRequired('This field is required.'),
    academicYear: StringType().isRequired('This field is required.'),
    gender: StringType().containsLetterOnly().isRequired('This field is required.'),
    medium: StringType().containsLetterOnly().isRequired('This field is required.'),
});

function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

const AddNewStudent = ({onFetch,loading}) => {
  const formRef = useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    firstName: '',
    lastName: '',
    class: '',
    divison: '',
    academicYear: '',
    gender: '',
    medium: ''
  });
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const selectClass = ['6', '7', '8', '9', '10', '11', '12'].map(item => ({
    label: item,
    value: item
  }));
  const selectGender = ['Male', 'Female', 'Other'].map(item => ({
    label: item,
    value: item
  }));
  const selectAcademicYear = ['2023-24','2024-25', '2025-26', '2026-27', '2027-28'].map(item => ({
    label: item,
    value: item
  }));
  const selectMedium = ['English', 'Hindi', 'Marathi', 'Urdu'].map(item => ({
    label: item,
    value: item
  }));
  const selectDivision = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].map(item => ({
    label: item,
    value: item
  }));
  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const reset = () => {
    setFormValue({
      firstName: '',
      lastName: '',
      class: '',
      divison: '',
      academicYear: '',
      gender: '',
      medium: ''
    })
  }
  const handleAddStudent = async () => {
    if (!formRef.current.check()) {
      console.error('Form Error',formError);
      return;
    } else {
      try {
        const udiseCode = JSON.parse(localStorage.getItem('instuctorInfo'));
        const marks = assignMarksBasedOnGrades(formValue.class)
        const payload = {
          "udiseCode": udiseCode.udiseCode,
          ...formValue,
          ...marks,
        }
        let response = await addNewStudent(payload);
        if(response.data.status) {
          const json = {
            udiseCode : udiseCode.udiseCode,
            class : formValue.class
          }
          onFetch(json);
          setOpen(false);
          reset();
        }
      } catch (error) {
        console.log(error)
      }
    }

  }
  return (
    <>
        <Button disabled={loading} style={{marginLeft : '10px'}} color="blue" size="md" appearance="primary" onClick={() => handleOpen('md')} endIcon={<UserInfoIcon />}>
        Add New Student
        </Button>
      <hr />
      <Modal backdrop={'static'} size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Add New Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  
          model={model}           
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}>
            <TextField name="firstName" label="First Name" />
            <TextField name="lastName" label="Last Name" />
            <Form.Group controlId="select-10">
              <Form.ControlLabel>Class</Form.ControlLabel>
              <Form.Control name="class" data={selectClass} accepter={SelectPicker} />
            </Form.Group>
            <Form.Group controlId="select-10">
              <Form.ControlLabel>Divison</Form.ControlLabel>
              <Form.Control name="divison" data={selectDivision} accepter={SelectPicker} />
            </Form.Group>
            <Form.Group controlId="select-10">
              <Form.ControlLabel>Academic Year</Form.ControlLabel>
              <Form.Control name="academicYear" data={selectAcademicYear} accepter={SelectPicker} />
            </Form.Group>
            <Form.Group controlId="select-10">
              <Form.ControlLabel>Gender</Form.ControlLabel>
              <Form.Control name="gender" data={selectGender} accepter={SelectPicker} />
            </Form.Group>
            <Form.Group controlId="select-10">
              <Form.ControlLabel>Medium</Form.ControlLabel>
              <Form.Control name="medium" data={selectMedium} accepter={SelectPicker} />
            </Form.Group>
            <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button type="submit" appearance="primary" onClick={handleAddStudent}>
            Ok
          </Button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default AddNewStudent;