import React from 'react';
import { Modal, Button, Carousel } from 'rsuite';
import { Message } from 'rsuite';
const ViewImages = ({isOpen,handleClose,data}) => {
  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Uploaded Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <Carousel autoplay className="custom-slider">
            {data.map((item)=>{
                return /\.(png|jpg|jpeg)$/i.test(item) ?  <img src={item} alt={item} height="250" /> : <Button appearance="primary" onClick={()=>window.open(item, '_blank')}>{item}</Button> 
            })}
            </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewImages;