import React , { useState,useRef,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from '../home/home.module.css';
import { GroundExercise, InstuctorTableData, SessionPlan, TraningMaterial } from '../table/ReactTable';
import { Divider, Uploader,Nav } from 'rsuite';
import { logout } from '../../utils/utils';
import { Modal, Button,Form } from 'rsuite';
import FileUpload from '@rsuite/icons/FileUpload';
import url from '../../api/api';
import { getFilesByUsername } from '../../services/services';
import ThreeColumnsIcon from '@rsuite/icons/ThreeColumns';
import PeopleSpeakerIcon from '@rsuite/icons/PeopleSpeaker';
import PeopleBranchIcon from '@rsuite/icons/PeopleBranch';
import ModelIcon from '@rsuite/icons/Model';
import Profile from '../profile/Profile';
import STATIC_JSON from '../../data/static.json';

const TextField = React.forwardRef((props, ref) => {
    const { name, label, accepter, ...rest } = props;
    return (
      <Form.Group ref={ref}>
        <Form.ControlLabel>{label} </Form.ControlLabel>
        <Form.Control name={name} accepter={accepter} {...rest} />
      </Form.Group>
    );
  });

function InstructorData () {
    const navigate = useNavigate();
    const data = [
        {
            "label": "Grade 6",
            "id" : "1",
            "value": "1",
            "price": "$496662",
            "rating": "3.60",
            "children": [
                {
                    "label": "English",
                    "id" : "1.1",
                    "value": "1.1",
                    "link" : "https://goenkainc0-my.sharepoint.com/:b:/g/personal/shared_drive_edspire_in/ER5AncEyYJBCgCyShFCJdCAB--IRC4C-LrH_tWiOSUzr1g?e=sGyQdm"
                },
                {
                    "label": "Hindi",
                    "id" : "1.2",
                    "value": "1.2",
                },
                {
                    "label": "Marathi",
                    "id" : "1.3",
                    "value": "1.3",
                },
            ]
        },
        {
            "label": "Grade 7",
            "id" : "1",
            "value": "2",
            "price": "$496662",
            "rating": "3.60",
            "children": [
                {
                    "label": "English",
                    "id" : "1.1",
                    "value": "2.1",
                    "link" : "https://goenkainc0-my.sharepoint.com/:b:/g/personal/shared_drive_edspire_in/EXsmmT7KdopPmSx5aeeskDIBEloQABZ5HMGE4I2M6AxuzQ?e=pf7Xvl"
                },
                {
                    "label": "Hindi",
                    "id" : "1.2",
                    "value": "2.2",
                },
                {
                    "label": "Marathi",
                    "id" : "1.3",
                    "value": "2.3",
                },
            ]
        },
        {
            "label": "Grade 8",
            "id" : "1",
            "value": "3",
            "price": "$496662",
            "rating": "3.60",
            "children": [
                {
                    "label": "English",
                    "id" : "1.1",
                    "value": "3.1",
                    "link" : "https://goenkainc0-my.sharepoint.com/:b:/g/personal/shared_drive_edspire_in/ET0e_4FHjMVEsu4r-Yrp-PcB5bjJPNv-cC7ny6u_WClgJg?e=HEz3e5"
                },
                {
                    "label": "Hindi",
                    "id" : "1.2",
                    "value": "3.2",
                },
                {
                    "label": "Marathi",
                    "id" : "1.3",
                    "value": "3.3",
                },
            ]
        },
        {
            "label": "Grade 9",
            "id" : "1",
            "value": "4",
            "price": "$496662",
            "rating": "3.60",
            "children": [
                {
                    "label": "English",
                    "id" : "1.1",
                    "value": "4.1",
                    "link" : "https://goenkainc0-my.sharepoint.com/:b:/g/personal/shared_drive_edspire_in/EWJEqNl28wVFpSATCZcKRVcBcGJ1TxHdzl8wYi_m8CKscQ?e=xPtY2k"
                },
                {
                    "label": "Hindi",
                    "id" : "1.2",
                    "value": "4.2",
                },
                {
                    "label": "Marathi",
                    "id" : "1.3",
                    "value": "4.3",
                },
            ]
        },
        {
            "label": "Grade 10",
            "id" : "1",
            "value": "5",
            "price": "$496662",
            "rating": "3.60",
            "children": [
                {
                    "label": "English",
                    "id" : "1.1",
                    "value": "5.1",
                    "link" : "https://goenkainc0-my.sharepoint.com/:b:/g/personal/shared_drive_edspire_in/EZGcyDRN5khAvZJ20kT7-3EBbT1gSEEUh7L1B89RppyM4A?e=nNeBPa"
                },
                {
                    "label": "Hindi",
                    "id" : "1.2",
                    "value": "5.2",
                },
                {
                    "label": "Marathi",
                    "id" : "1.3",
                    "value": "5.3",
                },
            ]
        },
        {
            "label": "Grade 11",
            "id" : "1",
            "value": "6",
            "price": "$496662",
            "rating": "3.60",
            "children": [
                {
                    "label": "English",
                    "id" : "1.1",
                    "value": "6.1",
                    "link" : "https://goenkainc0-my.sharepoint.com/:b:/g/personal/shared_drive_edspire_in/EU72FG7HwBVNgmQML8HrW5MBYW2ChIpr8ad49iG2p_WKCw?e=vg8gAa"
                },
                {
                    "label": "Hindi",
                    "id" : "1.2",
                    "value": "6.2",
                },
                {
                    "label": "Marathi",
                    "id" : "1.3",
                    "value": "6.3",
                },
            ]
        },
        {
            "label": "Grade 12",
            "id" : "1",
            "value": "7",
            "price": "$496662",
            "rating": "3.60",
            "children": [
                {
                    "label": "English",
                    "id" : "1.1",
                    "value": "7.1",
                    "link" : "https://goenkainc0-my.sharepoint.com/:b:/g/personal/shared_drive_edspire_in/EZRDxnHok6lEgxGC2PzEv-4B0BbZs69-AWahloh_9i-TlA?e=z6osNt"
                },
                {
                    "label": "Hindi",
                    "id" : "1.2",
                    "value": "7.2",
                },
                {
                    "label": "Marathi",
                    "id" : "1.3",
                    "value": "7.3",
                },
            ]
        },
    ]
    const [instuctorInfo,setInstuctorInfo] = useState({
    username : '',
    udiseCode : '',
    schoolInfo: [
            {
                block_name: "",
                district: "",
                division_head: "",
                division_name: "",
                school_name: ""
            }
        ]
    });
    const Navbar = ({ active, onSelect, ...props }) => {
        return (
          <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 50 }}>
           {instuctorInfo.schoolInfo[0].school_name === 'BRC' ? '' : <Nav.Item eventKey="lesson" icon={<ThreeColumnsIcon />}>Lesson Plans</Nav.Item>}
            <Nav.Item eventKey="groundExercise" icon={<PeopleSpeakerIcon />}>Ground Exercises</Nav.Item>
            {instuctorInfo.schoolInfo[0].school_name === 'BRC' ? '' : <Nav.Item eventKey="sessionPlan" icon={<PeopleBranchIcon />}>Session Plans</Nav.Item>}
            <Nav.Item eventKey="traningMaterial" icon={<ModelIcon />}>Traning Material</Nav.Item>
          </Nav>
        );
      };
    const [active, setActive] = useState('');
    useEffect(()=>{
        const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
        if(instuctor){
            if(instuctor.schoolInfo[0].school_name === "BRC"){
                setActive('groundExercise')
            } else {
                setActive('lesson')
            }
            setInstuctorInfo(instuctor);
        } else {
            navigate("/home")
        }
    },[])
    return   <div className={style.form_table}>
    <div className={style.home_sec}>
    <div className="container">
        <div className={style.header}>
          <div className={style.image}>
            <img src='../../assets/images/MPSP_logo.png' alt='' className='img-fluid' onClick={()=>{
              navigate('/home')
            }} />
          </div>
          <div className='d-flex'>
          {/* <Link to='/instructor' className={`${style.btn} btn`} onClick={()=>logout('instructor')}>Logout</Link> */}
          <Profile 
          logoutType={"instructor"}
          linkTo={"/instructor"}
          username={instuctorInfo.username}
          udise_code={instuctorInfo.udiseCode} 
          division_name={instuctorInfo.schoolInfo[0].division_name} 
          district={instuctorInfo.schoolInfo[0].district} 
          school_name={instuctorInfo.schoolInfo[0].school_name}
            />
          </div>
        </div>
      </div>
    </div>

    <div className={style.table_body}>
      <div className="container">
      <Navbar appearance="tabs" active={active} onSelect={setActive} />
      {active === "lesson" ?  <InstuctorTableData data={STATIC_JSON.lessonPlan} /> : active === "groundExercise" ? <GroundExercise /> : active === "sessionPlan" ?  <SessionPlan /> : active === "traningMaterial" ? <TraningMaterial /> : ''}
      </div>
    </div>
  </div>
}

export default InstructorData;