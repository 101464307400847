import React from 'react';
import { Modal, Button, Table,Checkbox,Pagination } from 'rsuite';
const { Column, HeaderCell, Cell,ColumnGroup } = Table;

const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />;

const ViewSessionPlans = ({isOpen,handleClose,data}) => {
    const [limit, setLimit] = React.useState(9);
    const [page, setPage] = React.useState(1);

    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
      };
    
      const formatedData = data.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
  return (
    <>
      <Modal size={"xl"} open={isOpen} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Session Plans</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table wordWrap='break-word' bordered cellBordered autoHeight headerHeight={80} data={formatedData}>
      <ColumnGroup header="Session Plan - Information Communication & Technology (Daywise)" align="center" verticalAlign='center'>
      <Column width={100} align="center" verticalAlign='center' rowSpan={rowData => {
          return rowData.rowSpan;
        }} >
        <HeaderCell>Week</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="week"  />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Day</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="day"  />
      </Column>
      <Column width={300} align="left" verticalAlign='center'>
        <HeaderCell>Topic/Chapter Name</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="topicChapterName"  />
      </Column>
      <Column width={300} align="center" verticalAlign='center'>
        <HeaderCell>Session</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="session"  />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>#</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="number"  />
      </Column>
      <Column width={300} align="left" verticalAlign='center'>
        <HeaderCell>Sub topic</HeaderCell>
        <Cell style={{fontSize : '12px'}}>
          {rowData => {
            const formattedTopic = rowData.subtopic.split("●").filter(item => item.trim() !== "");
            return <ul>
            {formattedTopic.map((item, index) => (
              <li key={index}>● {item.trim()}</li>
            ))}
          </ul>
          }}
        </Cell>
      </Column>
      </ColumnGroup>
      <ColumnGroup header="School Training" align="center" verticalAlign='center'>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Theory</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="theory"  />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Practical</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="practical"  />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>Class Exercise (Worksheet)</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="classExercise"  />
      </Column>
      <Column width={200} align="center" verticalAlign='center'>
        <HeaderCell>PBL (Activity/Project)</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="PBL"  />
      </Column>
      <Column width={100} align="center" verticalAlign='center'>
        <HeaderCell>Assessment</HeaderCell>
        <Cell style={{fontSize : '12px'}} dataKey="assessment"  />
      </Column>
      </ColumnGroup>
    </Table>
    <div className='mt-2'>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={data.length}
          limitOptions={[9, 30, 48]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewSessionPlans;