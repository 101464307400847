import { useEffect, useState } from "react";
import { BRCTable } from "../table/ReactTable";
import { useNavigate } from 'react-router-dom';
import style from '../home/home.module.css';
import Profile from "../profile/Profile";

function BRCProfile () {
    const navigate = useNavigate();
    const [instuctorInfo,setInstuctorInfo] = useState({
      username : '',
      udiseCode : '',
      schoolInfo: [
              {
                  block_name: "",
                  district: "",
                  division_head: "",
                  division_name: "",
                  school_name: ""
              }
          ]
      });
    useEffect(()=>{
      const instuctor = JSON.parse(localStorage.getItem('instuctorInfo'));
      if(instuctor){
          setInstuctorInfo(instuctor);
      } else {
          navigate("/home")
      }
    },[])
    return <div className={style.home_sec}>
    <div className="container">
        <div className={style.header}>
          <div className={style.image}>
            <img src='../../assets/images/MPSP_logo.png' alt='' className='img-fluid' onClick={()=>{
              navigate('/home')
            }} />
          </div>
          <div className="d-flex">
          <Profile 
          logoutType={"instructor"}
          linkTo={"/instructor"}
          username={instuctorInfo.username}
          udise_code={instuctorInfo.udiseCode} 
          division_name={instuctorInfo.schoolInfo[0].division_name} 
          district={instuctorInfo.schoolInfo[0].district} 
          school_name={instuctorInfo.schoolInfo[0].school_name}
            />
          </div>
        </div>
      </div>
    <div className={style.table_body}>
    <div className='container'><BRCTable /></div>
    </div>
  </div>
}

export default BRCProfile;