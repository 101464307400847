import React from 'react';
import { Modal, Button, Table, Badge } from 'rsuite';
import { arrayOfJSONWithCount } from '../../utils/utils';
const { Column, HeaderCell, Cell } = Table;

function ViewSchoolList ({isOpen,handleClose,data}) {
    const formatedData = arrayOfJSONWithCount(data);
    return <>
    <Modal size={"md"} open={isOpen} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Schools</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <Table
   bordered cellBordered height={800} headerHeight={80} data={formatedData}
   >
      <Column width={50} align="center">
        <HeaderCell>Sr No</HeaderCell>
        <Cell dataKey="count" />
      </Column>

      <Column width={200}>
        <HeaderCell>Name of the Schools</HeaderCell>
        <Cell dataKey="nameOfTheEquipment" />
      </Column>

      <Column width={100}>
        <HeaderCell>UDISE Code</HeaderCell>
        <Cell dataKey="code" />
      </Column>

      <Column width={200}>
        <HeaderCell>Block</HeaderCell>
        <Cell dataKey="block" />
      </Column>

      <Column width={100}>
        <HeaderCell>HM Name</HeaderCell>
        <Cell dataKey="hmName" />
      </Column>
      <Column width={110}>
        <HeaderCell>HM Contact</HeaderCell>
        <Cell dataKey="hmContact" />
      </Column>
  </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="primary">
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}

export default ViewSchoolList;