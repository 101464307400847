import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

// Services
import { instuctorLogin } from '../../services/services';
import { Loader,Message, Notification, useToaster } from 'rsuite';
import { rememberMeInstuctor } from '../../utils/utils';

export default function InstructerPage() {
    const navigate = useNavigate();
    const toaster = useToaster();
    const [isLoading, setLoading] = useState(false);
    const [isShowPassword,setIsShowPassword] = useState(false);
    const [isChecked,setIsChecked] = useState(false);
    const [formValues, setFormValues] = useState({
        username : '',
        password : ''
    })
    const [error, setError] = useState({
        errorType: '',
        errorMessage: ''
    })

    const handleForm = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
        setError({ errorType: '' })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formValues.username) {
            setError({
                errorType : 'username',
                errorMessage : 'Please enter username'
            })
            return;
        }
        else if (!formValues.password) {
            setError({
                errorType : 'password',
                errorMessage : 'Please enter password'
            })
            return;
        } else {
          try {
            setLoading(true)
            const response = await instuctorLogin(formValues);
            if (response.data.status) {
                setLoading(false);
                if(localStorage.getItem('isRememberMeInstuctor') === 'true'){
                  localStorage.setItem('rememberMeInstuctor',JSON.stringify(formValues));
                } else {
                  localStorage.removeItem('rememberMeInstuctor');
                }
                localStorage.setItem("instuctorInfo", JSON.stringify(...response.data.data));
                localStorage.setItem("permissionToken",response.data.accessToken)
                toaster.push(<Message showIcon type={'success'} header='Success'>{response.data.message}</Message>, {
                    placement : 'topEnd',
                    duration: 3000
                  })
                // toaster.push(<Notification type={'warning'} header={'Note'} closable>It is mandatory to upload the signed copy.</Notification>,{
                //   placement : 'topEnd',
                //   duration: 10000
                // })
                // toaster.push(<Notification type={'error'} header={'Unauthorized User'} closable>The BRC page for the next month will be locked unless the signed copy is uploaded.​</Notification>,{
                //   placement : 'topEnd',
                //   duration: 10000
                // })
                
                navigate("/instructor/choose");
            }
        } catch (error) {
            toaster.push(<Message showIcon type={'error'} header='Error'>{error.response.data.message}</Message>, {
                placement : 'topEnd',
                duration: 3000
              })
            setLoading(false);
        }
        }
    };
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("userInfo"))
        const isInstuctor = localStorage.getItem('isRememberMeInstuctor');
        const isRememberMe = localStorage.getItem('rememberMeInstuctor');
        if(isRememberMe && isInstuctor === 'true'){
          setFormValues(JSON.parse(isRememberMe));
          setIsChecked(true);
        }
        if (!userData) {
            navigate("/")
            return
        }
    }, [])


    return (
        <>
<div className="form_container p-3 p-sm-5">
  <div className="mx-auto d-flex flex-column align-items-center gap-2">
    <img src="./assets/icon/MPSP logo.png" alt="" className="logo" onClick={()=>{
              navigate('/home')
            }} />
    <p className="fs_24 fw_600 color_blue_black white-space-nowrap text-center">
      ICT for Instructors
    </p>
  </div>
  <div className="d-flex align-items-center justify-content-center flex-grow-1 text-center">
    <form className="mt-sm-5 form_width w-100">
      <h1 className="fs_39 fw_700 color_blue_black">Namaste <img src='./assets/icon/folded.png' /></h1>
      <h3 className="fs_24 fw_600 color_blue_black mt-2 pt-1 pb-2">
        Sign in your Instructor Account
      </h3>
      <div className={error.errorType === "username" ? "d-flex align-items-center input_decoration mt-sm-5 input-error" : "d-flex align-items-center input_decoration mt-sm-5"}>
        <img src="./assets/icon/user-icon.svg" alt="user-icon" />
        <input
          className="w-100 ms-2 fs_16 fw_600 color_blue_black"
          type="text"
          name='username'
          placeholder="username"
          value={formValues.username}
          onChange={handleForm}
        />
      </div>
      <div className={error.errorType === "password" ? "d-flex align-items-center input_decoration mt-sm-5 input-error" : "d-flex align-items-center input_decoration mt-sm-5"}>
        <img src="./assets/icon/password-icon.svg" alt="password-icon" />
        <input
          className="w-100 ms-2 fs_16 fw_600 color_blue_black"
          type={isShowPassword ? "text" : "password"}
          name='password'
          placeholder="-------"
          value={formValues.password}
          onChange={handleForm}
        />
        {isShowPassword ? <img className='pointer' src="./assets/icon/visible.png" alt="eye-icon" onClick={()=>setIsShowPassword(false)} /> : <img className='pointer' src="./assets/icon/invisible.png" alt="eye-icon" onClick={()=>setIsShowPassword(true)} />}
      </div>
      <p className="fs_16 fw_700 text-start mt-4 pt-1 color_red">
        *Instructor details required.
      </p>
      <div className="d-flex align-items-center mt-4 pt-2">
        <input type="checkbox" id="remember" checked={isChecked} onClick={(e)=>{
          rememberMeInstuctor(e.target.checked);
          setIsChecked(e.target.checked);
        }} />
        <label
          className="ms-2 fs_16 fw_600 color_blue_black"
          htmlFor="remember"
        >
          Remember me
        </label>
      </div>
      <button className="w-100 mt-4 sign_in_btn text-white" onClick={handleSubmit}>
                {isLoading ? <Loader size="sm" content="Loading..." /> : 'Sign In'}
              </button>
    </form>
  </div>
</div>
        </>
    )
}
