import React , {useRef} from 'react';
import { Modal, Button,Form, Schema,SelectPicker } from 'rsuite';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import { adminAdd, adminUpdateStatus } from '../../services/services';
// Notifications
import { Message, useToaster } from 'rsuite';

const { StringType } = Schema.Types;

const model = Schema.Model({
    isActive: StringType().isRequired('This field is required.'),
});

function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

const AdminStatus = ({onFetch,adminId,loading}) => {
  const toaster = useToaster();
  const pushNotification = (type,header,message) => {
      toaster.push(<Message showIcon type={type} header={header}>{message}</Message>, {
        placement : 'topEnd',
        duration: 3000
      })
    }
  const formRef = useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    isActive: '',
  });
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const selectStatus = ['Active', 'Deactivate'].map(item => ({
    label: item,
    value: item
  }));
  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const reset = () => {
    setFormValue({
      isActive: '',
    })
  }
  const handleAdmin= async () => {
    if (!formRef.current.check()) {
      console.error('Form Error',formError);
      return;
    } else {
      try {
        const json = {
            id : adminId,
            isActive : formValue.isActive
        }
        let response = await adminUpdateStatus(json);
        if(response.data.status) {
          onFetch();
          pushNotification('success','Updated',response.data.message)
          setOpen(false);
          reset();
        } else {
            pushNotification('warning','Note',response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    }

  }
  return (
    <>
        <Button disabled={loading} style={{marginLeft : '10px'}} size="xs" color="blue" appearance="link" onClick={() => handleOpen('md')}>
        Edit
        </Button>
      <hr />
      <Modal backdrop={'static'} size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Update Admin Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form  
          model={model}           
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}>
            <Form.Group controlId="select-10">
              <Form.ControlLabel>Status</Form.ControlLabel>
              <Form.Control name="isActive" data={selectStatus} accepter={SelectPicker} />
            </Form.Group>
            <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button type="submit" appearance="primary" onClick={handleAdmin}>
            Ok
          </Button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default AdminStatus;