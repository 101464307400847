import { Uploader, Button } from 'rsuite';
import url from "../../api/api";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Notifications
import { Message, useToaster } from 'rsuite';
import FileUploadIcon from '@rsuite/icons/FileUpload';
import axios from 'axios';

const FileUploader = ({title,color,isSignedCopy,onFetch}) => {
    const toaster = useToaster();
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const [username,setUsername] = useState('');

    const imageFileTypes = ['image/jpeg', 'image/png', 'image/gif','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/pdf'];
    const maxFileSize = 1024 * 1024; // 1MB in bytes

    const pushNotification = (type,header,message) => {
        toaster.push(<Message showIcon type={type} header={header}>{message}</Message>, {
          placement : 'topEnd',
          duration: 3000
        })
      }
      const handleUpload = async (files) => {
        try {
          console.log(files)
          if (files.length) {
            if (!imageFileTypes.includes(files[0]?.blobFile?.type)) {
              pushNotification(
                "error",
                "Note",
                "Invalid file type. Please upload only images.",
              );
              return false;
            }
      
            if (files[0]?.blobFil?.size > maxFileSize) {
              pushNotification(
                "error",
                "Note",
                "File size exceeds 5MB limit. Please choose a smaller file.",
              );
              return false;
            }
            // Manually trigger the upload
      
            const formData = new FormData();
      
            // Append each file to the FormData object
            files.forEach((file) => {
              formData.append("file", file.blobFile, file.name);
            });
      
            // Add any additional data to the FormData object if needed
            // formData.append('key', 'value');
      
            // Send FormData to your API endpoint
            setLoading(true);
            await axios
              .post(
                `${url}/instuctor/file/upload?username=${username}&isSignedCopy=${isSignedCopy}&createdAt=${new Date()}`,
                formData,
              )
              .then((data) => {
                pushNotification("success", "Success", "File Uploaded");
                setLoading(false);
                onFetch('BRC Monthly Activity Log')
              })
              .catch((error) => {
                // Handle errors
                pushNotification("error", "Note", "File Upload Failed");
                setLoading(false);
              });
          }
        } catch (error) {
          console.log(error);
        }
      };
    useEffect(()=>{
    let instuctor = JSON.parse(localStorage.getItem("instuctorInfo"));
    if(instuctor) {
        setUsername(instuctor.username);
    } else {
        navigate("/");
    }
  })
  return <Uploader
    listType="picture-text"
    accept=".jpg,.png"
    loading={loading}
    autoUpload={false}
    onChange={handleUpload}
  >
    <Button loading={loading} color={color} appearance="primary" startIcon={<FileUploadIcon />}>{title}</Button>
  </Uploader>
};

export default FileUploader;